import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AsyncSubject } from 'rxjs';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';
import { RightSideInfoFloaterModel } from './rightside-info-floater.model';

@Component({
	selector: 'ae-rightside-info-floater',
	templateUrl: './rightside-info-floater.component.html',
	styleUrls: ['./rightside-info-floater.component.scss'],
	imports: [NgClass, NgTemplateOutlet],
})
export class RightSideInfoFloaterComponent implements OnInit, OnDestroy {
	public _unsubscribe$ = new AsyncSubject<null>();

	@Input() rightSideInfoConfig: RightSideInfoFloaterModel;
	@Input() public id: string;

	public collapsed: boolean = false;
	public animationOn: boolean = false;

	@ViewChild('rightSideInfoContainer', { static: true })
	private rightSideInfoContainer: ElementRef<HTMLElement>;

	constructor(
		protected fb: FormBuilder,
		private localStore: LocalStoreService,
	) {}

	ngOnInit() {
		if (isNullOrEmptyString(this.id)) {
			throw new Error('Error in ae-right-side-nav-tabber, id is required');
		}

		this.collapsed = this.localStore.get(
			`rightside-info-floater-collapsed-${this.id}`,
			TypedStoreType.BOOLEAN,
			false,
		);
		this.animationOn = this.collapsed;
	}

	public toggleCollapse = () => {
		this.collapsed = !this.collapsed;
		if (!this.collapsed) {
			this.animationOn = false;
		}

		this.localStore.set(
			`rightside-info-floater-collapsed-${this.id}`,
			this.collapsed,
		);

		setTimeout(() => {
			this.animationOn = this.collapsed;
		}, 15);
	};

	public buttonEnter = () => {
		if (
			this.rightSideInfoContainer.nativeElement.querySelector(
				'.rightside-info-floater-cover',
			)?.clientWidth < 10
		) {
			this.rightSideInfoContainer.nativeElement.classList.add('hover-suppress');
		}
	};

	public buttonLeave = () => {
		this.rightSideInfoContainer.nativeElement.classList.remove(
			'hover-suppress',
		);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
