import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentCourseOptionMetaDataModel {
	completed: number;
	transferred: number;
}
export function StudentCourseOptionMetaDataModel_ClassTransformType() {
	return StudentCourseOptionMetaDataModel;
}

export class StudentCourseOptionModel {
	class_id: number;
	title: string;
	credits: number;
	@Type(StudentCourseOptionMetaDataModel_ClassTransformType)
	metadata: StudentCourseOptionMetaDataModel;
	planned: boolean;
	subject: string;
	requires_parental_consent: boolean;
}
export function StudentCourseOptionModel_ClassTransformType() {
	return StudentCourseOptionModel;
}

export class StudentCourseCreditLimitModel {
	current_credits?: number;
	max_current_credits?: number;
	max_credits_style: MaxCreditsStyleEnum;
}

export function StudentCourseCreditLimitsModel_ClassTransformType() {
	return StudentCourseCreditLimitModel;
}

export class StudentCourseOptionsModel {
	@Type(StudentCourseOptionModel_ClassTransformType)
	pacing_type: CoursePacingTypes;
	course_list: StudentCourseOptionModel[];

	@Expose({ name: 'default_pacing_start_timestamp' })
	@Transform(dateTransformer)
	default_pacing_start_date: Date;

	default_pacing_weeks: number;

	@Expose({ name: 'default_target_end_timestamp' })
	@Transform(dateTransformer)
	default_target_end_date: Date;

	pacing_required: boolean;
	pacing_start_allowed_days_of_week: number[];
	concurrent_additional: number;
	disable_grant_access: boolean;
	@Type(StudentCourseCreditLimitsModel_ClassTransformType)
	credit_limits: StudentCourseCreditLimitModel;
	possible_grades: string[];
	pacing_specific_dates: number[][];
}

export enum MaxCreditsStyleEnum {
	none = 'none',
	credit = 'credit',
	counted = 'counted',
	unlimited = 'unlimited',
}

export enum CoursePacingTypes {
	weeklyPacing = 'weekly_pacing',
	specificDates = 'specific_dates',
	none = 'none',
}

export enum CourseAccessTypes {
	immediately = 'immediate registration',
	onStart = 'on scheduled start',
	plan = 'add to plan',
	planSchedule = 'add and schedule',
}
