import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class LearningPlanHistoryUserModel {
	uid: number;
	name: string;
}

export function LearningPlanHistoryUserModel_ClassTransform_Type() {
	return LearningPlanHistoryUserModel;
}

export class LearningPlanHistoryModel {
	learning_plan_id: number;
	@Type(LearningPlanHistoryUserModel_ClassTransform_Type)
	performed_by: LearningPlanHistoryUserModel;
	@Expose({ name: 'performed_on_timestamp' })
	@Transform(dateTransformer)
	performed_on_datetime: Date;
	@Transform(dateTransformer)
	performed_on_date: Date;
	action: string;
	description: string;
}
