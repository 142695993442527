import spacetime, { type Spacetime } from 'spacetime';
import { hasValue, isDate, isValidDate } from './compare';

export function convertToCalendarDate(d: Date): Date {
	if (d == null) return null;
	else {
		return new Date(d.getFullYear(), d.getMonth(), d.getDate());
	}
}

export function isSameCalendarDate(left: Date, right: Date): boolean {
	if (isValidDate(left) && isValidDate(right)) {
		return (
			convertToCalendarDate(left).getTime() ===
			convertToCalendarDate(right).getTime()
		);
	} else {
		// eslint-disable-next-line eqeqeq
		return left == right;
	}
}

export function compareDateRangePickerItem(
	item: Date,
	comparison: {
		toDate: Date;
		fromDate: Date;
	},
): boolean {
	if (comparison == null || item == null) return false;

	const endDate = spacetime(comparison.toDate).startOf('date').epoch;
	const startDate = spacetime(comparison.fromDate).startOf('date').epoch;
	const itemDate = spacetime(item).startOf('date').epoch;
	return startDate <= itemDate && endDate >= itemDate;
}

export function compareDateRangePickerTouchesRangeItem(
	fromDate: Date,
	toDate: Date,
	comparison: {
		toDate: Date;
		fromDate: Date;
	},
): boolean {
	if (comparison == null || fromDate == null || toDate == null) return false;

	const itemFromDate = spacetime(fromDate).startOf('date').epoch;
	const itemToDate = spacetime(toDate).startOf('date').epoch;

	const compareFromDate = spacetime(comparison.fromDate).startOf('date').epoch;
	const compareToDate = spacetime(comparison.toDate).startOf('date').epoch;

	return !(compareToDate < itemFromDate || compareFromDate > itemToDate);
}

export function compareDateRangePickerContainsRangeItem(
	fromDate: Date,
	toDate: Date,
	comparison: {
		toDate: Date;
		fromDate: Date;
	},
): boolean {
	if (comparison == null || fromDate == null || toDate == null) return false;

	const itemFromDate = spacetime(fromDate).startOf('date').epoch;
	const itemToDate = spacetime(toDate).startOf('date').epoch;

	const compareFromDate = spacetime(comparison.fromDate).startOf('date').epoch;
	const compareToDate = spacetime(comparison.toDate).startOf('date').epoch;

	return compareFromDate <= itemFromDate && compareToDate >= itemToDate;
}

export function isDifferentDate(
	date1: Date,
	date2: Date,
	options?: {
		dateOnly?: boolean;
	},
): boolean {
	options = options ?? {};
	options.dateOnly = options.dateOnly ?? false;

	const date1Has = hasValue(date1);
	const date2Has = hasValue(date2);

	if (!date1Has && !date2Has) {
		return false;
	}

	if (date1Has && !date2Has) {
		return true;
	}

	if (isDate(date1) !== isDate(date2)) {
		return true;
	}

	if (options.dateOnly) {
		return !isSameCalendarDate(date1, date2);
	} else {
		return date1.getTime() !== date2.getTime();
	}
}

export function isSameDate(
	date1: Date,
	date2: Date,
	options?: {
		dateOnly?: boolean;
	},
): boolean {
	options = options ?? {};
	options.dateOnly = options.dateOnly ?? false;

	const date1Has = hasValue(date1);
	const date2Has = hasValue(date2);

	if (!date1Has && !date2Has) {
		return true;
	}

	if (date1Has && !date2Has) {
		return false;
	}

	if (isDate(date1) !== isDate(date2)) {
		return false;
	}

	if (options.dateOnly) {
		return isSameCalendarDate(date1, date2);
	} else {
		return date1.getTime() === date2.getTime();
	}
}

export function isWeekend(date: Date | Spacetime): boolean {
	if (date instanceof Date) {
		return date.getDay() === 0 || date.getDay() === 6;
	} else {
		return date.day() === 0 || date.day() === 6;
	}
}
