import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormArray,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import {
	NgbDropdown,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	EqualityComparerTextInputComponent,
	EqualityComparerTextInputValue,
} from '../equality-comparer-text-input/equality-comparer-text-input.component';

@Component({
	selector: 'ae-multi-equality-comparer-text-inputs',
	templateUrl: './multi-equality-comparer-text-inputs.component.html',
	styleUrls: ['./multi-equality-comparer-text-inputs.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiEqualityComparerTextInputsComponent),
			multi: true,
		},
	],
	imports: [
		EqualityComparerTextInputComponent,
		FormsModule,
		NgbDropdown,
		NgbDropdownMenu,
		NgbDropdownToggle,
		ReactiveFormsModule,
	],
})
export class MultiEqualityComparerTextInputsComponent
	implements OnInit, OnDestroy, ControlValueAccessor
{
	private _unsubscribe$ = new AsyncSubject<null>();
	@Input() id: string = 'default';

	public outsideControl: FormControl<EqualityComparerTextInputValue>;
	public controlFG: FormArray<FormControl<EqualityComparerTextInputValue>>;
	public processing = false;
	public loading = true;
	public blockLoop = false;

	private _touchFunction: () => void;
	private _changeFunction: (value: EqualityComparerTextInputValue[]) => void =
		() => null;
	private _changeWatcher: Subscription;

	constructor(private fb: FormBuilder) {}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	ngOnInit() {
		this.outsideControl = this.fb.control<EqualityComparerTextInputValue>({
			contains: true,
			value: null,
		});
		this.controlFG = this.fb.array<EqualityComparerTextInputValue>([]);

		this.addNewFilter();

		this.watchForChange();

		this.outsideControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (!this.blockLoop) {
					this.blockLoop = true;
					this.controlFG.at(0).setValue(val);
					this.blockLoop = false;
				}
			});

		this.loading = false;
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.controlFG.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (!this.blockLoop && val != null && val.length > 0) {
					this.blockLoop = true;
					this.outsideControl.setValue(val[0]);
					this.blockLoop = false;
				}
				if (this.processing) return;

				const values: EqualityComparerTextInputValue[] = [];
				this.controlFG.value.forEach((item) => {
					if (item?.value != null && item?.value !== '') {
						values.push(item);
					}
				});

				if (this._changeFunction) {
					this._changeFunction(values);
				}
				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	public addNewFilter = () => {
		this.controlFG.push(
			this.fb.control<EqualityComparerTextInputValue>({
				contains: true,
				value: null,
			}),
		);
	};

	public removeFilter = (index: number) => {
		this.controlFG.removeAt(index);
		if (this.controlFG.length === 0) this.addNewFilter();
		else if (index === 0) {
			this.outsideControl.setValue(this.controlFG.value[0]);
		}
	};

	// Implementing ControlValueAccessor
	public writeValue(val: EqualityComparerTextInputValue[]): void {
		this.processing = true;
		try {
			this.stopWatchForChange();
			val = val ?? [];

			this.controlFG.clear();
			val.forEach((item) => {
				this.controlFG.push(
					this.fb.control<EqualityComparerTextInputValue>({
						contains: item.contains,
						value: item.value,
					}),
				);
			});

			if (this.controlFG.length === 0) this.addNewFilter();
		} catch (e) {
			throw new Error(
				`MultiEqualityComparerTextInputsComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
		this.processing = false;
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled): void {
		this.stopWatchForChange();

		if (isDisabled) this.controlFG.disable();
		else this.controlFG.enable();

		this.watchForChange();
	}
}
