import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hrefUrl',
})
export class HrefUrlPipe implements PipeTransform {
	transform(value: string): string {
		if (value == null) return value;

		if (value.indexOf('//') === -1) {
			value = `//${value}`;
		}

		return value;
	}
}
