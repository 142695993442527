import { NgClass, NgStyle } from '@angular/common';
import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'ae-wait-spinner',
	templateUrl: './wait-spinner.component.html',
	styleUrls: ['./wait-spinner.component.scss'],
	imports: [NgClass, NgStyle],
})
export class WaitSpinnerComponent implements OnInit, OnChanges {
	@Input() saving: boolean = false;
	@Input() class: string = '';
	@Input() style: any;

	public spin: boolean = true;

	constructor(private eleRem: ElementRef<HTMLElement>) {}

	ngOnInit() {
		this.eleRem.nativeElement.className = '';
		this.eleRem.nativeElement.setAttribute('style', '');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.saving) {
			this.spin = changes.saving.currentValue;
		}

		if (changes.class) {
			this.eleRem.nativeElement.className = '';
		}

		if (changes.style) {
			this.eleRem.nativeElement.setAttribute('style', '');
		}
	}
}
