import { Component, Input, OnChanges } from '@angular/core';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { isNonEmptyString } from 'src/lib/utilities/compare';

@Component({
	selector: 'ae-address-display',
	templateUrl: './address-display.component.html',
	styleUrls: ['./address-display.component.scss'],
	imports: [],
})
export class AddressDisplayComponent implements OnChanges {
	@Input() public address: UserAddressModel;
	@Input() public includeAddressLink: boolean = true;

	protected link = null;

	ngOnChanges(): void {
		if (this.address && this.includeAddressLink) {
			const encoded = encodeURIComponent(
				Object.keys(this.address)
					.filter((k) => k !== 'zip' && isNonEmptyString(this.address[k]))
					.map((v) => this.address[v])
					.join(', '),
			);
			this.link = `https://www.google.com/maps/search/?api=1&query=${encoded}`;
		}
	}
}
