<section #rootElement class="billboard" tabindex="-1">
	<div class="billboard-header">
		<h2 class="billboard-title">Account</h2>
		<div class="billboard-actions d-flex">
			<div class="text-center flex-grow-0 mx-2 align-self-center">
				@if (existingPendingChange) {
					<span
						class="align-self-center"
						title="A request to change this info is pending"
					>
						<i aria-hidden="true" class="far fa-lg fa-clock"></i>
					</span>
				}
			</div>
			@if (canEditAccount) {
				<button
					class="btn btn-default"
					id="profile.contact.edit.enable"
					type="button"
					[disabled]="loading"
					(click)="enableEdit()"
				>
					Edit
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			<!-- Account Info -->
			<h3 class="fw-bold fs-5">Account Info</h3>
			<div class="row mb-1">
				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Created</div>
					<div>
						{{
							accountData?.created
								? (accountData?.created | date: 'yyyy-MM-dd hh:mm a')
								: '-'
						}}
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Last Online</div>
					<div>
						{{
							accountData?.last_online
								? (accountData?.last_online | date: 'yyyy-MM-dd hh:mm a')
								: '-'
						}}
					</div>
				</div>
				@if (accountData?.status != null) {
					<div class="col-xl-3 col-lg-4 mb-3">
						<div class="fw-bold">Status</div>
						<div>{{ accountData.status ? 'Active' : 'Not Active' }}</div>
					</div>
				}
			</div>

			<div class="row">
				<div class="col-xl-6 col-lg-8 mb-3">
					<div class="fw-bold">Assigned Programs</div>
					@if (gaData?.programs?.length >= 1) {
						<ul>
							@for (program of gaData?.programs?.sort(); track program) {
								<li>
									{{ program }}
								</li>
							}
						</ul>
					} @else {
						<div>-</div>
					}
				</div>

				@if (formattedAccessType != null) {
					<div class="col-xl-6 col-lg-4 mb-3">
						<div class="fw-bold">Assigned Roles</div>
						@if (formattedAccessType.length > 0) {
							<ul>
								@for (role of formattedAccessType; track role) {
									<li>
										{{ role }}
									</li>
								}
							</ul>
						}
						@if (formattedAccessType.length < 1) {
							<div>-</div>
						}
					</div>
				}

				@if (assignedStudents != null) {
					<div class="col mb-3">
						<div class="fw-bold">Assigned Students</div>
						@if (assignedStudents.length > 0) {
							<ul>
								@for (student of assignedStudents; track student) {
									<li>
										{{ student }}
									</li>
								}
							</ul>
						}
						@if (assignedStudents.length < 1) {
							<div>-</div>
						}
					</div>
				}

				@if (canViewLinkedAccounts) {
					<div class="col mb-3">
						<div class="fw-bold">External Accounts</div>
						@if (externalAccounts?.length > 0) {
							<ul>
								@for (account of externalAccounts; track account) {
									<li>
										{{ account.title }}
									</li>
								}
							</ul>
						} @else {
							<div>-</div>
						}
					</div>
				}
			</div>

			<!-- Administrator Settings -->
			@if (canEditAccount) {
				<h3 class="fw-bold fs-5">Administrator Settings</h3>
				<div class="mb-3">
					<div class="fw-bold">Title</div>
					<div>
						{{
							profileData.profile_user_title
								? profileData.profile_user_title
								: '-'
						}}
					</div>
				</div>
				<div>
					<div class="fw-bold">Administrator Type</div>
					<div>
						<!-- Using empty div to help keep all the spans in a full width row -->
						@for (
							role of formattedAdminTypeList;
							track role;
							let last = $last
						) {
							<span class="text-capitalize">{{
								role.title + (last ? '' : ', ')
							}}</span>
						}
						@if (formattedAdminTypeList?.length < 1) {
							<span>-</span>
						}
					</div>
				</div>
			}

			@if (canViewId) {
				<div>
					<div class="mb-3"></div>
					<div class="row">
						<div class="col-lg-12 mb-3">
							<div class="fw-bold">Employee ID</div>
							<div>
								{{ profileData?.profile_employee_id | ifEmpty }}
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	</div>
</section>
