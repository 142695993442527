import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
	MeetupAttendanceModel,
	MeetupPlannedAttendeeModel,
} from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupManagementService } from 'src/lib/services/api/organizations/meetups/meetup-management.service';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { MeetupUtilitiesService } from '../service/meetup-utilities.service';

@Component({
	selector: 'ae-meetup-rsvp-recurring-modal',
	templateUrl: './meetup-rsvp-recurring-modal.component.html',
	styleUrls: ['./meetup-rsvp-recurring-modal.component.scss'],
	imports: [FormsModule, ReactiveFormsModule, SpinWhileDirective],
})
export class MeetupRsvpRecurringModalComponent {
	@Input() meetupId: number;
	@Input() arrival: number;
	@Input() departure: number;
	@Input() isReschedule: boolean;

	public frequencyCtrl: FormControl<string>;

	public loading = true;
	public saving = false;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private toastr: ToastrService,
		private meetupManagementService: MeetupManagementService,
		public meetupUtilitiesService: MeetupUtilitiesService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		meetupId: number;
		arrival: number;
		departure: number;
		isReschedule: boolean;
	}): void => {
		// SET DATA
		this.meetupId = data.meetupId;
		this.arrival = data.arrival;
		this.departure = data.departure;
		this.isReschedule = data.isReschedule;
		this.frequencyCtrl = new FormControl(null, Validators.required);

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.loading = false;
	};

	public save = () => {
		this.saving = true;
		const isOneTime = this.frequencyCtrl.value === 'one_time';

		if (this.isReschedule) {
			const obs$: Observable<any> = isOneTime
				? this.meetupManagementService.removeAttending(this.meetupId)
				: this.meetupManagementService.deleteRecurringMeetup(this.meetupId);
			obs$.subscribe(() => {
				this.toastr.success(
					isOneTime
						? 'Removed attendance from this meetup.'
						: 'Removed attendance from all recurring meetups.',
				);
				this.saving = false;
				this.activeModal.close(true);
			});
		} else {
			const obs$: Observable<
				MeetupPlannedAttendeeModel | MeetupAttendanceModel
			> = isOneTime
				? this.meetupManagementService.markAsAttending(this.meetupId, {
						planned_arrival_timestamp: this.arrival,
						planned_departure_timestamp: this.departure,
					})
				: this.meetupManagementService.markAsAttendingRecurringMeetup(
						this.meetupId,
						{
							planned_arrival_time: this.arrival,
							planned_departure_time: this.departure,
						},
					);

			obs$.subscribe(() => {
				this.toastr.success(
					isOneTime ? 'RSVP to this meeting only' : 'Set recurring meetup',
				);
				this.saving = false;
				this.close(true);
			});
		}
	};

	public close = (confirmed?: boolean) => {
		this.activeModal.close(confirmed);
	};
}
