import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneAbstractControl } from 'src/lib/utilities/forms';
import { ModalMoverComponent } from '../../../../global/modal-mover/modal-mover.component';
import { PaginationTableColumnDirective } from '../../children/pagination-table-column.directive';
import { PaginationTableFormModalCustomDirective } from '../../children/pagination-table-form-modal-custom.directive';

@Component({
	selector: 'ae-pagination-table-search-modal',
	templateUrl: './pagination-table-search-modal.component.html',
	styleUrls: ['./pagination-table-search-modal.component.scss'],
	imports: [
		FormsModule,
		ModalMoverComponent,
		NgTemplateOutlet,
		ReactiveFormsModule,
	],
})
export class PaginationTableSearchModalComponent<T> {
	@Input() formGroup: FormGroup;
	@Input() columnTemplates: PaginationTableColumnDirective<T>[];
	@Input() customFormTemplate: PaginationTableFormModalCustomDirective;

	public clonedFormGroup: FormGroup;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		formGroup: FormGroup;
		columnTemplates: PaginationTableColumnDirective<T>[];
		customFormTemplate: PaginationTableFormModalCustomDirective;
	}): void => {
		// SET DATA
		this.formGroup = data.formGroup;
		this.columnTemplates = data.columnTemplates;
		this.customFormTemplate = data.customFormTemplate;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.clonedFormGroup = cloneAbstractControl(this.formGroup);
	};

	public searchableColumns = () => {
		return this.columnTemplates.filter(
			(x: PaginationTableColumnDirective<T>) => {
				return x.searchTemplate != null;
			},
		);
	};

	public accept = () => {
		this.activeModal.close(this.clonedFormGroup);
	};

	public cancel = () => {
		this.activeModal.dismiss();
	};
}
