import { Directive, Input, TemplateRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { IPaginationTable } from '../pagination-table-config';

export declare class PaginationTableColumnSearchDirectiveContext<
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	T,
	F extends {
		[K in keyof F]: AbstractControl<any>;
	} = any,
> {
	$implicit: FormGroup<F>;
}

@Directive({
	selector: '[aePgtSearch]',
})
export class PaginationTableColumnSearchDirective<
	T,
	F extends {
		[K in keyof F]: AbstractControl<any>;
	},
> {
	@Input() public aePgtSearch: IPaginationTable<T, F>;
	@Input() public cellClass: string;

	constructor(
		public template: TemplateRef<
			PaginationTableColumnSearchDirectiveContext<T, F>
		>,
	) {}

	static ngTemplateGuard_aePgtSearch: 'binding';
	static ngTemplateContextGuard<
		T,
		F extends {
			[K in keyof F]: AbstractControl<any>;
		},
	>(
		_dir: PaginationTableColumnSearchDirective<T, F>,
		_ctx: any,
	): _ctx is PaginationTableColumnSearchDirectiveContext<T, F> {
		return true;
	}
}
