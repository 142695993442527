import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, ElementRef, NgZone } from '@angular/core';
import {
	OverlayScrollbarsComponent,
	OverlayscrollbarsModule,
} from 'overlayscrollbars-ngx';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'overlay-scrollbars-dragdrop',
	host: { 'data-overlayscrollbars-initialize': '' },
	// This template needs to match the ngx-overlayscrollbars template
	template: `
		<div
			#content
			cdkScrollable
			data-overlayscrollbars-contents=""
			overlayScrollbars
			[defer]="defer"
			[events]="mergeEvents(events)"
			[options]="options"
		>
			<ng-content />
		</div>
	`,
	imports: [CdkScrollable, OverlayscrollbarsModule],
})
export class OverlayScrollbarsDragdropComponent extends OverlayScrollbarsComponent {
	constructor(ngZone: NgZone, targetRef: ElementRef<HTMLElement>) {
		super(ngZone, targetRef);
	}
}
