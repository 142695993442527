import { KeyValue, KeyValuePipe } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncSubject, Observable, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import spacetime, { type Spacetime } from 'spacetime';
import { Permits } from 'src/lib/constants/permissions';
import { StudentEventUpsertOptions } from 'src/lib/services/api/students/events/student-event-upsert-options.model';
import { StudentEventUpsertArgument } from 'src/lib/services/api/students/events/student-event-upsert.argument';
import {
	EventCategory,
	StudentEventFile,
	StudentEventModel,
} from 'src/lib/services/api/students/events/student-event.model';
import { StudentsEventsService } from 'src/lib/services/api/students/events/students-events.service';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { StudentEventStoreService } from 'src/lib/services/stores/students/event/student-event-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { mergeStrings } from 'src/lib/utilities/array';
import { convertMapToArray } from 'src/lib/utilities/convert';
import {
	commonValidators,
	forceUpdateStatus,
	markFormGroupTouched,
} from 'src/lib/utilities/forms';
import {
	calendarDate,
	localCalendarDateOrNull,
} from 'src/lib/utilities/spacetime';
import { firstBy } from 'thenby';
import { FileUploaderComponent } from '../../../../../templates/controls/file-uploader/file-uploader.component';
import { InputCheckboxComponent } from '../../../../../templates/controls/input-checkbox/input-checkbox.component';
import { SingleDatePickerComponent } from '../../../../../templates/controls/single-date-picker/single-date-picker.component';
import { NgxEditorComponent } from '../../../../../templates/controls/text-editors/ngx-editor/ngx-editor.component';
import { TimezoneNoticeComponent } from '../../../../../templates/global/timezone-notice/timezone-notice.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

interface EventFormGroup {
	category: FormControl<string>;
	medium: FormControl<string>;
	subject: FormControl<string>;
	purpose: FormControl<string[]>;
	body: FormControl<string>;
	permission_level: FormControl<boolean>;
	noteAdditional: FormGroup<EventAdditionalGroup>;
	taskAdditional: FormGroup<TaskAdditionalGroup>;
	tags: FormControl<string[]>;
	file: FormControl<File>;
	registrationId: FormControl<number>;
	relationship: FormControl<string>;
}

interface EventAdditionalGroup {
	twoWayContact: FormControl<string>;
	initiatedByStudent: FormControl<string>;
	completeDate: FormControl<Date>;
}

interface DueDateGroup {
	date: Date;
	time: string;
}

interface TaskAdditionalGroup {
	dueDate: FormGroup<FormControlWrapper<DueDateGroup>>;
	assignedUid: FormControl<number>;
	notifyCompleted: FormControl<boolean>;
}

interface TagOption {
	key: string;
	value: string;
	parent: string;
}

@Component({
	selector: 'ae-event-upsert',
	templateUrl: './event-upsert.component.html',
	styleUrls: ['./event-upsert.component.scss'],
	imports: [
		FileUploaderComponent,
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		InputCheckboxComponent,
		KeyValuePipe,
		NgSelectModule,
		NgxEditorComponent,
		ReactiveFormsModule,
		SingleDatePickerComponent,
		SpinWhileDirective,
		TimezoneNoticeComponent,
		ValidationErrorDirective,
	],
})
export class EventUpsertComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() studentId: number;
	@Input() editEvent: StudentEventModel = null;
	@Output() isFormValid = new EventEmitter<boolean>();
	@Output() isFormPristine = new EventEmitter<boolean>();

	public EventCategory = EventCategory;
	public programTZ: string;
	public eventFormGroup: FormGroup<EventFormGroup>;
	public allOptions: StudentEventUpsertOptions;
	public assignOptions: { key: string; value: string }[] = [];
	public tagOptions: TagOption[] = [];
	public purposeOptions: KeyValue<string, string>[] = [];
	public relationships: { key: string; value: string }[] = [];

	public loading = true;
	public errorLoading = false;
	public minimumDueDate: Date;
	public maximumCompleteDate: Date;

	// files
	public filesToSave: File[] = [];
	public filesToRemove: number[] = [];
	public loadedFiles: StudentEventFile[] = [];

	public showAdditional: boolean = false;
	public permissions = {
		canMarkInternal: false,
		canCreateTask: false,
		canAddNote: false,
		canAddFile: false,
		canRemoveFile: false,
	};

	public vals = [];

	public eventFormGroupReady$: Observable<FormGroup<EventFormGroup>> =
		new AsyncSubject<FormGroup<EventFormGroup>>();

	constructor(
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder,
		private permissionService: PermissionStoreService,
		private eventsService: StudentsEventsService,
		private studentEventStoreService: StudentEventStoreService,
		private usersService: UsersService,
	) {}

	ngOnInit() {
		this.loadFormInfo();
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public loadFormInfo = () => {
		this.loading = true;
		this.errorLoading = false;

		combineLatest([
			this.permissionService
				.getFieldSet$({
					UserId: this.studentId,
				})
				.pipe(
					tap((x) => {
						this.permissions.canMarkInternal = x.canDo(
							Permits['ga_user_log|mark internal only logs'],
						);
						this.permissions.canAddNote = x.canDo(
							Permits['ga_user_log|add user log entities'],
						);
						this.permissions.canCreateTask = x.canDo(
							Permits['ga_user_log|create user log tasks'],
						);
						this.permissions.canAddFile = x.canDo(
							Permits['ga_user_log|upload user log files'],
						);
						this.permissions.canRemoveFile = x.canDo(
							Permits['ga_user_log|delete user log files'],
						);
					}),
				),
			this.studentEventStoreService.options$(this.studentId).pipe(
				tap((x) => {
					this.createOptions(x);
				}),
			),
			this.usersService.getTimezone(this.studentId).pipe(
				tap((tz) => {
					this.programTZ = tz.institute_timezone;
					this.minimumDueDate = new Date();
					this.maximumCompleteDate = spacetime
						.now()
						.endOf('date')
						.toNativeDate();
				}),
			),
		])
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: () => {
					this.initForm();
					const subject = this.eventFormGroupReady$ as AsyncSubject<
						FormGroup<EventFormGroup>
					>;
					subject.next(this.eventFormGroup);
					subject.complete();
				},
				error: (errors) => {
					console.error(mergeStrings(errors));
					this.errorLoading = true;
					this.loading = false;
				},
			});
	};

	private createOptions = (options: StudentEventUpsertOptions) => {
		this.allOptions = options;
		if (options) {
			this.assignOptions = convertMapToArray(options.assigned_uid).sort(
				firstBy((x) => x.value),
			);
			this.convertTagOptions(options.tags);
			this.getPurposeOptions(options.purposes);

			this.relationships = Object.keys(options.relationships).map(
				(relationship) => {
					return {
						key: relationship,
						value: options.relationships[relationship],
					};
				},
			);
		}
	};

	private convertTagOptions = (tags: any) => {
		Object.keys(tags).forEach((parentTag) => {
			Object.keys(tags[parentTag]).forEach((childTag) => {
				this.tagOptions.push({
					key: childTag,
					value: tags[parentTag][childTag],
					parent: parentTag,
				});
			});
		});
		this.tagOptions.sort(
			firstBy<TagOption, string>((x) => x.parent).thenBy((y) => y.value),
		);
	};

	private getPurposeOptions = (obj: Record<any, string>) => {
		if (obj) {
			Object.keys(obj).forEach((key) => {
				this.purposeOptions.push({
					key: key,
					value: obj[key],
				});
			});
		}
	};

	public save$ = (): Observable<StudentEventModel> => {
		const saveArgs = this.setSaveArgs();
		const files = this.filesToSave;

		if (this.editEvent != null) {
			return this.eventsService.updateStudentEvent(
				this.studentId,
				this.editEvent.id,
				saveArgs,
				files,
			);
		} else {
			return this.eventsService.createStudentEvent(
				this.studentId,
				saveArgs,
				files,
			);
		}
	};

	private setSaveArgs = () => {
		const values = this.eventFormGroup.value;
		const args: StudentEventUpsertArgument = {
			subject: values.subject,
			category: values.category,
			body: values.body,
			medium: values.medium,
			tags: values.tags,
			registration_id: values.registrationId,
			purpose: values.purpose,
			relationship: values.relationship,
		};

		if (args.purpose[0] == null) {
			args.purpose = null;
		}

		if (this.permissions.canMarkInternal) {
			args.permission_level = values.permission_level ? '1' : '0';
		}

		if (this.filesToRemove.length > 0) {
			args.files__delete = this.filesToRemove;
		}

		if (this.eventFormGroup.controls.noteAdditional.enabled) {
			args.initiated_by = values.noteAdditional.initiatedByStudent;
			args.twoway_contact = values.noteAdditional.twoWayContact;
			if (values.noteAdditional.completeDate) {
				args.complete_date = Math.trunc(
					calendarDate(
						spacetime(values.noteAdditional.completeDate),
						this.programTZ,
					)
						.startOf('date')
						.add(6, 'hour').epoch / 1000,
				);
			}
		}
		if (this.eventFormGroup.controls.taskAdditional.enabled) {
			args.assigned_uid = values.taskAdditional.assignedUid;
			args.due_date = Math.trunc(
				this.getTimeStamp(values.taskAdditional.dueDate).epoch / 1000,
			);
			if (
				this.eventFormGroup.controls.taskAdditional.controls.notifyCompleted
					.enabled
			) {
				args.notify = values.taskAdditional.notifyCompleted;
			}
		}
		return args;
	};

	public resetForm = () => {
		this.eventFormGroup.reset();

		this.filesToRemove = [];
		this.filesToSave = [];
		this.showAdditional = false;
		this.setEventFormState();

		this.eventFormGroup.controls.taskAdditional.controls.dueDate.setErrors(
			null,
		);

		this.isFormValid.emit(false);
		this.isFormPristine.emit(true);
	};

	public removeFileToSave = (index: number) => {
		this.filesToSave.splice(index, 1);
	};

	public removeSavedFile = (file: StudentEventFile) => {
		const fileIndex = this.loadedFiles.findIndex((x) => x.fid === file.fid);

		this.filesToRemove.push(file.fid);
		this.loadedFiles.splice(fileIndex, 1);
		this.isFormValid.emit(this.eventFormGroup.valid);
	};

	// Prefill Actions
	public prefillAttempt = () => {
		const efgCtrls = this.eventFormGroup.controls;
		// Attempt to find options (and not break if they're aren't there)
		if (this.allOptions.medium.has('Phone')) {
			efgCtrls.medium.patchValue('Phone');
		}
		efgCtrls.subject.patchValue('Attempted to Contact');
		efgCtrls.noteAdditional.patchValue({
			twoWayContact: '0',
			initiatedByStudent: '1',
		});
	};

	public prefillTwoWay = () => {
		const efgCtrls = this.eventFormGroup.controls;
		// Attempt to find options (and not break if they're aren't there)
		if (this.allOptions.medium.has('Phone')) {
			efgCtrls.medium.patchValue('Phone');
		}
		efgCtrls.subject.patchValue('Completed Contact');
		efgCtrls.noteAdditional.patchValue({
			twoWayContact: '1',
			initiatedByStudent: '1',
		});
	};

	private initForm = () => {
		this.isFormValid.emit(false);

		this.eventFormGroup = this.fb.group<EventFormGroup>({
			category: new FormControl({ value: null, disabled: true }),
			subject: new FormControl(null, commonValidators.requiredNotEmpty),
			body: new FormControl(null, commonValidators.requiredNotEmpty),
			medium: new FormControl(null, Validators.required),
			permission_level: new FormControl({
				value: false,
				disabled: !this.permissions.canMarkInternal,
			}),
			registrationId: new FormControl(null),
			tags: new FormControl([]),
			file: new FormControl({
				value: null,
				disabled: !this.permissions.canAddFile,
			}),
			purpose: new FormControl(null, Validators.required),
			relationship: new FormControl(null, Validators.required),
			noteAdditional: this.fb.group<EventAdditionalGroup>({
				initiatedByStudent: new FormControl(null),
				twoWayContact: new FormControl(null, Validators.required),
				completeDate: new FormControl(null),
			}),
			taskAdditional: this.fb.group<TaskAdditionalGroup>({
				assignedUid: new FormControl(null, Validators.required),
				dueDate: this.fb.group<FormControlWrapper<DueDateGroup>>(
					{
						date: new FormControl(null, Validators.required),
						time: new FormControl(null, Validators.required),
					},
					{
						validators: [
							(group: FormGroup<FormControlWrapper<DueDateGroup>>) => {
								if (
									!group.pristine &&
									group.controls.date.valid &&
									group.controls.time.valid
								) {
									if (
										this.getTimeStamp(group.value).epoch <
										spacetime.now(this.programTZ).epoch
									) {
										return { time: 'Due date and time can not be in the past' };
									}
								}

								return null;
							},
						],
					},
				),
				notifyCompleted: new FormControl({
					value: false,
					disabled: this.editEvent != null,
				}),
			}),
		});
		this.setEventFormState();

		this.eventFormGroup.controls.purpose.valueChanges.subscribe((val) => {
			if (val) {
				const len = val.length - 1;
				let arr;
				if (len > -1 && val[len] == null) {
					arr = [null];
				} else if (val.includes(null)) {
					arr = val.filter((v) => {
						return v != null;
					});
				}

				if (arr) {
					this.eventFormGroup.controls.purpose.setValue(arr, {
						emitEvent: false,
					});
				}
			}
		});

		this.eventFormGroup.valueChanges.subscribe(() => {
			this.isFormValid.emit(this.eventFormGroup.valid);
			this.isFormPristine.emit(this.eventFormGroup.pristine);
		});

		if (this.eventFormGroup.controls.file.enabled) {
			this.eventFormGroup.controls.file.valueChanges.subscribe((file) => {
				if (file != null) {
					this.filesToSave.unshift(file);
					this.eventFormGroup.controls.file.setValue(null);

					// File inputs get stuck after they get a file in them. We need to blink the element
					this.eventFormGroup.controls.file.disable();
					this.cdr.detectChanges();
					this.eventFormGroup.controls.file.enable();
					this.cdr.detectChanges();
				}
			});
		}
	};

	private getTimeStamp = (group: Partial<DueDateGroup>): Spacetime => {
		return calendarDate(spacetime(group.date), this.programTZ)
			.hour(parseInt(group.time.split(':')[0]))
			.minute(parseInt(group.time.split(':')[1]))
			.second(0);
	};

	private setEventFormState = () => {
		const isEdit = this.editEvent != null;
		const category = this.eventFormGroup.controls.category;

		this.loadedFiles = [];

		if (!isEdit) {
			this.eventFormGroup.controls.noteAdditional.controls.initiatedByStudent.setValidators(
				Validators.required,
			);
			this.eventFormGroup.controls.taskAdditional.controls.dueDate.patchValue({
				date: spacetime.now().add(1, 'week').toNativeDate(),
				time: '17:00',
			});

			if (this.permissions.canAddNote && this.permissions.canCreateTask) {
				category.enable();
				category.setValue(EventCategory.note);
				category.valueChanges.subscribe((val) => {
					if (val === EventCategory.task) {
						this.eventFormGroup.controls.taskAdditional.enable();
						this.eventFormGroup.controls.noteAdditional.disable();
					} else {
						this.eventFormGroup.controls.taskAdditional.disable();
						this.eventFormGroup.controls.noteAdditional.enable();
					}
				});
			} else if (this.permissions.canCreateTask) {
				category.setValue(EventCategory.task);
			} else category.setValue(EventCategory.note);
		} else {
			const eventPurposes = Object.keys(this.editEvent.purposes);

			this.eventFormGroup.patchValue({
				category: this.editEvent.category,
				subject: this.editEvent.subject,
				purpose: eventPurposes?.length === 0 ? [null] : eventPurposes,
				body: this.editEvent.body,
				medium: this.editEvent.medium,
				registrationId: this.editEvent.registration_id,
				tags: this.editEvent.tags || [],
				permission_level:
					this.editEvent.permission_level === '1' ? true : false,
				relationship: this.editEvent.relationship,
			});
			if (this.editEvent.files.length > 0) {
				this.editEvent.files.forEach((file) => {
					this.loadedFiles.push(file);
				});
			}
		}

		if (this.eventFormGroup.controls.category.value === EventCategory.task) {
			this.eventFormGroup.controls.noteAdditional.disable();
			if (isEdit) {
				this.eventFormGroup.controls.taskAdditional.controls.assignedUid.setValue(
					this.editEvent.assigned_uid,
				);

				if (this.editEvent.due_date != null) {
					const currentDueDate = localCalendarDateOrNull(
						this.editEvent.due_date,
						this.programTZ,
					);
					this.eventFormGroup.controls.taskAdditional.controls.dueDate.patchValue(
						{
							date: currentDueDate,
							time: spacetime(currentDueDate).format('time-24'),
						},
					);
				}
			}
		} else {
			this.eventFormGroup.controls.taskAdditional.disable();
			if (isEdit) {
				switch (this.editEvent.twoway_contact) {
					case true:
						this.eventFormGroup.controls.noteAdditional.controls.twoWayContact.setValue(
							'1',
						);
						break;
					case false:
						this.eventFormGroup.controls.noteAdditional.controls.twoWayContact.setValue(
							'0',
						);
						break;
					default:
						break;
				}

				switch (this.editEvent.initiated_by) {
					case null:
						break;
					case 'creator':
						this.eventFormGroup.controls.noteAdditional.controls.initiatedByStudent.setValue(
							'1',
						);
						break;
					default:
						this.eventFormGroup.controls.noteAdditional.controls.initiatedByStudent.setValue(
							'0',
						);
						break;
				}

				this.eventFormGroup.controls.noteAdditional.controls.completeDate.setValue(
					localCalendarDateOrNull(this.editEvent.complete_date),
				);
			}
		}
		this.eventFormGroup.markAsPristine();

		if (isEdit) {
			markFormGroupTouched(this.eventFormGroup);
			forceUpdateStatus(this.eventFormGroup);
		}

		this.loading = false;
	};
}
