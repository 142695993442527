import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { StaffDataModel } from 'src/lib/services/api/users/staff-data.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { SpinWhileDirective } from '../../layout/spin-while/spin-while.directive';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';

let canUseStaffViewer$: Observable<boolean> = null;
let canUseStaffViewer: boolean = null;

@Component({
	selector: 'ae-staff-viewer',
	templateUrl: './staff-viewer.component.html',
	styleUrls: ['./staff-viewer.component.scss'],
	imports: [
		NgbPopover,
		NgTemplateOutlet,
		ProfilePictureComponent,
		SpinWhileDirective,
	],
})
export class StaffViewerComponent implements OnInit {
	@Input() userId: number;

	public userData: StaffDataModel;
	public popoverEnabled = null;
	public canUsePopover = null;
	public popoverClass = 'd-none';
	public hasViewed: boolean = false;

	@ViewChild('popover', { static: false }) ngbPopover: NgbPopover;

	constructor(
		private usersService: UsersService,
		private permissionService: PermissionStoreService,
	) {}

	ngOnInit() {
		if (canUseStaffViewer$ == null) {
			canUseStaffViewer$ = this.permissionService.getFieldSet$().pipe(
				map((p) => {
					return p.canDo(Permits['ga_user|view all users']);
				}),
			);
			canUseStaffViewer$.subscribe((x) => {
				this.canUsePopover = x;
				canUseStaffViewer = x;
			});
		} else {
			this.canUsePopover = canUseStaffViewer;
		}
	}

	enablePopover = () => {
		if (this.popoverEnabled === null) {
			this.popoverEnabled = true;
		}
	};

	public loadData = () => {
		if (this.ngbPopover && this.popoverEnabled) {
			this.popoverClass = 'popover-dark w-100';
		}
		if (this.userData == null && !this.hasViewed && this.userId) {
			this.hasViewed = true;
			this.usersService.getStaffData(this.userId).subscribe({
				next: (x) => {
					this.userData = x;
				},
				error: (errors) => {
					this.popoverEnabled = false;
					this.canUsePopover = false;
					console.error('Error in StaffViewerComponent', mergeStrings(errors));
					this.ngbPopover?.close();
				},
			});
		}
	};
}
