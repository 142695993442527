import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/public.services';
import { WaitSpinnerComponent } from 'src/public.templates';
import { MapResponseErrors, mergeStrings } from 'src/public.utilities';
import { LoginState } from '../../login-state.enum';

@Component({
	selector: 'gal-password-reset',
	imports: [FormsModule, ReactiveFormsModule, WaitSpinnerComponent],
	templateUrl: './password-reset.component.html',
	styleUrl: './password-reset.component.scss',
})
export class PasswordResetComponent implements OnInit {
	@Input() public email: string = null;
	@Output() public setState = new EventEmitter<LoginState>();
	@Output() public processing = new EventEmitter<boolean>();

	public loginState = LoginState;
	public ctrl: FormControl<string>;

	public loading = true;
	public saving = false;

	constructor(
		private loginService: LoginService,
		private toastr: ToastrService,
	) {}

	ngOnInit(): void {
		this.ctrl = new FormControl(this.email, [
			Validators.required,
			Validators.email,
		]);

		this.loading = false;
	}

	requestReset = () => {
		this.processing.emit(true);

		this.loginService.requestPasswordReset(this.ctrl.value).subscribe({
			complete: () => {
				this.toastr.success(
					'A link to reset your password will be sent to the provided email if valid',
					'Reset Password',
				);
				this.processing.emit(false);
				this.setState.emit(LoginState.credential);
			},
			error: (errors: MapResponseErrors) => {
				this.toastr.error(mergeStrings(errors), 'Reset Password');
				this.processing.emit(false);
			},
		});
	};
}
