import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { ProfilePictureComponent } from '../profile-picture.component';

@Component({
	selector: 'ae-user-profile-picture',
	templateUrl: './user-profile-picture.component.html',
	styleUrls: ['./user-profile-picture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ProfilePictureComponent],
})
export class UserProfilePictureComponent
	implements OnInit, OnChanges, OnDestroy
{
	@HostBinding('class.text-secondary') textColor = true;

	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() userUid: number;

	public userProfileImagePath: string;
	private userUidSubject = new BehaviorSubject<number>(
		this.userStoreService.currentUserUid,
	);

	constructor(
		private userStoreService: UserStoreService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.userUidSubject
			.pipe(
				tap(() => (this.userProfileImagePath = null)),
				switchMap((x) => this.userStoreService.userData$(x)),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((user) => {
				this.userProfileImagePath = user?.user?.profile_picture;
				this.cdr.detectChanges();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.userUid) {
			this.userUidSubject.next(changes.userUid.currentValue);
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
