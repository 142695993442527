import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'if',
})
export class IfPipe implements PipeTransform {
	transform<T>(value: T, display: boolean, elseValue: string = ''): T | string {
		if (display) {
			return value;
		} else {
			return elseValue;
		}
	}
}
