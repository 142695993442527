import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OnlineStatusBubbleComponent } from '../../online-status-bubble/online-status-bubble.component';

@Component({
	selector: 'ae-profile-picture-online-status',
	templateUrl: './profile-picture-online-status.component.html',
	styleUrls: ['./profile-picture-online-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [OnlineStatusBubbleComponent],
})
export class ProfilePictureOnlineStatusComponent {
	@Input() public linkIds: number[] | number;
}
