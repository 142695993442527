import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'ae-confirm-exit-guard-modal',
	templateUrl: './confirm-exit-guard-modal.component.html',
	styleUrls: ['./confirm-exit-guard-modal.component.scss'],
})
export class ConfirmExitGuardModalComponent {
	@Input() warningMessage: string;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { warningMessage: string }): void => {
		// SET DATA
		this.warningMessage = data.warningMessage;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};
}
