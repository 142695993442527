import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgDompurifyPipe } from 'src/lib/thirdparty/ng-dompurify/ng-dompurify.pipe';
import { printHTML } from 'src/lib/utilities/html';
import { WaitSpinnerComponent } from '../../../templates/global/wait-spinner/wait-spinner.component';

export interface PrintModalOptions {
	modalTitle: string;
	printHeader: string;
}

@Component({
	selector: 'ae-print-modal',
	templateUrl: './print-modal.component.html',
	styleUrls: ['./print-modal.component.scss'],
	imports: [NgDompurifyPipe, WaitSpinnerComponent],
})
export class PrintModalComponent {
	@Input() html: string;
	@Input() opts: PrintModalOptions = {
		modalTitle: 'Print',
		printHeader: null,
	};

	public printing = false;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private ngDomPurifyPipe: NgDompurifyPipe,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		html: string;
		opts: PrintModalOptions;
	}): void => {
		// SET DATA
		this.html = data.html;
		this.opts = data.opts;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};

	public print = () => {
		this.printing = true;
		printHTML(this.ngDomPurifyPipe.transform(this.html), this.opts.printHeader);
		// to disable button and prevent multiple openings
		setTimeout(() => {
			this.printing = false;
		}, 500);
	};
}
