import { NgClass } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import {
	NavigationEnd,
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncSubject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { hasValue, isNullOrEmptyString } from 'src/lib/utilities/compare';
import { Key } from 'ts-key-enum';
import { AnchorLinkDirective } from '../../global/anchor-link/anchor-link.directive';
import { SizeMonitorDirective } from '../../global/size-monitor/size-monitor.directive';
import { RightSideInfoFloaterComponent } from '../rightside-info-floater/rightside-info-floater.component';
import { RightSideInfoFloaterModel } from '../rightside-info-floater/rightside-info-floater.model';
import { SpinWhileDirective } from '../spin-while/spin-while.directive';
import { SidenavTabberTab } from './sidenav-tabber.config';

@Component({
	selector: 'ae-sidenav-tabber',
	templateUrl: './sidenav-tabber.component.html',
	styleUrls: ['./sidenav-tabber.component.scss'],
	imports: [
		AnchorLinkDirective,
		FormsModule,
		NgClass,
		NgSelectModule,
		ReactiveFormsModule,
		RightSideInfoFloaterComponent,
		RouterLink,
		RouterLinkActive,
		RouterOutlet,
		SizeMonitorDirective,
		SpinWhileDirective,
	],
})
export class SidenavTabberComponent implements OnInit, OnChanges, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public isStudentApp = getEnvironment().isApp('student');

	@Input() public tabs: SidenavTabberTab[];
	@Input() public id: string;
	@Input() public size: 'sm' | 'md' | 'lg' | null;
	@Input() rightSideInfoConfig: RightSideInfoFloaterModel = null;
	@Input() public customTitle: boolean = false;
	@Input() public hideOverflow: boolean = false;

	@Output() selectedTab = new EventEmitter<string>();

	public visibleTabs: SidenavTabberTab[];
	public selectedTabCtrl: FormControl<string>;

	public collapsed: boolean = false;
	public animationOn: boolean = false;

	@ViewChild('sidenavTabberContainer', { static: true })
	private sidenavTabberContainer: ElementRef<HTMLElement>;

	@ViewChildren('sidenavTab') private sidenavTabs: QueryList<
		ElementRef<HTMLElement>
	>;

	public activatedTab: SidenavTabberTab | null = null;

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private localStore: LocalStoreService,
	) {
		this.selectedTabCtrl = this.fb.control<string>(null);
		this.selectedTabCtrl.valueChanges
			.pipe(
				filter((x) => {
					return (
						this.router.isActive(x, {
							fragment: 'ignored',
							matrixParams: 'ignored',
							queryParams: 'ignored',
							paths: 'subset',
						}) === false // If the route is active, no need to navigate
					);
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((x) => {
				const tab = this.tabs?.find((t) => t.path === x);
				if (tab) {
					this.router.navigate([tab.path], {
						queryParamsHandling: 'preserve',
						replaceUrl: this.activatedTab == null,
					});
				}
			});

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				debounceTime(1),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.ensureActivatedTab();
			});

		this.setActivatedTab();
	}

	ngOnInit() {
		if (isNullOrEmptyString(this.id)) {
			throw new Error('Error in ae-sidenav-tabber, id is required');
		}

		if (!this.isStudentApp) {
			this.collapsed = this.localStore.get(
				`sidenav-tabber-collapsed-${this.id}`,
				TypedStoreType.BOOLEAN,
				false,
			);
			this.animationOn = this.collapsed;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.tabs && this.tabs) {
			this.visibleTabs = this.tabs.filter((x) => x.hasPermission);
			this.ensureActivatedTab();
		}
	}

	public onKeyDown = (event: KeyboardEvent): void => {
		if (event.key === Key.ArrowUp || event.key === Key.ArrowLeft) {
			this.focusNextTab('up');
			event.preventDefault();
		} else if (event.key === Key.ArrowDown || event.key === Key.ArrowRight) {
			this.focusNextTab('down');
			event.preventDefault();
		}
	};

	public focusNextTab = (direction: 'up' | 'down'): void => {
		const tabs = this.sidenavTabs?.toArray() ?? [];
		if (tabs.length === 0) {
			return;
		}

		let activeI = tabs.findIndex(
			(t) => document.activeElement === t.nativeElement,
		);
		if (direction === 'down') {
			activeI++;
			if (activeI > tabs.length - 1) {
				activeI = 0;
			}
		} else {
			activeI--;
			if (activeI < 0) {
				activeI = tabs.length - 1;
			}
		}

		tabs[activeI].nativeElement.focus();
	};

	private ensureActivatedTab = () => {
		this.setActivatedTab();

		if (this.activatedTab == null && this.visibleTabs) {
			if (this.visibleTabs.length > 0) {
				this.selectedTabCtrl.setValue(this.visibleTabs[0].path);
			} else {
				this.router.navigate(['nopermission']);
			}
		}
	};

	private setActivatedTab = (): boolean => {
		const activatedTab = this.tabs?.find((t) =>
			this.router.isActive(t.path, {
				fragment: 'ignored',
				matrixParams: 'ignored',
				queryParams: 'ignored',
				paths: 'subset',
			}),
		);

		const tabChanged = this.activatedTab !== activatedTab;
		this.activatedTab = activatedTab;

		if (this.activatedTab) {
			this.hideOverflow = hasValue(this.activatedTab.hideOverflow)
				? this.activatedTab.hideOverflow
				: false;

			this.selectedTabCtrl.setValue(this.activatedTab.path);
		}

		this.selectedTab.emit(this.activatedTab?.name);

		return tabChanged;
	};

	public toggleCollapse = (element?: HTMLElement) => {
		this.collapsed = !this.collapsed;
		if (!this.collapsed) {
			this.animationOn = false;
		} else {
			element?.blur();
		}

		this.localStore.set(`sidenav-tabber-collapsed-${this.id}`, this.collapsed);

		setTimeout(() => {
			this.animationOn = this.collapsed;
		}, 15);
	};

	public buttonEnter = () => {
		if (
			this.sidenavTabberContainer.nativeElement.querySelector(
				'.sidenav-tabber-cover',
			)?.clientWidth < 10
		) {
			this.sidenavTabberContainer.nativeElement.classList.add('hover-suppress');
		}
	};

	public buttonLeave = () => {
		this.sidenavTabberContainer.nativeElement.classList.remove(
			'hover-suppress',
		);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
