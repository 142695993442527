import {
	Directive,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Directive({
	selector: '[aeModalHideUntil]',
})
export class ModalHideUntilDirective implements OnChanges {
	@Input() aeModalHideUntil: any;

	constructor(private elemRef: ElementRef<HTMLElement>) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.aeModalHideUntil) {
			let modalWindow = this.elemRef.nativeElement.parentElement;
			while (
				modalWindow != null &&
				modalWindow.nodeName !== 'NGB-MODAL-WINDOW'
			) {
				modalWindow = modalWindow.parentElement;
			}

			if (modalWindow == null) {
				throw new Error(
					'aeModalHideUntil could not find the parent modal window tag NGB-MODAL-WINDOW. aeModalHideUntil can only be used in ngbModals',
				);
			}

			const backdrop =
				modalWindow.previousElementSibling?.nodeName === 'NGB-MODAL-BACKDROP'
					? (modalWindow.previousElementSibling as HTMLElement)
					: null;

			if (changes.aeModalHideUntil.currentValue) {
				modalWindow.style.removeProperty('display');

				if (backdrop) {
					backdrop.style.removeProperty('display');
				}
			} else {
				modalWindow.style.setProperty('display', 'none', 'important');

				if (backdrop) {
					backdrop.style.setProperty('display', 'none', 'important');
				}
			}
		}
	}
}
