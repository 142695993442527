import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { isNonEmptyString } from 'src/lib/utilities/compare';
import { convertURLToFile$ } from 'src/lib/utilities/file';
import { FileUploaderComponent } from '../../../../templates/controls/file-uploader/file-uploader.component';

@Component({
	selector: 'ae-profile-image-upload-modal',
	templateUrl: './profile-image-upload-modal.component.html',
	styleUrls: ['./profile-image-upload-modal.component.scss'],
	imports: [FileUploaderComponent, FormsModule, ReactiveFormsModule],
})
export class ProfileImageUploadModalComponent {
	@Input() public userId: number;
	@Input() public currentImg: string;

	public isSaving: boolean = false;

	public fileGroup: FormGroup;
	public maxProfileSize =
		getEnvironment().settings.services.utilities.profileFileSize;

	constructor(
		private fb: FormBuilder,
		public activeModal: NgbActiveModal,
		private userService: UsersService,
		private changeDetector: ChangeDetectorRef,
		private toastSerivce: ToastrService,
	) {
		this.fileGroup = this.fb.group({
			file: [null, Validators.required],
		});
	}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		userId: number;
		currentImg: string;
	}): void => {
		// SET DATA
		this.userId = data.userId;
		this.currentImg = data.currentImg;

		// DETECT CHANGES
		this.changeDetector.markForCheck();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		if (isNonEmptyString(this.currentImg)) {
			convertURLToFile$(this.currentImg).subscribe((file) => {
				this.fileGroup.controls.file.setValue(file);
				this.fileGroup.markAsPristine();
			});
		}
	};

	public save = () => {
		if (this.fileGroup.dirty) {
			this.isSaving = true;

			this.userService
				.updateProfilePicture(this.userId, this.fileGroup.controls.file.value)
				.pipe(finalize(() => (this.isSaving = false)))
				.subscribe({
					next: () => {
						this.toastSerivce.success('New profile image saved!');
						this.activeModal.close();
					},
					error: (errors) => {
						this.toastSerivce.error(
							`Error uploading file: ${mergeStrings(errors)}`,
						);
					},
				});
		} else {
			this.activeModal.close();
		}
	};

	public close = (success: boolean) => {
		if (success) this.activeModal.close();
		else this.activeModal.dismiss();
	};
}
