import { ElementRef, Pipe, PipeTransform } from '@angular/core';
import { hasValue, isNonEmptyString } from '../utilities/compare';
import { tryParseInt } from '../utilities/convert';

@Pipe({
	name: 'phone',
})
export class PhoneNumberPipe implements PipeTransform {
	public static toFormatted(input: number | string): string {
		const cleaned = String(input).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			const intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return '';
	}

	public static toNumber(input: string): number | null {
		input = input?.replace(/([^0-9])/g, '');
		return tryParseInt(input);
	}

	constructor(public readonly elementRef: ElementRef<HTMLElement>) {}

	public getNearestElement = (): HTMLElement => {
		let e = this.elementRef.nativeElement;
		while (e != null) {
			if (e.nodeType === Node.TEXT_NODE) {
				e = e.parentElement;
			} else {
				break;
			}
		}

		return e;
	};

	transform(
		input: number | string,
		userId?: number | string,
		userName?: number | string,
		studentId?: number | string,
	): string {
		const formatted = PhoneNumberPipe.toFormatted(input);
		if (hasValue(formatted) && (hasValue(userId) || hasValue(studentId))) {
			const elem = this.getNearestElement();

			if (hasValue(userId)) {
				elem.setAttribute(
					`phone-number-user-id-${PhoneNumberPipe.toNumber(formatted)}`,
					`${userId}`,
				);
			}

			if (isNonEmptyString(userName)) {
				elem.setAttribute(
					`phone-number-user-name-${PhoneNumberPipe.toNumber(formatted)}`,
					`${userName}`,
				);
			}

			if (hasValue(studentId)) {
				elem.setAttribute(
					`phone-number-student-id-${PhoneNumberPipe.toNumber(formatted)}`,
					`${studentId}`,
				);
			}
		}

		return formatted;
	}
}
