import { Pipe, PipeTransform } from '@angular/core';
import pluralize from 'pluralize';

@Pipe({
	name: 'outputTime',
})
export class OutputTimePipe implements PipeTransform {
	private output: string[] = [];
	private value: number;
	private types = {
		week: {
			divisor: 604800,
			outputSmall: 'w',
			outputLarge: 'week',
		},
		day: {
			divisor: 86400,
			outputSmall: 'd',
			outputLarge: 'day',
		},
		hour: {
			divisor: 3600,
			outputSmall: 'h',
			outputLarge: 'hour',
		},
		minute: {
			divisor: 60,
			outputSmall: 'm',
			outputLarge: 'minute',
		},
		second: {
			divisor: 1,
			outputSmall: 's',
			outputLarge: 'second',
		},
	};
	//format: [w, d, h, m, s]
	transform(
		value: number,
		args: string,
		format: 'verbose' | 'singular',
		separator: ', ' | ': ' | ' and ' = ', ',
	): unknown {
		if (value == null) return null;
		if (value <= 0) return null;
		this.value = value;
		if (args.includes('w')) {
			this.valueToTime('week', this.value, format);
		}
		if (args.includes('d')) {
			this.valueToTime('day', this.value, format);
		}
		if (args.includes('h')) {
			this.valueToTime('hour', this.value, format);
		}
		if (args.includes('m')) {
			this.valueToTime('minute', this.value, format);
		}
		if (args.includes('s')) {
			this.valueToTime('second', this.value, format);
		}

		const str = this.output.length > 0 ? this.output.join(separator) : null;
		this.output = [];
		return str;
	}

	private valueToTime(type: string, value: number, format: string): void {
		if ((this.value > 0 && format === 'verbose') || format === 'singular') {
			const t = this.types[type];
			const time = Math.floor(value / t.divisor);
			this.value = value % t.divisor;
			if (format === 'verbose') {
				this.output.push(`${time} ${pluralize(t.outputLarge, time)}`);
			} else if (format === 'singular') {
				this.output.push(`${time} ${t.outputSmall}`);
			}
		}
	}
}
