import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	ChannelModel,
	ChannelTopic,
} from 'src/lib/services/api/gabby/channels/channel.model';

@Component({
	selector: 'ae-gabby-channel-topic-label',
	templateUrl: './gabby-channel-topic-label.component.html',
	styleUrls: ['./gabby-channel-topic-label.component.scss'],
	imports: [NgClass],
})
export class GabbyChannelTopicLabelComponent {
	@Input() channel: ChannelModel;
	@Input() channelTopic: ChannelTopic;

	public getLabelType = () => {
		if (this.channel == null && this.channelTopic == null) return '';
		switch (this.channel?.topic_id || this.channelTopic) {
			case ChannelTopic.Custom:
				return 'c';
			case ChannelTopic.AcademicCoach:
				return 'ac';
			case ChannelTopic.Teacher:
				return 't';
			case ChannelTopic.LocalAdvocate:
				return 'la';
			case ChannelTopic.TechSupport:
				return 'ts';
			case ChannelTopic.CareerCounselor:
				return 'cc';
			case ChannelTopic.TestingCoordinator:
				return 'tc';
			case ChannelTopic.Principal:
				return 'pp';
			case ChannelTopic.Specialist:
				return 'sp';
			case ChannelTopic.GradCoordinator:
				return 'gc';
			case ChannelTopic.ReEntry:
				return 're';
			case ChannelTopic.Tutor:
				return 'tu';
			default:
				return '';
		}
	};
}
