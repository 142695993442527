import { TitleCasePipe } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { AsyncSubject, takeUntil } from 'rxjs';
import { getEnvironment } from 'src/lib/environment/environment';
import { LoginService } from 'src/public.services';
import { containsString, hasValue } from 'src/public.utilities';
import { LoginState } from '../../login-state.enum';
import { LoginSaveService } from '../../services/login-save.service';
import { SSOButtonInputModel } from './sso-button-input.model';

@Component({
	selector: 'gal-sso-button',
	imports: [TitleCasePipe],
	templateUrl: './sso-button.component.html',
	styleUrl: './sso-button.component.scss',
})
export class SsoButtonComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	@Input({ required: true }) data: SSOButtonInputModel;
	@Output() public setState = new EventEmitter<LoginState>();

	public ssoImage = ``;
	public loading = true;
	public parsedSsoLink: string = null;

	constructor(
		private loginService: LoginService,
		private loginSaveService: LoginSaveService,
	) {}

	ngOnInit(): void {
		switch (this.data.type) {
			case 'google': {
				this.ssoImage = `${getEnvironment().assets}/images/g-logo.png`;
				break;
			}
			case 'microsoft': {
				this.ssoImage = `${getEnvironment().assets}/images/microsoft-logo.png`;
				break;
			}
			case 'clever': {
				this.ssoImage = `${getEnvironment().assets}/images/clever-mark-white.png`;
				break;
			}
		}

		const checkUrl = (ssoLink: string) => {
			if (hasValue(ssoLink)) {
				let formattedLink: string = null;

				const path = this.data.destinationUrl?.pathname;

				if (hasValue(path) && path !== '/' && !containsString(path, '/p/')) {
					if (this.data.type === 'google' || this.data.type === 'clever') {
						const baseLink = ssoLink.substring(0, ssoLink.indexOf('&'));
						formattedLink = `${baseLink}&destination=${this.data.destinationUrl.pathname}`;
					} else if (this.data.type === 'microsoft') {
						const baseLink = ssoLink.substring(0, ssoLink.indexOf('?'));
						formattedLink = `${baseLink}?returnTo=${this.data.destinationUrl.pathname}`;
					}
				}

				this.parsedSsoLink = hasValue(formattedLink) ? formattedLink : ssoLink;
			}

			this.loading = false;
		};

		if (!hasValue(this.data?.ssoLink)) {
			this.loginService
				.getSsoOptions()
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe((x) => {
					checkUrl(x[this.data.type]);
				});
		} else {
			checkUrl(this.data.ssoLink);
		}
	}

	saveUniqueId = (): void => {
		if (this.data.program?.institute_unique_id != null) {
			this.loginSaveService.setUniqueId(this.data.program.institute_unique_id);
		}
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
