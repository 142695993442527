import { type Spacetime } from 'spacetime';
import { CalendarEventModel } from 'src/lib/services/api/organizations/calendar/calendar-event.model';
import { MeetupManagementListItemModel } from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { StudentCourseModel } from 'src/lib/services/api/students/classes/student-course.model';
import { StudentStateTestEventModel } from 'src/lib/services/api/students/tests/student-state-test.model';

export enum StudentCalendarItemType {
	course = 'Course Due Date',
	final = 'Final Exam Due Date',
	availableMeetup = 'Available Meetup',
	scheduledMeetup = 'Scheduled Meetup',
	attendedMeetup = 'Attended Meetup', // only used in staff mobile meetups
	programEvent = 'Program Event',
	stateTest = 'State Test Event',
}

export interface StudentCalendarCourseItem {
	type: StudentCalendarItemType.course | StudentCalendarItemType.final;
	bgColor: string;
	data: StudentCourseModel;
	icon?: string;
}

export interface StudentCalendarEventItem {
	type: StudentCalendarItemType.programEvent;
	bgColor: string;
	data: CalendarEventModel;
	icon?: string;
}

export interface StudentCalendarMeetupItem {
	type:
		| StudentCalendarItemType.availableMeetup
		| StudentCalendarItemType.scheduledMeetup
		| StudentCalendarItemType.attendedMeetup;
	bgColor: string;
	data: MeetupManagementListItemModel;
	icon?: string;
}

export interface StudentCalendarTestEventItem {
	type: StudentCalendarItemType.stateTest;
	bgColor: string;
	data: StudentStateTestEventModel;
	icon?: string;
	isRequired: boolean;
}

export type StudentCalendarDataItem =
	| StudentCalendarCourseItem
	| StudentCalendarEventItem
	| StudentCalendarMeetupItem
	| StudentCalendarTestEventItem;

export interface CalendarDateRangeModel {
	start: Spacetime;
	end: Spacetime;
}
