import { AfterViewInit, Directive } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
	selector: 'ng-select[aeNgSelectAutofocus]',
})
export class NgSelectAutofocusDirective implements AfterViewInit {
	constructor(private select: NgSelectComponent) {}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.select.open();
		});
	}
}
