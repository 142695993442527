import {
	AfterContentChecked,
	ContentChild,
	Directive,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PaginationTableColumnDisplayDirective } from './pagination-table-column-display.directive';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'ae-pgt-row-actions-column',
})
export class PaginationTableRowActionsColumnDirective<T>
	implements AfterContentChecked, OnChanges
{
	@Input()
	public disabled: boolean = false;

	@Input()
	public size: number = 3;

	@ContentChild(PaginationTableColumnDisplayDirective)
	public displayTemplate: PaginationTableColumnDisplayDirective<T>;

	private onChange = new Subject<SimpleChanges>();
	public onChange$ = this.onChange.asObservable();

	ngAfterContentChecked(): void {
		if (this.displayTemplate == null) {
			throw Error(
				'ae-pgt-row-actions-column is missing required child ae-pgt-column-display',
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.size) {
			if (!(changes.size.currentValue >= 1 && changes.size.currentValue <= 4)) {
				console.error(
					`PaginationTableRowActionsColumnDirective size must be between 1-4, is currently set to ${changes.size.currentValue}`,
				);
			}
		}

		this.onChange.next(changes);
	}
}
