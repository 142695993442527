import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[aePgtStateNoItems]',
})
export class PaginationTableStateNoItemsDirective {
	@Input() disabled = false;

	constructor(public template: TemplateRef<any>) {}
}
