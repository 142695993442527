import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { commonValidators } from 'src/lib/utilities/forms';
import { GroupValidationDisplayComponent } from '../../../../layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../layout/group-validation/validation-error.directive';
import { protectedPaginationTableConfigurationDefaultName } from '../../core/internal-pagination-table.interface';
import { IPaginationTable } from '../../core/pagination-table.interface';

export interface PaginationTableConfigurationEdit {
	name: string;
	create: boolean;
	copy: boolean;
}

@Component({
	selector: 'ae-pagination-table-configuration-edit-modal',
	templateUrl: './pagination-table-configuration-edit-modal.component.html',
	styleUrls: ['./pagination-table-configuration-edit-modal.component.scss'],
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		ReactiveFormsModule,
		ValidationErrorDirective,
	],
})
export class PaginationTableConfigurationEditModalComponent<T> {
	@Input() pgTable: IPaginationTable<T>;
	@Input() name: string;
	@Input() create: boolean;

	public editForm: FormGroup<
		FormControlWrapper<PaginationTableConfigurationEdit>
	>;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		pgTable: IPaginationTable<T>;
		name: string;
		create: boolean;
	}): void => {
		// SET DATA
		this.pgTable = data.pgTable;
		this.name = data.name;
		this.create = data.create;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.editForm = this.fb.group<
			FormControlWrapper<PaginationTableConfigurationEdit>
		>({
			name: new FormControl(data.name, [
				commonValidators.requiredNotEmpty,
				(control: FormControl<string>) => {
					if (control.value == null) {
						return null;
					} else if (control.value.trim() === '') {
						return { required: true };
					} else if (
						this.pgTable.userPreferences.savedConfigurations.find(
							(x) =>
								x.name.toLowerCase().trim() ===
								control.value.toLowerCase().trim(),
						)
					) {
						return { duplicate: true };
					} else if (
						control.value.trim() ===
						protectedPaginationTableConfigurationDefaultName
					) {
						return { protected: true };
					} else return null;
				},
			]),
			create: new FormControl(data.create),
			copy: new FormControl(true),
		});
	};

	public dismiss = () => {
		this.activeModal.dismiss();
	};

	public save = () => {
		const value = this.editForm.value;
		value.name = value.name.trim();
		this.activeModal.close(value);
	};
}
