import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import spacetime from 'spacetime';
import {
	StudentContactAttributes,
	StudentContactOptionsModel,
} from 'src/lib/services/api/students/contacts/student-contact-options.model';
import { StudentsContactsService } from 'src/lib/services/api/students/contacts/students-contacts.service';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { UserDataPersonalContactModel } from 'src/lib/services/api/users/user-data.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { convertToInt } from 'src/lib/utilities/convert';
import { ProfileFamilySupportEditComponent } from './profile-familysupport-edit/profile-familysupport-edit.component';
import { ProfileFamilySupportViewComponent } from './profile-familysupport-view/profile-familysupport-view.component';

@Component({
	selector: 'ae-profile-familysupport',
	templateUrl: './profile-familysupport.component.html',
	styleUrls: ['./profile-familysupport.component.scss'],
	imports: [
		ProfileFamilySupportEditComponent,
		ProfileFamilySupportViewComponent,
	],
})
export class ProfileFamilySupportComponent implements OnInit {
	public loading: boolean = true;
	public errorLoading: boolean = false;

	public existingPendingChange: boolean = false;
	public editEnabled: boolean = false;
	public canEditContact: boolean = false;
	public userId: number;

	public ageGate: boolean = true;

	public contactData: UserDataPersonalContactModel[];

	public contactOptions: UserUpdateRequestOptions;

	public dropdownOptions: StudentContactOptionsModel;

	constructor(
		private userService: UsersService,
		private route: ActivatedRoute,
		private userRequestService: UsersUpdateRequestsService,
		private studentContactService: StudentsContactsService,
	) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.userId = convertToInt(p.userId);
			this.getContactInfo();
		});
	}

	public enableEdit = () => {
		this.editEnabled = !this.editEnabled;
	};

	public hasAttribute = (
		contact: UserDataPersonalContactModel,
		attr: StudentContactAttributes,
	): boolean => {
		if (contact == null || contact.attributes == null) {
			return false;
		}
		return contact.attributes.indexOf(attr) !== -1;
	};

	public resetContactForm = (forceRefresh: boolean) => {
		this.editEnabled = false;
		if (forceRefresh) {
			this.getContactInfo();
		}
	};

	public getContactInfo = () => {
		this.loading = true;
		combineLatest([
			this.userService.getUserData(this.userId),
			this.studentContactService.getContactOptions(),
			this.userRequestService.getPendingRequests(this.userId),
			this.userRequestService.getRequestsOptions(this.userId),
		]).subscribe({
			next: ([userData, relationshipOptions, requests, requestOptions]) => {
				this.contactData = userData.contacts.personal;
				this.contactOptions = requestOptions.allowed_updates;

				this.ageGate = userData.student_data?.dob
					? !spacetime(userData.student_data.dob).isBefore(
							spacetime(new Date()).subtract(18, 'year'),
						)
					: true;

				this.dropdownOptions = relationshipOptions;

				this.existingPendingChange =
					requests.pending_update_requests?.length > 0;

				this.canEditContact = this.contactOptions.contacts?.personal != null;

				this.loading = false;
			},
			error: (errors) => {
				console.error(mergeStrings(errors));
				this.errorLoading = true;
			},
		});
	};
}
