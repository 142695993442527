import { AsyncPipe } from '@angular/common';
import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
	AsyncSubject,
	BehaviorSubject,
	debounceTime,
	distinctUntilChanged,
	of,
	Subject,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';
import { LoginProgramModel, LoginService } from 'src/public.services';
import { buildSearchFieldLikeObject, hasValue } from 'src/public.utilities';
import { LoginState } from '../../login-state.enum';

@Component({
	selector: 'gal-sso-program-select',
	imports: [AsyncPipe, FormsModule, NgSelectModule, ReactiveFormsModule],
	templateUrl: './sso-program-select.component.html',
	styleUrl: './sso-program-select.component.scss',
})
export class SsoProgramSelectComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Output() selectedProgram = new EventEmitter<LoginProgramModel>();
	@Output() setState = new EventEmitter<LoginState>();

	public programOptions: LoginProgramModel[] = [];

	public selectCtrl: FormControl<LoginProgramModel>;

	public programsInput$ = new Subject<string>();
	public programs$ = new BehaviorSubject<LoginProgramModel[]>([]);

	public loadingPrograms = false;

	constructor(
		private loginService: LoginService,
		private fb: FormBuilder,
	) {}

	ngOnInit(): void {
		this.selectCtrl = this.fb.control<LoginProgramModel>(null);
		this.selectCtrl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val != null) {
					this.selectedProgram.emit(val);
				}
			});
		this.initInput();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	private initInput = () => {
		this.programsInput$
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.loadingPrograms = true)),
				switchMap((search) => {
					if (hasValue(search)) {
						return this.loginService.getPrograms({
							institute_title: buildSearchFieldLikeObject(search),
						});
					} else {
						return of([]);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((r) => {
				this.programs$.next(r);
				this.loadingPrograms = false;
			});
	};
}
