import { NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { AsyncSubject, Subject, of, race } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PaginationTableColumnDirective } from '../../../children/pagination-table-column.directive';
import { PaginationTableExportConfig } from './pagination-table-export-config';

const exportTakeCount = 100;

@Component({
	selector: 'ae-pagination-table-exporter',
	templateUrl: './pagination-table-exporter.component.html',
	styleUrls: ['./pagination-table-exporter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgTemplateOutlet],
})
export class PaginationTableExporterComponent<T>
	implements OnInit, AfterViewInit, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public exportConfig: PaginationTableExportConfig<T>;
	@Output() public exportProgress = new EventEmitter<{
		complete: number;
		total: number;
	}>();
	@Output() public exportComplete = new EventEmitter<string>();

	@ViewChild('exportTableHeader', { static: true })
	exportTableHeader: ElementRef<HTMLElement>;
	@ViewChild('exportTableBody', { static: true })
	exportTableBody: ElementRef<HTMLElement>;

	private loadFromIndexSubject = new Subject<number>();
	private lastItemIndex = 0;

	currentItems: T[];
	visibleColumns: PaginationTableColumnDirective<T>[];

	private renderedHeaderRows: string[] = [];
	private renderedBodyRows: string[][] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		if (this.exportConfig == null) {
			throw new Error('Expected exportConfig to not be null');
		}

		this.visibleColumns = this.getColumns()
			.filter((x) => this.exportConfig.exportColumns.indexOf(x.columnId) !== -1)
			.filter((x) => x.isExportable);
	}

	ngAfterViewInit(): void {
		this.loadFromIndexSubject
			.pipe(
				delay(1),
				switchMap((start) => {
					this.currentItems = null;
					const page = Math.floor(start / exportTakeCount) + 1;

					if (start >= this.lastItemIndex) {
						return of(-1); // Break out
					} else {
						return this.seek$(page, exportTakeCount).pipe(
							map((result) => {
								// Set the result to the count
								if (this.lastItemIndex === Number.MAX_SAFE_INTEGER) {
									this.lastItemIndex = result.count;
								}

								// Filter out items that weren't requested
								const usableItems = (result.items || []).filter((_x, i) => {
									const globalPosition = i + (page - 1) * exportTakeCount;
									const inRange =
										globalPosition >= start &&
										globalPosition < this.lastItemIndex;

									return inRange;
								});

								if (usableItems.length <= 0) {
									return -1; // Break out
								} else {
									this.currentItems = usableItems;
									return page;
								}
							}),
						);
					}
				}),
				tap(() => this.cdr.detectChanges()),
				filter((x) => {
					if (x <= 0) {
						this.finishExport();
						return false;
					} else {
						return true;
					}
				}),
				delay(100),
				tap(() => this.scrapeContent()),
				takeUntil(race(this._unsubscribe$, this.exportComplete)),
			)
			.subscribe((page) => {
				this.loadFromIndexSubject.next(page * exportTakeCount); // Look for more work
			});

		let [initStart, initEnd] = this.exportConfig.exportRange;
		initStart--;

		if (initStart <= 0) {
			initStart = 0;
		}
		if (initEnd < 0) {
			initEnd = Number.MAX_SAFE_INTEGER;
		}

		this.lastItemIndex = initEnd;
		this.loadFromIndexSubject.next(initStart);
	}

	private seek$ = (page: number, itemsPerPage: number) => {
		return this.exportConfig.pgTable.tableConfig
			.getPage$(
				page,
				itemsPerPage,
				this.exportConfig.pgTable.sortKey,
				this.exportConfig.pgTable.sortDescending,
				this.exportConfig.pgTable.tableConfig.search?.form,
			)
			.pipe(takeUntil(this._unsubscribe$));
	};

	private finishExport = () => {
		this.exportProgress.complete();

		let csvContent = `${this.renderedHeaderRows
			.map((c) => c || '')
			.map((c) => `"${c.replace(/"/g, '""')}"`)
			.join(',')}\n`;
		this.renderedBodyRows.forEach((x) => {
			csvContent += `${x
				.map((c) => c || '')
				.map((c) => `"${c.replace(/"/g, '""')}"`)
				.join(',')}\n`;
		});

		this.exportComplete.next(csvContent);
		this.exportComplete.complete();
	};

	private scrapeContent = () => {
		// Fetch the headers
		if (this.renderedHeaderRows.length === 0) {
			if (this.exportTableHeader.nativeElement.children.length > 0) {
				const headerColumns =
					this.exportTableHeader.nativeElement.children[0].children;
				for (let hci = 0; hci < headerColumns.length; hci++) {
					this.renderedHeaderRows[hci] = (
						(headerColumns[hci] as any).innerText ||
						headerColumns[hci].textContent
					).trim();
				}
			}
		}

		// Fetch the currently displayed body items
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (
			let bri = 0;
			bri < this.exportTableBody.nativeElement.children.length;
			bri++
		) {
			const renderedColumns = [];
			const bodyRowColumns =
				this.exportTableBody.nativeElement.children[bri].children;
			for (let bci = 0; bci < bodyRowColumns.length; bci++) {
				renderedColumns[bci] = (
					(bodyRowColumns[bci] as any).innerText ||
					bodyRowColumns[bci].textContent
				).trim();
			}

			this.renderedBodyRows.push(renderedColumns);
		}

		this.exportProgress.next({
			complete: this.renderedBodyRows.length,
			total: this.lastItemIndex,
		});
	};

	getColumns = (): PaginationTableColumnDirective<T>[] => {
		return this.exportConfig.pgTable.columnTemplates.filter((x) => !x.disabled);
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
