import { Directive, OnInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
	selector: 'ng-select[aeNgSelectAutosize]',
})
export class NgSelectAutosizeDirective implements OnInit {
	constructor(private select: NgSelectComponent) {}

	ngOnInit(): void {
		this.select.openEvent.subscribe(() => {
			document.body.classList.add('ng-select-dropdown-panel-autosize');
		});

		this.select.closeEvent.subscribe(() => {
			document.body.classList.remove('ng-select-dropdown-panel-autosize');
		});
	}
}
