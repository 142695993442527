import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { AddressComparisonInfo } from 'src/lib/services/api/generic/address/api-address.model';
import { ToggleButtonOffDirective } from '../../../../templates/controls/toggle-button/toggle-button-off.directive';
import { ToggleButtonOnDirective } from '../../../../templates/controls/toggle-button/toggle-button-on.directive';
import { ToggleButtonComponent } from '../../../../templates/controls/toggle-button/toggle-button.component';
import { AddressDisplayComponent } from '../../../../templates/global/user-address/address-display.component';
import { SpinUntilDirective } from '../../../../templates/layout/spin-while/spin-until.directive';

interface ConfirmAddressSelectionFormGroup {
	selections: FormArray<FormControl<boolean>>;
}

@Component({
	selector: 'ae-confirm-address-modal',
	templateUrl: './confirm-address-modal.component.html',
	styleUrls: ['./confirm-address-modal.component.scss'],
	imports: [
		AddressDisplayComponent,
		FormsModule,
		NgClass,
		NgTemplateOutlet,
		ReactiveFormsModule,
		SpinUntilDirective,
		ToggleButtonComponent,
		ToggleButtonOffDirective,
		ToggleButtonOnDirective,
	],
})
export class ConfirmAddressModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	public title: string = 'Address Verification';

	protected addressObjects: AddressComparisonInfo[];
	protected invalidAddressObjects: AddressComparisonInfo[];
	protected invalidComponentsLength = 0;

	protected state = 0;

	protected formGroup: FormGroup<ConfirmAddressSelectionFormGroup>;

	protected addressArr: AddressComparisonInfo[] = [];

	protected loaded = false;

	constructor(
		private cdref: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
		private fb: FormBuilder,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: AddressComparisonInfo[]): void => {
		// SET DATA
		this.addressObjects = data.filter((v) => {
			return !v.invalidComponent;
		});

		this.invalidAddressObjects = data.filter((v) => {
			return v.invalidComponent;
		});

		if (this.addressObjects?.length === 0) {
			this.state = 1;
		}

		this.invalidComponentsLength = this.invalidAddressObjects?.length;

		//separate invalidComponents

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		if (this.addressObjects.length > 0) {
			this.formGroup = this.fb.group<ConfirmAddressSelectionFormGroup>({
				selections: this.fb.array<FormControl<boolean>>([]),
			});

			this.addressObjects.forEach(() => {
				this.formGroup.controls.selections.controls.push(
					new FormControl<boolean>(false),
				);
			});
		}

		this.loaded = true;
	};

	public nextSave = () => {
		if (this.state === 0 && this.addressObjects) {
			this.formGroup.controls.selections.controls.forEach((val, i) => {
				if (!val.value) {
					this.addressArr.push(this.addressObjects[i]);
				} else {
					this.addressArr.push(null);
				}
			});
			if (this.invalidComponentsLength > 0) {
				this.state = 1;
			} else {
				this.close(true);
			}
		} else if (this.state === 1) {
			if (this.addressObjects && this.invalidComponentsLength === 0) {
				this.close(true);
			} else {
				this.close(false);
			}
		}
	};

	public close = (confirmed: boolean) => {
		if (confirmed) {
			this.activeModal.close(this.addressArr);
		} else {
			this.activeModal.dismiss();
		}
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
