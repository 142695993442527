import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	AsyncSubject,
	BehaviorSubject,
	map,
	of,
	switchMap,
	takeUntil,
} from 'rxjs';
import { type Format } from 'spacetime';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { SpaceFormatPipe } from '../../../pipes/spacetime/space-format.pipe';
import { SpaceMapPipe } from '../../../pipes/spacetime/space-map.pipe';

@Component({
	selector: 'ae-user-time',
	templateUrl: './user-time.component.html',
	styleUrls: ['./user-time.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SpaceFormatPipe, SpaceMapPipe],
})
export class UserTimeComponent implements OnInit, OnChanges, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private _change$ = new BehaviorSubject<null>(null);

	@Input() public date: Date;
	@Input() public format: Format = 'numeric-us';
	@Input() public userId: number;
	@Input() public sourceTz: 'user' | 'program' = 'user';

	public tz: string;

	constructor(
		private userStore: UserStoreService,
		private cdr: ChangeDetectorRef,
	) {}
	ngOnInit(): void {
		this._change$
			.pipe(
				switchMap(() => {
					if (this.userId != null) {
						return this.userStore.timezone$(this.userId).pipe(
							map((x) => {
								if (this.sourceTz === 'program') {
									return x.institute_timezone;
								} else {
									return x.timezone;
								}
							}),
						);
					} else {
						return of(null as string);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((tz) => {
				this.tz = tz;
				this.cdr.markForCheck();
			});
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this._change$.next(null);
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
