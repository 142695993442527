import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { UserSettingsStoreService } from 'src/lib/services/stores/users/settings/user-settings-store.service';

@Directive({
	selector: '[aeBillboardCollapsable]',
})
export class BillboardCollapsableDirective implements OnInit {
	@Input() aeBillboardCollapsable: string;

	private _open = true;
	private _iconElement: HTMLElement;

	constructor(
		private elemRef: ElementRef<HTMLElement>,
		private userSettingService: UserSettingsStoreService,
	) {}

	private getSaveId = () =>
		`billboard.${this.aeBillboardCollapsable}.collapsed.state`;

	ngOnInit(): void {
		this.renderElements();

		this.userSettingService
			.getSetting$<boolean>(this.getSaveId())
			.pipe(take(1))
			.subscribe((r) => {
				if (r != null) {
					this._open = r;
					this.setState(this._open);
				}
			});
	}

	private setState = (open: boolean) => {
		if (open) {
			this.elemRef.nativeElement.classList.remove('collapsed');
			this._iconElement.classList.remove('fa-plus');
			this._iconElement.classList.add('fa-minus');
		} else {
			this.elemRef.nativeElement.classList.add('collapsed');
			this._iconElement.classList.remove('fa-minus');
			this._iconElement.classList.add('fa-plus');
		}

		this._open = open;
		this.userSettingService.saveSetting$(this.getSaveId(), open).subscribe();
	};

	private renderElements = () => {
		// cover
		const collapseCoverElement = document.createElement('div');
		collapseCoverElement.classList.add('billboard-collapse-cover');

		const coverBtnElement = document.createElement('button');
		coverBtnElement.classList.add('btn', 'btn-block');
		const coverIconElement = document.createElement('i');
		coverIconElement.classList.add('far', 'fa-chevron-down');

		coverBtnElement.appendChild(coverIconElement);
		collapseCoverElement.appendChild(coverBtnElement);
		coverBtnElement.onclick = () => {
			this.setState(true);
		};

		// collapse toggle
		const btnContainerElement = document.createElement('div');
		btnContainerElement.classList.add(
			'billboard-collapse-btn-container',
			'd-print-none',
		);

		const btnElement = document.createElement('button');
		btnElement.setAttribute(
			'id',
			`${this.aeBillboardCollapsable}.collapse-btn`,
		);
		btnElement.classList.add('btn', 'btn-icon', 'text-primary', 'p-0');
		btnContainerElement.appendChild(btnElement);

		const iconContainerElement = document.createElement('span');
		iconContainerElement.classList.add('fa-stack');

		this._iconElement = document.createElement('i');
		this._iconElement.classList.add(
			'far',
			'fa-minus',
			'fa-stack-1x',
			'text-light',
			'fa-fw',
		);

		const iconBgElement = document.createElement('i');
		iconBgElement.classList.add('fas', 'fa-square', 'fa-stack-2x');

		iconContainerElement.appendChild(iconBgElement);
		iconContainerElement.appendChild(this._iconElement);
		btnElement.appendChild(iconContainerElement);

		btnElement.onclick = () => {
			this.setState(!this._open);
		};

		this.elemRef.nativeElement.appendChild(collapseCoverElement);
		this.elemRef.nativeElement.appendChild(btnContainerElement);
	};
}
