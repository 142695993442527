import { NgIf } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { LoginService } from 'src/public.services';
import {
	GroupValidationDirective,
	GroupValidationDisplayComponent,
	InputTrimDirective,
	ValidationErrorDirective,
	WaitSpinnerComponent,
} from 'src/public.templates';
import {
	hasValue,
	MapResponseErrors,
	mergeStrings,
} from 'src/public.utilities';
import { LoginState } from '../../login-state.enum';

interface LoginForm {
	email: FormControl<string>;
	password: FormControl<string>;
}

@Component({
	selector: 'gal-credential-login',
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		InputTrimDirective,
		NgIf,
		ReactiveFormsModule,
		ValidationErrorDirective,
		WaitSpinnerComponent,
	],
	templateUrl: './credential-login.component.html',
	styleUrl: './credential-login.component.scss',
})
export class CredentialLoginComponent implements OnInit {
	@Output() public setState = new EventEmitter<LoginState>();
	@Output() public processing = new EventEmitter<boolean>();
	@Output() public email = new EventEmitter<string>();

	@ViewChild('emailInput')
	emailInput: ElementRef<HTMLElement>;

	public loginForm: FormGroup<LoginForm>;
	public loginState = LoginState;

	public errorMessage: string =
		'Incorrect email or password. Forgot your password?';

	public hasValue = hasValue;

	public loginError = false;
	public working = false;
	public loading = true;

	constructor(
		private fb: FormBuilder,
		private loginService: LoginService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.loginForm = this.fb.group<LoginForm>({
			email: new FormControl(null, [Validators.email]),
			password: new FormControl(null, Validators.required),
		});
		this.loading = false;
	}

	public login = () => {
		this.loginError = false;
		this.working = true;
		this.processing.emit(true);
		const value = this.loginForm.value;

		this.loginService.login(value.email, value.password).subscribe({
			next: () => {
				window.location.reload();
			},
			error: (err: MapResponseErrors) => {
				console.error('error', mergeStrings(err));
				if (err.length > 1) {
					const e = err.filter((val) => {
						return !val.includes('Error Code');
					});
					this.errorMessage = mergeStrings(e);
				}

				this.loginError = true;
				this.working = false;
				this.processing.emit(false);

				this.cdr.detectChanges();
				this.emailInput.nativeElement.focus();
			},
		});
	};

	public forgotPassword = () => {
		this.email.emit(this.loginForm.controls.email.value);
		this.setState.emit(LoginState.passwordReset);
	};
}
