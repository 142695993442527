import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { convertToBoolean } from 'src/lib/utilities/convert';
import { StudentCurrentCoursesResponseModel } from './current-courses/student-current-courses-response.model';
import { StudentPastCoursesResponseModel } from './past-courses/student-past-courses-response.model';
import { StudentCourseAddResponseModel } from './student-course-add-response.model';
import { StudentCoursesAddArgument } from './student-course-add.argument';
import { CourseBulkEditDateModel } from './student-course-bulk-edit-date.model';
import { StudentCourseEditResponseModel } from './student-course-edit-class-response.model';
import { StudentCourseEditArgument } from './student-course-edit-class.argument';
import { StudentCourseFeedbackModel } from './student-course-feedback.model';
import { StudentGradingScaleModel } from './student-course-grading-scale.model';
import { StudentCourseOptionsModel } from './student-course-options.model';
import { StudentCourseProgressReportModel } from './student-course-progress-report.model';
import { StudentCoursesResponseModel } from './student-course-response.model';
import { StudentCourseTeacherOptionModel } from './student-course-teacher-option.model';
import {
	StudentCourseActivitiesModel,
	StudentCourseModel,
	StudentRegistrationTypes,
} from './student-course.model';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseService {
	constructor(private httpClient: HttpClient) {}

	/************************************************/
	/* in postman names are Class instead of course */
	/* eg. getCourse is getClass                    */
	/************************************************/

	public getCourse = (
		studentId: number,
		registrationId: number,
	): Observable<StudentCourseModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/classes/${registrationId}`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseModel, r), {
					errorCode: 'B11C3D3E',
				}),
			);
	};

	public getCourseActivities = (
		studentId: number,
		registrationId: number,
	): Observable<StudentCourseActivitiesModel> => {
		return this.httpClient
			.get<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/activities`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseActivitiesModel, r), {
					errorCode: '25E2386E',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getAllCourses = (
		studentId: number,
		args: PagingArgument,
	): Observable<StudentCoursesResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/classes`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCoursesResponseModel, r), {
					errorCode: '09270C2E',
				}),
			);
	};

	public getPastCourses = (
		studentId: number,
		args: PagingArgument,
	): Observable<StudentPastCoursesResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/classes/past`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentPastCoursesResponseModel, r),
					{
						errorCode: '15D5F5D0',
					},
				),
			);
	};

	public getCurrentCourses = (
		studentId: number,
		args: PagingArgument,
	): Observable<StudentCurrentCoursesResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/classes/current`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentCurrentCoursesResponseModel, r),
					{ errorCode: '80463C49' },
				),
			);
	};

	public getCourseProgressReport = (
		studentId: number,
		registrationId: number,
	): Observable<StudentCourseProgressReportModel> => {
		return this.httpClient
			.get<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/report`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentCourseProgressReportModel, r),
					{
						errorCode: '78C9D0C1',
					},
				),
			);
	};

	/**********************/
	/**** Add Classes *****/
	/**********************/

	public getCourseOptions = (
		studentId: number,
		includeClassSelections: boolean,
		showCreditInformation: boolean = false,
		classes_registration_type: StudentRegistrationTypes = StudentRegistrationTypes.Regular,
	): Observable<StudentCourseOptionsModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/classes/options`, {
				show_classes: includeClassSelections,
				show_credit_information: showCreditInformation,
				classes_registration_type: classes_registration_type,
			})
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentCourseOptionsModel, r.results),
					{
						errorCode: '35F0CBF6',
					},
				),
			);
	};

	public getCourseTeacherOptions = (
		studentId: number,
		classId: number,
	): Observable<StudentCourseTeacherOptionModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/students/${studentId}/classes/create_options/${classId}`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentCourseTeacherOptionModel,
							r.results as any[],
						),
					{
						errorCode: '45D5BAC7',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public addStudentCourses = (
		studentId: number,
		args: StudentCoursesAddArgument,
	): Observable<StudentCourseAddResponseModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/classes/create`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(StudentCourseAddResponseModel, r.results as any[]),
					{
						errorCode: '11F630DB',
						errorMessage: 'There was an issue adding classes',
					},
				),
			);
	};

	/**********************/
	/**** Edit Classes ****/
	/**********************/

	public editStudentCourse = (
		studentId: number,
		registrationId: number,
		args: StudentCourseEditArgument,
		errMsg: string = 'There was an issue updating',
	): Observable<StudentCourseEditResponseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/edit`,
				{ registration: args },
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseEditResponseModel, r), {
					errorCode: 'DEECF40A',
					errorMessage: errMsg,
				}),
			);
	};

	/**********************/
	/*** Withdraw Class ***/
	/**********************/

	public withdrawStudentCourse = (
		studentId: number,
		registrationId: number,
	): Observable<StudentCourseEditResponseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/withdraw`,
				{},
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseEditResponseModel, r), {
					errorCode: '6C84E2E4',
					errorMessage: 'There was an issue withdrawing',
				}),
			);
	};

	public setCourseOrder = (
		studentId: number,
		registrationId: number,
		weight: number,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/weight`,
				{ weight: weight },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '11611993',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	/*******************************/
	/********** Feedback ***********/
	/*******************************/
	public getFeedback = (
		studentId: number,
	): Observable<StudentCourseFeedbackModel[]> => {
		return this.httpClient
			.get<any[]>(`/api/v1/students/${studentId}/classes/feedback`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseFeedbackModel, r), {
					errorCode: 'EA0B1A5B',
				}),
			);
	};

	public getCourseFeedback = (
		studentId: number,
		registrationId: number,
	): Observable<StudentCourseFeedbackModel> => {
		return this.httpClient
			.get<
				any[]
			>(`/api/v1/students/${studentId}/classes/${registrationId}/feedback`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseFeedbackModel, r)[0], {
					errorCode: 'B26123CE',
				}),
			);
	};

	public markFeedbackRead = (
		studentId: number,
		registrationId: number,
		submissionIds: number[],
	): Observable<boolean> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${studentId}/classes/${registrationId}/feedback`,
				submissionIds,
			)
			.pipe(
				mapResponse((r) => convertToBoolean(r), {
					errorCode: '4545192E',
				}),
			);
	};

	public gradingScale = (
		studentId: number,
	): Observable<StudentGradingScaleModel[]> => {
		return this.httpClient
			.get<any[]>(`/api/v1/student/${studentId}/gradebook/grading_scale`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentGradingScaleModel, r as any[]),
					{
						errorCode: '55332C0D',
					},
				),
			);
	};

	public getBulkEditStudentsCourses = (
		uids: number[],
	): Observable<CourseBulkEditDateModel[]> => {
		return this.httpClient
			.post<any>(`api/v1/students/courses`, { uids: uids })
			.pipe(
				mapResponse(
					(r) => plainToInstance(CourseBulkEditDateModel, r.succeeded as any[]),
					{
						errorCode: 'D37334A9',
					},
				),
			);
	};

	public retryAssignmentScore = (
		studentId: number,
		registrationId: number,
		assignmentId: string,
	) => {
		return this.httpClient
			.post<any>(
				`api/v1/students/${studentId}/classes/${registrationId}/assignments/${assignmentId}/retry`,
				{},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '65A75CDA',
				}),
			);
	};

	public excuseAssignment = (
		studentId: number,
		registrationId: number,
		assignmentId: string,
		isExcused: boolean,
		comment: string,
	) => {
		const args = { is_excused: isExcused };
		if (comment) {
			args['comment'] = comment;
		}
		return this.httpClient
			.post<any>(
				`api/v1/students/${studentId}/classes/${registrationId}/assignments/${assignmentId}/excuse`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'CBCBEF17',
				}),
			);
	};

	public zeroOutAssignments = (studentId: number, registrationId: number) => {
		return this.httpClient
			.post<any>(
				`api/v1/students/${studentId}/classes/${registrationId}/zero_out`,
				{},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D3C41116',
				}),
			);
	};
}
