import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, switchMap, tap } from 'rxjs';
import spacetime, { type Spacetime } from 'spacetime';
import {
	meetupFrequencyType,
	meetupTitles,
} from 'src/lib/constants/meetup-data';
import {
	MeetupGatheringTypes,
	MeetupManagementListItemModel,
} from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupManagementService } from 'src/lib/services/api/organizations/meetups/meetup-management.service';
import { StudentMeetupStoreService } from 'src/lib/services/stores/students/meetup/student-meetup-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasValue } from 'src/lib/utilities/compare';
import { convertDateToPhpTimestamp } from 'src/lib/utilities/convert';
import { commonValidators } from 'src/lib/utilities/forms';
import { normalizeHref } from 'src/lib/utilities/html';
import { IfPipe } from '../../../../../pipes/if.pipe';
import { SpaceFormatPipe } from '../../../../../pipes/spacetime/space-format.pipe';
import { WaitSpinnerComponent } from '../../../../../templates/global/wait-spinner/wait-spinner.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { MeetupRsvpRecurringModalService } from '../rsvp-recurring-modal/meetup-rsvp-recurring-modal.service';
import { MeetupUtilitiesService } from '../service/meetup-utilities.service';

interface RsvpFormGroup {
	arrival_time: string;
	how_long: number;
	study_plan: string;
}

@Component({
	selector: 'ae-meetup-rsvp-modal',
	templateUrl: './meetup-rsvp-modal.component.html',
	styleUrls: ['./meetup-rsvp-modal.component.scss'],
	imports: [
		DatePipe,
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		IfPipe,
		ReactiveFormsModule,
		SpaceFormatPipe,
		SpinWhileDirective,
		ValidationErrorDirective,
		WaitSpinnerComponent,
	],
})
export class MeetupRsvpModalComponent {
	@Input() meetup: MeetupManagementListItemModel;

	public meetupTitles = meetupTitles;

	public MeetupGatheringTypes = MeetupGatheringTypes;

	public rsvpFormGroup: FormGroup<FormControlWrapper<RsvpFormGroup>>;

	public normalizeHref = normalizeHref;
	public hasValue = hasValue;

	public loading = true;
	public saving = false;

	public minuteOptions: { value: number; label: string }[] = [
		{ label: '30 minutes', value: 30 },
		{ label: '60 minutes', value: 60 },
		{ label: '90 minutes', value: 90 },
		{ label: '120 minutes', value: 120 },
	];

	public availableMinuteOptions: { value: number; label: string }[] = [];

	public endSpace: Spacetime;
	public startSpace: Spacetime;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private userStoreService: UserStoreService,
		private meetupManagementService: MeetupManagementService,
		private studentMeetupStoreService: StudentMeetupStoreService,
		public meetupUtilitiesService: MeetupUtilitiesService,
		private meetupRsvpRecurringModalService: MeetupRsvpRecurringModalService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		meetup: MeetupManagementListItemModel;
	}): void => {
		// SET DATA
		this.meetup = data.meetup;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		if (this.meetupUtilitiesService.canRsvp(this.meetup)) {
			this.initForm();
		} else {
			this.toastr.warning('The requested meetup is no longer available');
			this.activeModal.dismiss();
		}
	};

	private initForm = () => {
		this.endSpace = spacetime(this.meetup.end_date);
		this.startSpace = spacetime(this.meetup.start_date);

		const arrivalDate = spacetime(
			!this.meetupUtilitiesService.isActive(this.meetup)
				? this.meetup.start_date
				: new Date(),
		);

		const diff = spacetime(
			Math.max(this.startSpace.epoch, new Date().getTime()),
		).diff(this.endSpace, 'minute');

		this.availableMinuteOptions = this.minuteOptions.filter(
			(x) => x.value <= diff,
		);

		const howLong = this.availableMinuteOptions.slice(0, 2).pop()?.value;

		this.rsvpFormGroup = this.fb.group<FormControlWrapper<RsvpFormGroup>>(
			{
				arrival_time: new FormControl(
					arrivalDate.format('{hour-24-pad}:{minute-pad}'),
					[Validators.required, this.timeValidator],
				),
				how_long: new FormControl(howLong, Validators.required),
				study_plan: new FormControl(null),
			},
			{ validators: [this.enoughTimeValidator] },
		);

		if (howLong == null) {
			this.rsvpFormGroup.controls.how_long.disable();
		}

		if (
			this.meetup.gathering_type === MeetupGatheringTypes.community_event ||
			this.meetup.gathering_type === MeetupGatheringTypes.meetup
		) {
			this.rsvpFormGroup.controls.study_plan.disable();
		} else if (
			this.meetup.gathering_type === MeetupGatheringTypes.study_session
		) {
			this.rsvpFormGroup.controls.study_plan.setValidators(
				commonValidators.requiredNotEmpty,
			);
		}

		this.loading = false;
		this.cdref.detectChanges();
	};

	enoughTimeValidator = (fg: FormGroup<FormControlWrapper<RsvpFormGroup>>) => {
		if (fg.controls.how_long.disabled) {
			return null;
		}

		const time = fg.value.arrival_time?.split(':');

		if (!time || fg.controls.arrival_time.hasError('invalidArrival')) {
			return null;
		}

		return this.startSpace
			.hour(parseInt(time[0]))
			.minute(parseInt(time[1]))
			.diff(this.endSpace, 'minute') < fg.value.how_long
			? { invalidTime: true }
			: null;
	};

	timeValidator = (ctrl: FormControl<string>) => {
		const time = ctrl.value?.split(':');

		if (!time) {
			return null;
		}

		const currentTime = spacetime(this.meetup.start_date)
			.hour(parseInt(time[0]))
			.minute(parseInt(time[1]));

		return currentTime.isBetween(this.startSpace, this.endSpace, true)
			? null
			: { invalidArrival: true };
	};

	public timeRemaining = () => {
		return spacetime(new Date()).diff(this.meetup.end_date, 'minute');
	};

	public save = () => {
		this.saving = true;
		const fg = this.rsvpFormGroup.controls;

		const time = fg.arrival_time.value.split(':');
		const arrival = new Date(this.meetup.start_date);
		arrival.setHours(parseInt(time[0]), parseInt(time[1]));

		const departure = fg.how_long.value
			? spacetime(arrival).add(fg.how_long.value, 'minute').toNativeDate()
			: this.meetup.end_date;

		// Check if frequency is not one time but weekly or bi-weekly
		let obs$: Observable<any> = of(true);
		if (this.meetup.frequency !== meetupFrequencyType.one_time) {
			obs$ = this.meetupRsvpRecurringModalService.openModal$(
				this.meetup.id,
				convertDateToPhpTimestamp(arrival),
				convertDateToPhpTimestamp(departure),
				false,
			);
		}

		obs$
			.pipe(
				switchMap((r) => {
					if (r) {
						return this.meetupManagementService.markAsAttending(
							this.meetup.id,
							{
								planned_arrival_timestamp: convertDateToPhpTimestamp(arrival),
								planned_departure_timestamp:
									convertDateToPhpTimestamp(departure),
								student_plan: hasValue(fg.study_plan.value)
									? fg.study_plan.value
									: null,
							},
						);
					} else {
						return of(null);
					}
				}),
			)
			.pipe(
				tap(() => {
					return this.studentMeetupStoreService.refreshMeetups(
						this.userStoreService.currentUserUid,
						spacetime(this.meetup.start_date).month(),
						spacetime(this.meetup.start_date).year(),
					);
				}),
			)
			.subscribe({
				next: () => {
					if (
						this.meetup.type === 'virtual' &&
						this.meetupUtilitiesService.isActive(this.meetup)
					) {
						window.open(
							normalizeHref(this.meetup.link),
							'_blank',
							'noreferrer',
						);
					}

					this.activeModal.close();
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors), 'Attending Meetup');
					this.loading = false;
				},
			});
	};
}
