import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[aePgtHeader]',
})
export class PaginationTableColumnHeaderDirective {
	@Input() public cellClass: string;
	@Input() public description: string;

	constructor(public template: TemplateRef<any>) {}
}
