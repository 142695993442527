import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[aePgtStateLoading]',
})
export class PaginationTableStateLoadingDirective {
	@Input() disabled = false;

	constructor(public template: TemplateRef<any>) {}
}
