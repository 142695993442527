import { DatePipe, NgClass } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import { combineLatest } from 'rxjs';
import spacetime from 'spacetime';
import { Permits } from 'src/lib/constants/permissions';
import { getEnvironment } from 'src/lib/environment/environment';
import { StudentOverviewModel } from 'src/lib/services/api/students/student-overview.model';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { UserDataModel } from 'src/lib/services/api/users/user-data.model';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { StudentStoreService } from 'src/lib/services/stores/students/student/student-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { convertToInt } from 'src/lib/utilities/convert';
import { printHTML } from 'src/lib/utilities/html';
import { AssetUrlPipe } from '../../../../pipes/asset-url.pipe';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';
import { ProfileInfoEditComponent } from './profile-info-edit/profile-info-edit.component';
import { ProfileInfoViewComponent } from './profile-info-view/profile-info-view.component';

@Component({
	selector: 'ae-profile-info',
	templateUrl: './profile-info.component.html',
	styleUrls: ['./profile-info.component.scss'],
	imports: [
		AssetUrlPipe,
		DatePipe,
		NgClass,
		ProfileInfoEditComponent,
		ProfileInfoViewComponent,
		SpinWhileDirective,
	],
})
export class ProfileInfoComponent implements OnInit {
	public loading: boolean = true;
	public errorLoading: boolean = false;
	public savingInfo: boolean = false;

	public editEnabled: boolean = false;
	public canEditProfile: boolean = false;

	public currentUserId: number;

	public userData: UserDataModel;
	public studentData: StudentOverviewModel;
	public accountOptions: UserUpdateRequestOptions;

	public existingPendingChange: boolean = false;

	@ViewChild('idCard', { static: false }) idCard: ElementRef<HTMLElement>;
	public idCardReady: boolean = false;
	public isStudentApp = getEnvironment().isApp('student');

	constructor(
		private route: ActivatedRoute,
		private userRequestService: UsersUpdateRequestsService,
		private userStore: UserStoreService,
		private permissionService: PermissionStoreService,
		private studentStoreService: StudentStoreService,
	) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.currentUserId = convertToInt(p.userId);
			this.getContactInfo();
		});
	}

	public enableEdit = () => {
		this.editEnabled = !this.editEnabled;
	};

	public resetProfileInfoForm = (forceRefresh: boolean = true) => {
		this.editEnabled = false;
		if (forceRefresh) {
			this.getContactInfo();
		}
	};

	public getContactInfo = () => {
		this.loading = true;

		combineLatest([
			this.userStore.userData$(this.currentUserId),
			this.userRequestService.getPendingRequests(this.currentUserId),
			this.userRequestService.getRequestsOptions(this.currentUserId),
			this.permissionService.getFieldSet$(),
			this.studentStoreService.overview$(this.currentUserId),
		]).subscribe({
			next: ([
				userData,
				requests,
				requestOptions,
				permissions,
				studentData,
			]) => {
				this.userData = userData;
				this.studentData = studentData;
				this.accountOptions = requestOptions.allowed_updates;
				this.existingPendingChange =
					requests.pending_update_requests?.length > 0;

				this.canEditProfile = false;

				if (this.userStore.currentUserUid !== this.currentUserId) {
					this.canEditProfile = permissions.canDo(
						Permits['ga_institute|edit staff admin personal information'],
					);
				} else {
					Object.keys(this.accountOptions?.profile).forEach((key) => {
						if (this.accountOptions?.profile[key] != null) {
							this.canEditProfile = true;
						}
					});
				}

				if (this.isStudentApp) {
					setTimeout(() => {
						this.createIDCard();
					}, 10);
				}

				this.loading = false;
			},
			error: (errors) => {
				console.error(mergeStrings(errors));
				this.errorLoading = true;
			},
		});
	};

	public getAcademicYear = () => {
		const cutoff = spacetime.now().month('August').startOf('month');
		let endYear: number;

		if (spacetime.now().isBetween(cutoff, spacetime.now().endOf('year'))) {
			endYear = spacetime.now().year() + 1;
		} else {
			endYear = spacetime.now().year();
		}

		return `${endYear - 1} - ${endYear}`;
	};

	public createIDCard = () => {
		if (this.userData?.user?.profile_picture_high_res) {
			html2canvas(document.querySelector('#profile-info-id-card'), {
				allowTaint: true,
				imageTimeout: 15000,
			}).then((canvas) => {
				this.idCard.nativeElement.innerHTML = '';

				const image = new Image();
				image.src = canvas.toDataURL();
				image.title = 'School ID Card';
				image.alt = image.title;

				this.idCardReady = true;
				this.idCard.nativeElement.appendChild(image);
			});
		}
	};

	public print = () => {
		printHTML(this.idCard.nativeElement.outerHTML);
	};
}
