import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	UserDataContactAddressModel,
	UserDataContactEmailModel,
	UserDataContactMessengerModel,
	UserDataContactModel,
	UserDataContactPhoneModel,
} from 'src/lib/services/api/users/user-data.model';

import { KeyValuePipe, NgClass } from '@angular/common';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, filter, switchMap, takeUntil } from 'rxjs';
import { ValidationConstants } from 'src/lib/constants/constants';
import { Permits } from 'src/lib/constants/permissions';
import { AddressValidationService } from 'src/lib/services/api/generic/address/address-validation.service';
import { StudentContactPhoneArgument } from 'src/lib/services/api/students/contacts/student-contact.argument';
import { UserUpdateRequestArgument } from 'src/lib/services/api/users/update-request/users-update-request.argument';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import {
	compareObjects,
	containsString,
	hasKey,
	hasSomeKey,
} from 'src/lib/utilities/compare';
import {
	commonValidators,
	forceUpdateStatus,
	markFormGroupTouched,
} from 'src/lib/utilities/forms';
import { InputCheckboxComponent } from '../../../../../templates/controls/input-checkbox/input-checkbox.component';
import { IconTooltipComponent } from '../../../../../templates/global/icon-tooltip/icon-tooltip.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';

export interface ProfileContactEditCalendarFormGroup {
	link: string;
}

interface SsoEmailFormGroup {
	originalItem: UserDataContactEmailModel;
	newValue: string;
	delete: boolean;
}

class ProfileContactFormModel {
	phone: FormArray<FormGroup<FormControlWrapper<StudentContactPhoneArgument>>>;
	address: FormArray<FormGroup<FormControlWrapper<UserAddressModel>>>;
	calendar: FormArray<
		FormGroup<FormControlWrapper<ProfileContactEditCalendarFormGroup>>
	>;
	ssoEmail: FormArray<FormGroup<FormControlWrapper<SsoEmailFormGroup>>>;
}

@Component({
	selector: 'ae-profile-contact-edit',
	templateUrl: './profile-contact-edit.component.html',
	styleUrls: ['./profile-contact-edit.component.scss'],
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		IconTooltipComponent,
		InputCheckboxComponent,
		KeyValuePipe,
		NgbTooltipModule,
		NgClass,
		ReactiveFormsModule,
		ValidationErrorDirective,
	],
})
export class ProfileContactEditComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public currentUserId: number;
	@Input() public contactData: UserDataContactModel;
	@Input() public phoneOptions: Map<string, string>;
	@Input() public contactOptions: UserUpdateRequestOptions;
	@Input() public contactSaveListener: (forceRefresh: boolean) => void;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public savingInfo: boolean = false;

	public profileContactFormGroup: FormGroup<ProfileContactFormModel>;

	public primaryPhoneIndex: number = 0;
	public primaryAddressIndex: number = 0;
	public primaryCalendarIndex: number = 0;
	public zipRegex: string = ValidationConstants.zipcode;
	public canEditSSOEmail: boolean = false;

	public hasKey = hasKey;
	public hasSomeKey = hasSomeKey;

	constructor(
		private fb: FormBuilder,
		private userRequestService: UsersUpdateRequestsService,
		private addressValidationService: AddressValidationService,
		private toastr: ToastrService,
		private permissionsService: PermissionStoreService,
	) {}

	ngOnInit() {
		// get indexes of primary values
		this.primaryPhoneIndex = this.contactData?.phone.findIndex((val) => {
			return val.prim;
		});

		this.primaryAddressIndex = this.contactData?.address.findIndex((val) => {
			return val.prim;
		});

		this.profileContactFormGroup = this.fb.group<ProfileContactFormModel>({
			phone: this.fb.array<
				FormGroup<FormControlWrapper<StudentContactPhoneArgument>>
			>([]),
			address: this.fb.array<FormGroup<FormControlWrapper<UserAddressModel>>>(
				[],
			),
			calendar: this.fb.array<
				FormGroup<FormControlWrapper<ProfileContactEditCalendarFormGroup>>
			>([]),
			ssoEmail: this.fb.array<FormGroup<FormControlWrapper<SsoEmailFormGroup>>>(
				[],
			),
		});

		const ctrls = this.profileContactFormGroup.controls;

		this.contactData?.phone?.forEach((phone) => {
			ctrls.phone.push(this.buildPhoneGroup(phone));
		});

		this.contactData?.address?.forEach((address) => {
			ctrls.address.push(this.buildAddressGroup(address));
		});

		// Currently showing only one Messenger item to edit, but system is built for multiple use (if we want to in the future)
		if (this.contactData?.messenger?.length > 0) {
			ctrls.calendar.push(
				this.buildCalendarGroup(this.contactData?.messenger[0]),
			);
		}

		if (!hasKey(this.contactOptions?.contact_field?.address, 'subfields')) {
			ctrls.address.disable();
		}

		if (!hasKey(this.contactOptions?.contact_field, 'phone')) {
			ctrls.phone.disable();
		}

		if (!hasKey(this.contactOptions?.contact_field, 'messenger')) {
			ctrls.calendar.disable();
		}

		this.permissionsService
			.getFieldSet$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((p) => {
				this.canEditSSOEmail = p.canDo(
					Permits['ga_institute|edit program email for user'],
				);

				this.contactData?.ssoEmail?.forEach((email) => {
					ctrls.ssoEmail.push(this.buildSsoEmailGroup(email));
				});
			});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public save = () => {
		//check changed addresses

		const addresses: FormGroup<FormControlWrapper<UserAddressModel>>[] = [];
		if (this.profileContactFormGroup.controls.address.controls.length > 0) {
			this.profileContactFormGroup.controls.address.controls.forEach(
				(address) => {
					if (
						this.contactData.address.find((a) => {
							return compareObjects(address.value, a.value);
						}) != null
					) {
						addresses.push(null);
					} else {
						addresses.push(address);
					}
				},
			);
		}

		if (!this.savingInfo) {
			this.savingInfo = true;

			this.addressValidationService
				.verifyAddresses$({
					addressFormGroups: addresses,
				})
				.pipe(
					filter((verified) => verified),
					switchMap(() => {
						const controlGroup = this.profileContactFormGroup.controls;
						const profileContactArgs: UserUpdateRequestArgument = {
							contact_field: {
								phone: [],
								address: [],
								messenger: [],
								email: [],
							},
						};

						controlGroup.phone.controls.forEach(
							(
								ctrl: FormGroup<
									FormControlWrapper<StudentContactPhoneArgument>
								>,
								index,
							) => {
								profileContactArgs.contact_field.phone.push({
									active: true,
									prim: this.primaryPhoneIndex === index ? true : false,
									sms: ctrl.controls.sms.value,
									type:
										ctrl.controls.type.value == null ||
										ctrl.controls.type.value === ''
											? null
											: ctrl.controls.type.value,
									value: ctrl.controls.phone.value.toString(),
									weight: index,
								});
							},
						);

						addresses.forEach(
							(
								ctrl: FormGroup<FormControlWrapper<UserAddressModel>>,
								index,
							) => {
								if (ctrl == null) {
									ctrl =
										this.profileContactFormGroup.controls.address.controls[
											index
										];
								}
								profileContactArgs.contact_field.address.push({
									active: true,
									prim: this.primaryAddressIndex === index ? true : false,
									type: 'home',
									weight: index,
									value: {
										city: ctrl.controls.city.value,
										state: ctrl.controls.state.value,
										street: ctrl.controls.street.value,
										street2: ctrl.controls.street2.value,
										zip: ctrl.controls.zip.value,
									},
								});
							},
						);

						controlGroup.calendar.controls.forEach((ctrl, index) => {
							profileContactArgs.contact_field.messenger.push({
								active: true,
								prim: true,
								type: 'list',
								weight: index,
								value: ctrl.controls.link.value,
							});
						});

						let emailChangeCount = 0;
						controlGroup.ssoEmail.controls.forEach((ctrl) => {
							const item = ctrl.controls.originalItem.value;

							if (ctrl.controls.delete.value === true) {
								item.active = false;
								emailChangeCount++;
							} else if (item.value !== ctrl.controls.newValue.value) {
								item.value = ctrl.controls.newValue.value;
								emailChangeCount++;
							}

							profileContactArgs.contact_field.email.push(item);
						});

						// Make arrays go bye bye if empty
						if (emailChangeCount > 0) {
							profileContactArgs.contact_field.email.push(
								this.contactData.email.find(
									(email) => email.prim && email.active,
								),
							);
						} else {
							delete profileContactArgs.contact_field.email;
						}

						if (
							profileContactArgs.contact_field.address.length < 1 &&
							(this.contactData.address?.length ?? 0) < 1
						) {
							delete profileContactArgs.contact_field.address;
						}

						if (
							profileContactArgs.contact_field.phone.length < 1 &&
							(this.contactData.phone?.length ?? 0) < 1
						) {
							delete profileContactArgs.contact_field.phone;
						}

						if (
							profileContactArgs.contact_field.messenger.length < 1 &&
							(this.contactData.messenger?.length ?? 0) < 1
						) {
							delete profileContactArgs.contact_field.messenger;
						}

						return this.userRequestService.createUpdateRequest(
							this.currentUserId,
							profileContactArgs,
						);
					}),
				)
				.subscribe({
					next: (response: any) => {
						if (response.success) {
							if (response.update_request) {
								this.toastr.success(
									'Contact info update has been requested. Your changes will not be shown until they are approved.',
								);
							} else {
								this.toastr.success('Contact info has been updated.');
							}
							this.contactSaveListener(true);
						} else {
							const msg = response.error_messages?.join('\n') ?? '';
							this.toastr.error(`Error saving changes: ${msg}`);
						}
					},
					error: (error) => {
						const msg = error?.error?.error_messages?.join('\n') ?? '';
						this.toastr.error(`Error saving changes: ${msg}`);
					},
					complete: () => {
						this.savingInfo = false;
					},
				});
		}
	};

	protected addAddress = () => {
		if (this.profileContactFormGroup.controls.address.length === 0) {
			this.primaryAddressIndex = 0;
		}
		this.profileContactFormGroup.controls.address.push(
			this.buildAddressGroup(),
		);
	};

	protected deleteAddress = (index: number) => {
		this.profileContactFormGroup.controls.address.removeAt(index);
		if (index === this.primaryAddressIndex) {
			this.primaryAddressIndex = 0;
		}
	};

	protected addPhone = () => {
		if (this.profileContactFormGroup.controls.phone.length === 0) {
			this.primaryPhoneIndex = 0;
		}
		this.profileContactFormGroup.controls.phone.push(this.buildPhoneGroup());
	};

	protected deletePhone = (index: number) => {
		this.profileContactFormGroup.controls.phone.removeAt(index);
		if (index === this.primaryPhoneIndex) {
			this.primaryPhoneIndex = 0;
		} else {
			this.primaryPhoneIndex -= 1;
		}
	};

	private buildPhoneGroup = (phoneArgs?: UserDataContactPhoneModel) => {
		const ctrl = this.fb.group<FormControlWrapper<StudentContactPhoneArgument>>(
			{
				phone: new FormControl(phoneArgs ? phoneArgs.value : null, [
					commonValidators.phone,
					commonValidators.notSevenDigitPhone,
					Validators.required,
				]),
				type: new FormControl(phoneArgs ? phoneArgs.type : null),
				sms: new FormControl(
					phoneArgs ? phoneArgs.sms : false,
					Validators.required,
				),
			},
		);

		if (phoneArgs) {
			markFormGroupTouched(ctrl);
			forceUpdateStatus(ctrl);
		}

		return ctrl;
	};

	public addCalendar = () => {
		this.profileContactFormGroup.controls.calendar.push(
			this.buildCalendarGroup(),
		);
	};

	public deleteCalendar = (index: number) => {
		this.profileContactFormGroup.controls.calendar.removeAt(index);
		if (index === this.primaryPhoneIndex) {
			this.primaryPhoneIndex = 0;
		}
	};

	private buildCalendarGroup = (
		calendarArgs?: UserDataContactMessengerModel,
	) => {
		const ctrl = this.fb.group<
			FormControlWrapper<ProfileContactEditCalendarFormGroup>
		>({
			link: new FormControl(calendarArgs ? calendarArgs.value : null, [
				commonValidators.requiredNotEmpty,
				commonValidators.url(),
			]),
		});

		if (calendarArgs) {
			markFormGroupTouched(ctrl);
			forceUpdateStatus(ctrl);
		}

		return ctrl;
	};

	private buildAddressGroup = (addressArgs?: UserDataContactAddressModel) => {
		const ctrl = this.fb.group<FormControlWrapper<UserAddressModel>>({
			city: new FormControl(
				addressArgs ? addressArgs.value.city : null,
				Validators.required,
			),
			state: new FormControl(
				addressArgs ? addressArgs.value.state : null,
				Validators.required,
			),
			street: new FormControl(
				addressArgs ? addressArgs.value.street : null,
				Validators.required,
			),
			street2: new FormControl(addressArgs ? addressArgs.value.street2 : null),
			zip: new FormControl(
				addressArgs ? addressArgs.value.zip : null,
				Validators.required,
			),
		});

		if (addressArgs) {
			markFormGroupTouched(ctrl);
			forceUpdateStatus(ctrl);
		}

		return ctrl;
	};

	private buildSsoEmailGroup = (item: UserDataContactEmailModel) => {
		const isGradAlly = containsString(item.value, '@gradally.com');

		const ctrl = this.fb.group<FormControlWrapper<SsoEmailFormGroup>>({
			originalItem: new FormControl(item),
			newValue: new FormControl(
				{ value: item.value, disabled: !this.canEditSSOEmail },
				[commonValidators.emailAndLength],
			),
			delete: new FormControl({ value: false, disabled: isGradAlly }),
		});

		if (isGradAlly) {
			ctrl.controls.newValue.addValidators(this.gradAllyValidator());
		}

		markFormGroupTouched(ctrl);
		forceUpdateStatus(ctrl);

		return ctrl;
	};

	private gradAllyValidator = (): ValidatorFn => {
		return (ctrl: AbstractControl): ValidationErrors | null => {
			if (!containsString(ctrl.value, '@gradally.com')) {
				return {
					gradAllyRequired:
						'@gradally.com is required when updating existing @gradally.com emails',
				};
			} else {
				return null;
			}
		};
	};

	public getSsoEmailDeleteTitle = (
		fg: FormGroup<FormControlWrapper<SsoEmailFormGroup>>,
	) => {
		if (fg == null) {
			return null;
		}

		if (fg.controls.delete.disabled) {
			return 'Cannot delete @gradally.com email';
		}

		if (fg.controls.delete.value) {
			return 'Undo Delete';
		} else {
			return 'Delete';
		}
	};

	public setPrimaryPhone = (currentIndex: number) => {
		this.primaryPhoneIndex = currentIndex;
	};

	public setPrimaryAddress = (currentIndex: number) => {
		this.primaryAddressIndex = currentIndex;
	};

	public setPrimaryCalendar = (currentIndex: number) => {
		this.primaryCalendarIndex = currentIndex;
	};

	public showAddressError = (
		addressGroup: FormGroup<FormControlWrapper<UserAddressModel>>,
	) => {
		for (const c in addressGroup.controls) {
			if (addressGroup.controls.hasOwnProperty(c)) {
				if (
					addressGroup.controls[c].errors != null &&
					Object.keys(addressGroup.controls[c].errors).length > 0 &&
					!addressGroup.controls[c].pristine
				) {
					return true;
				}
			}
		}

		return false;
	};
}
