import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { AsyncSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SanitizeIdPipe } from '../../../pipes/sanitize-id.pipe';

@Component({
	selector: 'ae-input-checkbox',
	templateUrl: './input-checkbox.component.html',
	styleUrls: ['./input-checkbox.component.scss'],
	exportAs: 'inputCheckbox',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputCheckboxComponent),
			multi: true,
		},
	],
	imports: [FormsModule, NgClass, ReactiveFormsModule, SanitizeIdPipe],
})
export class InputCheckboxComponent
	implements ControlValueAccessor, OnDestroy, OnInit
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public id: string = null;
	@Input() public textClass: string = null;
	@Input() public iconClass: string = null;
	@Input() public defaultClass: string = null;
	@Input() public type: 'checkbox' | 'toggle' = 'checkbox';

	public backupId = `ae-input-checkbox-${Math.random() * 1000000}`;

	protected typeDefaults = {
		checkbox: {
			defaultClass: 'fa-regular fa-square',
			iconClass: 'fa-solid fa-check-square',
		},
		toggle: {
			defaultClass: 'fa-regular fa-toggle-off fa-lg',
			iconClass: 'fa-solid fa-toggle-large-on fa-lg',
		},
	};

	protected checkedControl: FormControl<boolean>;
	public value: boolean = false;

	private _touchFunction: () => void;
	private _changeFunction: (value: boolean) => void = () => null;
	private _changeWatcher: Subscription;

	constructor(
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	ngOnInit(): void {
		this.checkedControl = this.fb.control(false);

		// Creating a view safe value that can be checked similar to the native input checkbox
		this.checkedControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				setTimeout(() => (this.value = v));
			});

		this.cdr.detectChanges();
		this.watchForChange();
		this.cdr.detectChanges();
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.checkedControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (this._changeFunction) {
					this._changeFunction(val);
				}

				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	public writeValue(val: boolean = false): void {
		try {
			this.stopWatchForChange();
			this.checkedControl.setValue(val);
		} catch (e) {
			throw new Error(
				`InputCheckboxComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) {
			this.checkedControl.disable();
		} else {
			this.checkedControl.enable();
		}

		this.watchForChange();
	}
}
