import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	TemplateRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { ModalMoverComponent } from '../../../../templates/global/modal-mover/modal-mover.component';

@Component({
	selector: 'ae-alert-action-modal',
	templateUrl: './alert-action-modal.component.html',
	styleUrls: ['./alert-action-modal.component.scss'],
	imports: [CommonModule, ModalMoverComponent],
})
export class AlertActionModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public title: string = null;
	@Input() public message: string = null;
	@Input() public template: TemplateRef<any>;
	@Input() public mover: boolean = false;

	constructor(
		private cdref: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		title?: string;
		message?: string;
		template?: TemplateRef<any>;
		mover?: boolean;
	}): void => {
		// SET DATA
		this.title = data.title;
		this.message = data.message;
		this.template = data.template;
		this.mover = data.mover;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};

	public close = () => {
		this.activeModal.dismiss();
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
