import { Directive, Input, TemplateRef } from '@angular/core';
import { IPaginationTable } from '../pagination-table-config';

export declare class PaginationTableColumnDisplayDirectiveContext<T = unknown> {
	$implicit: T;
	rowId: number;
	alternateViewActive?: boolean;
}

@Directive({
	selector: 'ng-template[aePgtDisplay]',
})
export class PaginationTableColumnDisplayDirective<T = unknown> {
	@Input() public aePgtDisplay: IPaginationTable<T, any>;
	@Input() public cellClass: string;

	constructor(
		public template: TemplateRef<
			PaginationTableColumnDisplayDirectiveContext<T>
		>,
	) {}

	static ngTemplateGuard_aePgtDisplay: 'binding';
	static ngTemplateContextGuard<T>(
		_dir: PaginationTableColumnDisplayDirective<T>,
		_ctx: any,
	): _ctx is PaginationTableColumnDisplayDirectiveContext<T> {
		return true;
	}
}
