import {
	ComponentRef,
	Directive,
	Input,
	TemplateRef,
	ViewContainerRef,
	ViewRef,
} from '@angular/core';
import {
	hasValue,
	isArray,
	isNonEmptyString,
	isString,
} from 'src/lib/utilities/compare';
import { IfHasValueDefaultElseComponent } from './if-has-value-default-else/if-has-value-default-else.component';

export class IfHasValueDirectiveContext {
	public obj: unknown;
	public $implicit: any = null;
}

@Directive({
	selector: '[aeIfHasValue]',
})
export class IfHasValueDirective {
	private _context: IfHasValueDirectiveContext =
		new IfHasValueDirectiveContext();

	private _defaultElseComponent: ComponentRef<IfHasValueDefaultElseComponent>;
	private _elseTemplate: TemplateRef<unknown> = null;

	private _viewRef: ViewRef;
	private _elseViewRef: ViewRef;

	@Input() public checkEmpty = true;

	@Input() public set aeIfHasValue(obj: unknown) {
		this._context.$implicit = this._context.obj = obj;
		this.refreshView();
	}

	@Input() public set aeIfHasValueElse(template: TemplateRef<unknown>) {
		this._elseTemplate = template;
		this.refreshView();
	}

	constructor(
		private _viewContainer: ViewContainerRef,
		private _templateRef: TemplateRef<any>,
	) {}

	private _hasValue = () => {
		if (hasValue(this._context.obj)) {
			if (this.checkEmpty) {
				if (isString(this._context.obj)) {
					return isNonEmptyString(this._context.obj);
				} else if (isArray(this._context.obj)) {
					return this._context.obj.length > 0;
				}
			}

			return true;
		}

		return false;
	};

	private refreshView = () => {
		if (this._hasValue()) {
			if (this._viewRef == null) {
				this._viewContainer.clear();
				this._defaultElseComponent = null;
				this._elseViewRef = null;

				this._viewRef = this._viewContainer.createEmbeddedView(
					this._templateRef,
					this._context,
				);
			}
		} else {
			if (this._defaultElseComponent == null && this._elseTemplate == null) {
				this._viewContainer.clear();
				this._viewRef = null;
				this._elseViewRef = null;

				this._defaultElseComponent = this._viewContainer.createComponent(
					IfHasValueDefaultElseComponent,
				);
			} else if (this._elseViewRef == null && this._elseTemplate != null) {
				this._viewContainer.clear();
				this._viewRef = null;
				this._defaultElseComponent = null;

				this._elseViewRef = this._viewContainer.createEmbeddedView(
					this._elseTemplate,
					this._context,
				);
			}
		}
	};
}
