import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StudentEventUpsertArgument } from 'src/lib/services/api/students/events/student-event-upsert.argument';
import { StudentEventModel } from 'src/lib/services/api/students/events/student-event.model';
import { StudentsEventsService } from 'src/lib/services/api/students/events/students-events.service';
import { mergeStrings } from 'src/lib/utilities/array';

@Component({
	selector: 'ae-student-event-task-completion-modal',
	templateUrl: './student-event-task-completion-modal.component.html',
	styleUrls: ['./student-event-task-completion-modal.component.scss'],
	imports: [FormsModule],
})
export class StudentEventTaskCompletionModalComponent {
	private studentId: number;
	public selectedEvent: StudentEventModel;
	public completionComment: string = null;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private eventsService: StudentsEventsService,
		private toastr: ToastrService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		studentId: number;
		item: StudentEventModel;
	}): void => {
		// SET DATA
		this.studentId = data.studentId;
		this.selectedEvent = data.item;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};

	public save = () => {
		const saveArgs: StudentEventUpsertArgument = {
			completed_task: true,
			completion_comment: this.completionComment,
		};

		this.eventsService
			.updateStudentEvent(this.studentId, this.selectedEvent.id, saveArgs, [])
			.subscribe({
				next: () => {
					this.activeModal.close();
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors), 'Complete Task');
				},
			});
	};

	public close = (success: boolean) => {
		if (success) this.activeModal.close();
		else this.activeModal.dismiss();
	};
}
