import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	ElementRef,
	Input,
	TemplateRef,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'ae-flip-card',
	templateUrl: './flip-card.component.html',
	styleUrls: ['./flip-card.component.scss'],
	imports: [NgClass, NgTemplateOutlet],
})
export class FlipCardComponent {
	@Input() frontTemplate: TemplateRef<any>;
	@Input() backTemplate: TemplateRef<any>;
	@Input() deactivateFlip: boolean = false;

	@ViewChild('flipCard', { static: true })
	flipCard: ElementRef<HTMLElement>;

	protected flipped = false;

	public flip = (face: 'front' | 'back') => {
		if (!this.deactivateFlip) {
			if (face === 'front') {
				this.flipCard.nativeElement.classList.remove('transform-focus');
				this.flipped = false;
			} else {
				this.flipCard.nativeElement.classList.add('transform-focus');
				this.flipped = true;
			}
		}
	};
}
