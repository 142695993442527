import {
	Component,
	ElementRef,
	forwardRef,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as noUiSlider from 'nouislider';

export class DefaultFormatter implements noUiSlider.Formatter {
	to(value: number): string {
		// formatting with http://stackoverflow.com/a/26463364/478584
		return String(parseFloat(parseFloat(String(value)).toFixed(2)));
	}

	from(value: string): number {
		return parseFloat(value);
	}
}

// Sourced from https://github.com/tb/ng2-nouislider/blob/master/src/ng2-nouislider.component.ts
@Component({
	selector: 'ae-multi-slider',
	templateUrl: './multi-slider.component.html',
	styleUrls: ['./multi-slider.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiSliderComponent),
			multi: true,
		},
	],
})
export class MultiSliderComponent
	implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
	@ViewChild('sliderInjectionPoint', { static: true })
	sliderInjectionPoint: ElementRef<HTMLElement>;

	public slider: noUiSlider.API;

	@Input() options: noUiSlider.Options;

	private _changeFunction: (value: number[]) => void = () => null;

	constructor(
		private ngZone: NgZone,
		private renderer: Renderer2,
	) {}

	private getDefaultConfig = (): noUiSlider.Options => {
		return {
			animate: true,
			connect: true,
		} as noUiSlider.Options;
	};

	ngOnInit(): void {
		this.options.format = this.options.format || new DefaultFormatter();

		this.ngZone.runOutsideAngular(() => {
			this.slider = noUiSlider.create(
				this.sliderInjectionPoint.nativeElement,
				Object.assign(this.getDefaultConfig(), this.options),
			);
		});

		this.slider.on(
			'update',
			(_values: string[], _handle: number, unencoded: number[]) => {
				this.ngZone.run(() => {
					this._changeFunction(unencoded);
				});
			},
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.slider && (changes.min || changes.max || changes.step)) {
			this.ngZone.runOutsideAngular(() => {
				setTimeout(() => {
					this.slider.updateOptions(
						Object.assign(this.getDefaultConfig(), this.options),
						true,
					);
				});
			});
		}
	}

	ngOnDestroy(): void {
		this.slider.destroy();
	}

	writeValue(value: number[]): void {
		if (this.slider) {
			this.ngZone.runOutsideAngular(() => {
				setTimeout(() => {
					this.slider.set(value);
				});
			});
		}
	}

	registerOnChange(fn: (value: number[]) => void) {
		this._changeFunction = fn;
	}

	registerOnTouched(): void {
		// Multi slider doesn't have touched
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.renderer.setAttribute(
				this.sliderInjectionPoint.nativeElement,
				'disabled',
				'true',
			);
		} else {
			this.renderer.removeAttribute(
				this.sliderInjectionPoint.nativeElement,
				'disabled',
			);
		}
	}
}
