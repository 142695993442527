import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
class NgOptionHighlightDirective {
  constructor(elementRef, renderer) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.element = this.elementRef.nativeElement;
  }
  get _canHighlight() {
    return this._isDefined(this.term) && this._isDefined(this.label);
  }
  ngOnChanges() {
    if (this._canHighlight) {
      this._highlightLabel();
    }
  }
  ngAfterViewInit() {
    this.label = this.element.innerHTML;
    if (this._canHighlight) {
      this._highlightLabel();
    }
  }
  _escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  _highlightLabel() {
    const label = this.label;
    if (!this.term) {
      this._setInnerHtml(label);
      return;
    }
    const alternationString = this._escapeRegExp(this.term).replace(' ', '|');
    const termRegex = new RegExp(alternationString, 'gi');
    this._setInnerHtml(label.replace(termRegex, `<span class=\"highlighted\">$&</span>`));
  }
  _setInnerHtml(html) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);
  }
  _isDefined(value) {
    return value !== undefined && value !== null;
  }
  static {
    this.ɵfac = function NgOptionHighlightDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgOptionHighlightDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgOptionHighlightDirective,
      selectors: [["", "ngOptionHighlight", ""]],
      inputs: {
        term: [0, "ngOptionHighlight", "term"]
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgOptionHighlightDirective, [{
    type: Directive,
    args: [{
      selector: '[ngOptionHighlight]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    term: [{
      type: Input,
      args: ['ngOptionHighlight']
    }]
  });
})();
class NgOptionHighlightModule {
  static {
    this.ɵfac = function NgOptionHighlightModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgOptionHighlightModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgOptionHighlightModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgOptionHighlightModule, [{
    type: NgModule,
    args: [{
      imports: [NgOptionHighlightDirective],
      exports: [NgOptionHighlightDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-option-highlight
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgOptionHighlightDirective, NgOptionHighlightModule };
