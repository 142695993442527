import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';

@Component({
	selector: 'ae-gabby-chat-attachment-hider',
	templateUrl: './gabby-chat-attachment-hider.component.html',
	styleUrls: ['./gabby-chat-attachment-hider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class GabbyChatAttachmentHiderComponent implements OnInit {
	private messageAttachmentHideStoreKey = 'msgattchhd';

	@Input() messageId: number;
	@Input() attachmentIndex: number;

	public isHidden: boolean;

	constructor(private localStore: LocalStoreService) {}

	ngOnInit(): void {
		this.updateIsHidden();
	}

	private updateIsHidden = () => {
		this.isHidden = this.localStore.get(
			`${this.messageAttachmentHideStoreKey}.${this.messageId}.${this.attachmentIndex}`,
			TypedStoreType.BOOLEAN,
		);
	};

	public hideAttachment = (hide: boolean): void => {
		if (hide) {
			this.localStore.set(
				`${this.messageAttachmentHideStoreKey}.${this.messageId}.${this.attachmentIndex}`,
				true,
			);
		} else {
			this.localStore.remove(
				`${this.messageAttachmentHideStoreKey}.${this.messageId}.${this.attachmentIndex}`,
			);
		}

		this.updateIsHidden();
	};
}
