import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'ae-modal-mover',
	templateUrl: './modal-mover.component.html',
	styleUrls: ['./modal-mover.component.scss'],
})
export class ModalMoverComponent {
	public startDragReposition = (event: MouseEvent, element: HTMLElement) => {
		let safeGuard = 0;
		while (element != null && !element.classList.contains('modal-dialog')) {
			element = element.parentElement;
			safeGuard++;
			if (safeGuard > 100) {
				return;
			}
		}

		if (element == null) {
			return;
		}

		const startX = element.offsetLeft;
		const startY = element.offsetTop;

		element.style.position = 'fixed';
		element.style.width = '100%';
		element.style.left = `${startX}px`;
		element.style.top = `${startY}px`;
		element.style.margin = '0';

		const modalSizeX = element.clientWidth;
		const modalSizeY = element.clientHeight;

		const windowX = window.innerWidth;
		const windowY = window.innerHeight;

		const mouseStartX = event.pageX;
		const mouseStartY = event.pageY;
		event.preventDefault();

		const moveSubject = new Subject<MouseEvent>();
		moveSubject.subscribe((e: MouseEvent) => {
			let x = startX + (e.pageX - mouseStartX);
			let y = startY + (e.pageY - mouseStartY);

			x = Math.min(x, windowX - modalSizeX);
			x = Math.max(x, 0);

			y = Math.min(y, windowY - modalSizeY);
			y = Math.max(y, 0);

			element.style.left = `${x}px`;
			element.style.top = `${y}px`;
		});

		const moveFunc = (e: MouseEvent) => {
			moveSubject.next(e);
		};

		const upFunc = (e: MouseEvent) => {
			e.preventDefault();
			e.stopImmediatePropagation();

			document.removeEventListener('mousemove', moveFunc);
			document.removeEventListener('mouseup', upFunc);
			moveSubject.complete();
		};

		document.addEventListener('mousemove', moveFunc);
		document.addEventListener('mouseup', upFunc);
	};
}
