import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { AsyncSubject, BehaviorSubject, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { OrganizationsStoreService } from 'src/lib/services/stores/organizations-store/organizations-store.service';
import { isNonEmptyString } from 'src/lib/utilities/compare';
import { noop } from 'src/lib/utilities/noop';
import { singleSearch } from 'src/lib/utilities/search';
import { ProgramAdvancedSearchModalService } from 'src/lib/views/modals/program-advanced-search-modal/program-advanced-search-modal.service';

interface Organization {
	id: number;
	is_active: boolean;
	title: string;
	parent_title?: string;
	subtitle?: string;
	unique_id?: string;
}

@Component({
	selector: 'ae-organization-single-select',
	templateUrl: './organization-single-select.component.html',
	styleUrls: ['./organization-single-select.component.scss'],
	exportAs: 'aeOrganizationSingleSelect',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrganizationSingleSelectComponent),
			multi: true,
		},
	],
	imports: [FormsModule, NgClass, NgSelectModule, ReactiveFormsModule],
})
export class OrganizationSingleSelectComponent
	implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() organizations: Organization[];
	@Input() clearable: boolean = false;
	@Input() id: string;
	@Input() class: string;
	@Input() showFullLabel: boolean = false;
	@Input() placeholder: string = 'No Program Selected';
	@Input() reloadingOrgs: boolean = false;

	public searchMap = new Map<number, string[]>();
	public subtitleMap = new Map<number, string>();

	public _organizations: Organization[] = [];
	private _organizations$ = new BehaviorSubject<Organization[]>(undefined);
	private _organizationsSubscription: Subscription;

	public isInitiating = true;

	public orgControl: FormControl<number>;

	private _changeFunction: (value: number) => void = noop;
	private _touchedFunction: () => void = noop;
	private _changeWatcher: Subscription;

	@ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

	constructor(
		private organizationsStoreService: OrganizationsStoreService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private programAdvancedSearchModalService: ProgramAdvancedSearchModalService,
	) {}

	ngOnInit() {
		this.orgControl = this.fb.control(null);
		this.watchForChange();

		if (this.organizations == null) {
			this._organizationsSubscription = this.organizationsStoreService
				.organizations$()
				.subscribe({
					next: (x) => {
						this.organizations = x;
						this._organizations = x;
						this._organizations$.next(this._organizations);
					},
				});
		}

		this.ngSelect.openEvent.pipe(take(1)).subscribe(() => {
			this._touchedFunction();
		});

		// This resets the scroll back to top
		this.ngSelect.searchEvent
			.pipe(
				switchMap((x) => {
					if (isNonEmptyString(x?.term)) {
						this._organizations = [];
						this.cdr.detectChanges();

						return this.ngSelect.scroll.pipe(take(1));
					} else {
						return of(null);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this._organizations = this.organizations ?? [];
				this.cdr.detectChanges();
			});
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.orgControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((x) => this._changeFunction(x));
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.organizations) {
			this._organizationsSubscription?.unsubscribe();
			this._organizations = changes.organizations.currentValue ?? [];
		}
	}

	public orgsSearchFn = (term: string, item: Organization): boolean => {
		const trimmedTerm = term?.trim();

		if (item.id.toFixed() === trimmedTerm) {
			return true;
		}

		if (item.unique_id === trimmedTerm) {
			return true;
		}

		// Check subtitle first
		if (!this.subtitleMap.has(item.id)) {
			this.subtitleMap.set(
				item.id,
				item.subtitle.toLowerCase().replace(/[\s-]/g, ''),
			);
		}

		if (
			trimmedTerm.length > 1 &&
			this.subtitleMap
				.get(item.id)
				.indexOf(trimmedTerm.replace(/[\s-]/g, '').toLowerCase()) > -1
		) {
			return true;
		}

		// Cache the prepared searchable values
		if (!this.searchMap.has(item.id)) {
			const searchTerms = [];
			this.permuteArray([item.title, item.parent_title], searchTerms);

			const resultSets: string[][] = [[]];
			this.permuteArray([item.title, item.parent_title], resultSets);

			const prepared = resultSets.map((set) => set.join(', '));

			this.searchMap.set(item.id, prepared);
		}

		// Check if it's a match
		return singleSearch({
			search: term,
			target: this.searchMap.get(item.id),
			fuzzyThreshold: 0.7,
		}).match;
	};

	private permuteArray = <TPerm>(
		input: TPerm[],
		resultArray: TPerm[][],
		usedChars: TPerm[] = [],
	): void => {
		for (let i = 0; i < input.length; i++) {
			const ch = input.splice(i, 1)[0];
			usedChars.push(ch);
			if (input.length === 0) {
				resultArray.push(usedChars.slice());
			}
			this.permuteArray(input, resultArray, usedChars);
			input.splice(i, 0, ch);
			usedChars.pop();
		}
	};

	public advancedSearch = () => {
		if (this.orgControl.enabled) {
			this.programAdvancedSearchModalService
				.openModal$(
					false,
					this._organizations$.pipe(
						filter((x) => x !== undefined),
						map((orgs) => orgs.map((o) => o.id)),
					),
					false,
					[this.orgControl.value],
				)
				.subscribe((id) => {
					this.orgControl.setValue(id as number);
				});
		}
	};

	// ControlValueAccessor
	public writeValue(id: number): void {
		try {
			this.stopWatchForChange();
			this.orgControl.setValue(id);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) {
			this.orgControl.disable();
		} else {
			this.orgControl.enable();
		}

		this.watchForChange();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
