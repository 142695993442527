import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import spacetime from 'spacetime';
import { BARRIER_OPTIONS } from 'src/lib/constants/constants';
import {
	meetupPurposeOptions,
	meetupTitles,
} from 'src/lib/constants/meetup-data';
import { AddressValidationService } from 'src/lib/services/api/generic/address/address-validation.service';
import { OptionsService } from 'src/lib/services/api/generic/options/options.service';
import { StateOptionModel } from 'src/lib/services/api/generic/options/state-option.model';
import {
	MeetupAttendeeArgument,
	MeetupManagementAddArgument,
} from 'src/lib/services/api/organizations/meetups/meetup-management.argument';
import {
	MeetupAttendeeStatus,
	MeetupManagementItemModel,
} from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupManagementService } from 'src/lib/services/api/organizations/meetups/meetup-management.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { MapResponseErrors } from 'src/lib/utilities/api/map-response';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasValue } from 'src/lib/utilities/compare';
import { convertDateToPhpTimestamp } from 'src/lib/utilities/convert';
import { commonValidators } from 'src/lib/utilities/forms';
import { calendarDate } from 'src/lib/utilities/spacetime';
import { UserNamePipe } from '../../../../../pipes/user-name.pipe';
import { InputCheckboxComponent } from '../../../../../templates/controls/input-checkbox/input-checkbox.component';
import { SingleDatePickerComponent } from '../../../../../templates/controls/single-date-picker/single-date-picker.component';
import { CurrentTimeComponent } from '../../../../../templates/global/current-time/current-time.component';
import { InputTrimDirective } from '../../../../../templates/global/email-trim/input-trim.directive';
import { WaitSpinnerComponent } from '../../../../../templates/global/wait-spinner/wait-spinner.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

interface AddMeetupFormGroup {
	date: FormControl<Date>;
	time: FormGroup<FormControlWrapper<TimeFormGroup>>;
	link: FormControl<string>;
	address: FormGroup<FormControlWrapper<UserAddressModel>>;
	type: FormControl<string>;
	gathering_type: FormControl<string>;
	studentGroups: FormControl<number[]>;
	description: FormControl<string>;
	purpose: FormControl<number>;
	study_plan: FormControl<string>;
	barriers: FormControl<string>;
	resources: FormControl<string>;
	additional_notes: FormControl<string>;
}

interface TimeFormGroup {
	start: string;
	end: string;
	time_confirmed: boolean;
}

@Component({
	selector: 'ae-student-quick-log-meetup-modal',
	templateUrl: './student-quick-log-meetup-modal.component.html',
	styleUrls: ['./student-quick-log-meetup-modal.component.scss'],
	imports: [
		CurrentTimeComponent,
		DatePipe,
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		InputCheckboxComponent,
		InputTrimDirective,
		NgSelectModule,
		ReactiveFormsModule,
		SingleDatePickerComponent,
		SpinWhileDirective,
		UserNamePipe,
		ValidationErrorDirective,
		WaitSpinnerComponent,
	],
})
export class StudentQuickLogMeetupModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() studentId: number;
	public title = 'Log 1:1 Meetup';
	public userId: number;
	public addFormGroup: FormGroup<AddMeetupFormGroup>;
	public hostTimezone: string = null;

	public stateOptions: StateOptionModel[];
	public meetupTitles = meetupTitles;

	public loading = true;
	public saving = false;

	public barrierOptions = BARRIER_OPTIONS;
	public purposeOptions = meetupPurposeOptions;
	public currentDate = new Date();

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private optionsService: OptionsService,
		private meetupManagementService: MeetupManagementService,
		private userStore: UserStoreService,
		private addressValidationService: AddressValidationService,
	) {}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { studentId: number }): void => {
		// SET DATA
		this.studentId = data.studentId;
		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		combineLatest([
			this.optionsService.getStateOptions(),
			this.userStore.timezone$(this.studentId),
			this.userStore.currentUserUid$,
		])
			.pipe(takeUntil(this._unsubscribe$))

			.subscribe({
				next: ([s, tz, userId]) => {
					this.hostTimezone = tz.timezone;
					this.stateOptions = s;
					this.userId = userId;
					this.initForm();
				},
				error: (errors) => this.toastr.error(mergeStrings(errors), this.title),
			});
	};

	private initForm = () => {
		this.addFormGroup = this.fb.group<AddMeetupFormGroup>({
			date: new FormControl(this.currentDate, Validators.required),
			time: this.fb.group<FormControlWrapper<TimeFormGroup>>(
				{
					start: new FormControl('10:00', Validators.required),
					end: new FormControl('12:00', Validators.required),
					time_confirmed: new FormControl(false, Validators.requiredTrue),
				},
				{ validators: [this.timeValidator] },
			),
			link: new FormControl({ value: null, disabled: true }, [
				commonValidators.url(),
			]),
			address: this.fb.group<FormControlWrapper<UserAddressModel>>({
				city: new FormControl(null, commonValidators.requiredNotEmpty),
				state: new FormControl(null, commonValidators.requiredNotEmpty),
				street: new FormControl(null, commonValidators.requiredNotEmpty),
				street2: new FormControl(null),
			}),
			type: new FormControl(null, Validators.required),
			gathering_type: new FormControl(null, Validators.required),
			studentGroups: new FormControl(
				{ value: [], disabled: true },
				Validators.required,
			),
			description: new FormControl(null),

			purpose: new FormControl(1, Validators.required),
			study_plan: new FormControl(null),
			barriers: new FormControl(null),
			resources: new FormControl(null),
			additional_notes: new FormControl(null),
		});

		this.addFormGroup.controls.link.disable();
		this.addFormGroup.controls.address.disable();
		this.addFormGroup.controls.study_plan.disable();

		this.addFormGroup.controls.date.markAsTouched();
		this.addFormGroup.controls.date.markAsDirty();

		this.addFormGroup.controls.date.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				this.addFormGroup.controls.time.updateValueAndValidity();
			});

		this.addFormGroup.controls.type.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val === 'virtual') {
					this.addFormGroup.controls.link.enable();
					this.addFormGroup.controls.address.disable();
				} else if (val === 'physical') {
					this.addFormGroup.controls.link.disable();
					this.addFormGroup.controls.address.enable();
				}
			});

		this.addFormGroup.controls.gathering_type.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val === 'study_session') {
					this.addFormGroup.controls.study_plan.enable();
					this.addFormGroup.controls.study_plan.setValidators(
						Validators.required,
					);
				} else {
					this.addFormGroup.controls.study_plan.disable();
					this.addFormGroup.controls.study_plan.clearValidators();
				}
			});

		this.loading = false;
	};

	private timeValidator = (
		fg: FormGroup<FormControlWrapper<TimeFormGroup>>,
	) => {
		const hostCurrentTime = spacetime(this.currentDate, this.hostTimezone);
		const formDate = spacetime(
			this.addFormGroup?.controls.date.value ?? this.currentDate,
			this.hostTimezone,
		);
		const value = fg.value;
		const start = value.start.split(':').map((x) => parseInt(x));
		const end = value.end.split(':').map((x) => parseInt(x));

		if (hasValue(value.end) && hasValue(value.start)) {
			const startTime = formDate.clone().hour(start[0]).minute(start[1]);
			const endTime = formDate.clone().hour(end[0]).minute(end[1]);
			if (endTime.isBefore(startTime)) {
				return { invalid_range: true };
			} else if (
				hostCurrentTime.isBefore(startTime) ||
				hostCurrentTime.isBefore(endTime)
			) {
				return { invalid_time: true };
			}
			return null;
		} else {
			return null;
		}
	};

	public getTime = (fg?: FormGroup<FormControlWrapper<TimeFormGroup>>) => {
		const value = fg?.value ?? this.addFormGroup.controls.time.value;
		if (hasValue(value.end) && hasValue(value.start)) {
			const depart = value.end.split(':');
			const arrive = value.start.split(':');

			return Math.abs(
				parseInt(depart[0]) * 60 +
					parseInt(depart[1]) -
					(parseInt(arrive[0]) * 60 + parseInt(arrive[1])),
			);
		} else {
			return 0;
		}
	};

	public save = () => {
		if (this.addFormGroup?.valid && !this.saving) {
			this.saving = true;
			const fg = this.addFormGroup.controls;

			this.addressValidationService
				.verifyAddresses$({
					addressFormControl: fg.address,
				})
				.pipe(
					filter((verified) => verified),
					switchMap(() => {
						const depart = fg.time.value.end.split(':');
						const arrive = fg.time.value.start.split(':');

						const startSpace = calendarDate(
							spacetime(fg.date.value)
								.hour(parseInt(arrive[0]))
								.minute(parseInt(arrive[1])),
							this.hostTimezone,
						);
						const endSpace = calendarDate(
							spacetime(fg.date.value)
								.hour(parseInt(depart[0]))
								.minute(parseInt(depart[1])),
							this.hostTimezone,
						);

						const meetupAddArgs: MeetupManagementAddArgument = {
							frequency: 'one_time',
							groups: [`user||${this.studentId}`],
							host_uid: this.userId,
							type: fg.type.value,
							gathering_type: fg.gathering_type.value,
							start_timestamp: convertDateToPhpTimestamp(startSpace),
							end_timestamp: convertDateToPhpTimestamp(endSpace),
							description: fg.description.value,
						};

						if (fg.type.value === 'virtual') {
							meetupAddArgs.link = fg.link.value;
						} else {
							meetupAddArgs.address = fg.address.value as UserAddressModel;
						}

						return this.meetupManagementService
							.createScheduledMeetup(meetupAddArgs)
							.pipe(
								tap(() => {
									this.loading = true;
								}),
								switchMap((meetup: MeetupManagementItemModel) => {
									return this.meetupManagementService
										.editFutureMeetup(meetup.future_meetups[0]?.id, {
											attendees: [this.studentId],
										})
										.pipe(
											switchMap(() => {
												const args: Partial<MeetupAttendeeArgument> = {
													status: MeetupAttendeeStatus.attended,
													additional_notes: fg.additional_notes.value,
													arrival_timestamp:
														convertDateToPhpTimestamp(startSpace),
													departure_timestamp:
														convertDateToPhpTimestamp(endSpace),
													barriers: fg.barriers.value,
													purpose: fg.purpose.value,
													resources: fg.resources.value,
													finalized: meetup.finalized,
												};

												return this.meetupManagementService
													.editAttendance(
														meetup.future_meetups[0]?.id,
														this.studentId,
														args,
													)
													.pipe(
														switchMap(() => {
															return this.meetupManagementService.editFutureMeetup(
																meetup.future_meetups[0]?.id,
																{ finalized: true },
															);
														}),
													);
											}),
										);
								}),
							);
					}),
					takeUntil(this._unsubscribe$),
				)

				.subscribe({
					next: () => {
						this.toastr.success('Meetup Successfully Created', this.title);
						this.activeModal.close();
					},
					error: (errors: MapResponseErrors) => {
						this.toastr.error(
							`Meetup Failed to Create' ${mergeStrings(errors)}`,
							this.title,
						);
						this.loading = false;
					},
					complete: () => {
						this.saving = false;
					},
				});
		}
	};
}
