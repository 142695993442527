import { Component, Input } from '@angular/core';
import { getEnvironment } from 'src/lib/environment/environment';

@Component({
	selector: 'ae-profile-picture',
	templateUrl: './profile-picture.component.html',
	styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent {
	@Input() src: string;
	@Input() size: 'large' | null;

	public defaultProfileImageUrl = `${
		getEnvironment().assets
	}/images/person_default.png`;

	public profileImageError = (ele: HTMLElement) => {
		ele.setAttribute('src', this.defaultProfileImageUrl);
	};
}
