import {
	CdkDrag,
	CdkDragDrop,
	CdkDropList,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	NgbActiveModal,
	NgbModal,
	NgbModalOptions,
	NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartialOptions } from 'overlayscrollbars';
import { AsyncSubject, Subject, merge, of } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmPopupService } from 'src/lib/services/utility/confirm-popup/confirm-popup.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { arrayUnion } from 'src/lib/utilities/array';
import { isEquivalentArray } from 'src/lib/utilities/compare';
import { generateGuid } from 'src/lib/utilities/random';
import { firstBy } from 'thenby';
import { OverlayScrollbarsDragdropComponent } from '../../../../../thirdparty/overlay-scrollbars/overlay-scrollbars-dragdrop/overlay-scrollbars-dragdrop.component';
import { ModalMoverComponent } from '../../../../global/modal-mover/modal-mover.component';
import { PaginationTableColumnDirective } from '../../children/pagination-table-column.directive';
import {
	InternalPaginationTable,
	protectedPaginationTableConfigurationDefaultName,
} from '../../core/internal-pagination-table.interface';
import {
	columnStyleRuleClassSet,
	columnStyleRuleComparisonSet,
	warningColumnWidth,
} from '../../core/pagination-table-configuration.data';
import {
	PaginationTableSavedConfiguration,
	PaginationTableSavedResizedColumnWidthsConfiguration,
	PaginationTableSavedVisibleColumnConfiguration,
	PaginationTableStyleRule,
} from '../../types/ipagination-table-user-preferences';
import {
	PaginationTableConfigurationEdit,
	PaginationTableConfigurationEditModalComponent,
} from '../pagination-table-configuration-edit-modal/pagination-table-configuration-edit-modal.component';

@Component({
	selector: 'ae-pagination-table-configuration-modal',
	templateUrl: './pagination-table-configuration-modal.component.html',
	styleUrls: ['./pagination-table-configuration-modal.component.scss'],
	imports: [
		CdkDrag,
		CdkDropList,
		FormsModule,
		ModalMoverComponent,
		NgClass,
		NgSelectModule,
		NgTemplateOutlet,
		OverlayScrollbarsDragdropComponent,
		ReactiveFormsModule,
	],
})
export class PaginationTableConfigurationModalComponent<T>
	implements OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();
	private _afterContentChecked$ = new Subject<null>();

	modal_ConfigurationModalRef: NgbModalRef;
	modal_SortedColumns: PaginationTableColumnDirective<T>[];
	modal_ExpandedStyleRules: Record<string, Record<string, boolean>> = {};

	modal_ColumnSelectorScrollOptions: PartialOptions = {
		overflow: {
			y: 'scroll',
			x: 'hidden',
		},
		scrollbars: {
			autoHide: 'never',
		},
	};

	columnStyleRuleComparisons = columnStyleRuleComparisonSet;
	columnStyleRuleClasses = columnStyleRuleClassSet;

	pgTable: InternalPaginationTable<T>;

	public newColumns: string[] = [];
	public undersizedColumns: string[] = [];

	constructor(
		private activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private confirmPopupService: ConfirmPopupService,
		private modalService: NgbModal,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		pgTable: InternalPaginationTable<T>;
	}): void => {
		// SET DATA
		this.pgTable = data.pgTable;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		const snapshot = this.pgTable.getColumns(true);
		merge(
			this.pgTable.userPrefForm.controls.activeConfiguration.valueChanges,
			of(this.pgTable.userPreferences.activeConfiguration),
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((activeConfig: PaginationTableSavedConfiguration) => {
				this.modal_SortedColumns = snapshot.slice().sort(
					firstBy<PaginationTableColumnDirective<T>>((c) => {
						const i = (activeConfig.columnOrder || []).indexOf(c.columnId);
						return i !== -1 ? i : Number.MAX_SAFE_INTEGER;
					}),
				);
			});

		this.pgTable.userPrefForm.valueChanges
			.pipe(
				filter(() => this.pgTable.userPrefFormIgnoreChangesCount === 0),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.pgTable.redraw$.next(null);
				this.cdref.detectChanges();
			});

		// Check for new columns
		const configsToCheck = [
			this.pgTable.userPreferences.activeConfiguration?.visibleColumns,
			...(this.pgTable.userPreferences.savedConfigurations?.map(
				(sc) => sc.visibleColumns,
			) ?? []),
			this.pgTable.userPreferences.notifiedColumns?.reduce((acc, v) => {
				acc[v] = true;
				return acc;
			}, {} as PaginationTableSavedVisibleColumnConfiguration),
		].filter((x) => x != null);

		if (configsToCheck.length > 0) {
			this.newColumns = snapshot
				.map((x) => x.columnId)
				.filter((x) => configsToCheck.every((c) => c[x] == null));
		}

		const newNotifyColumns = arrayUnion(
			this.pgTable.userPreferences.notifiedColumns ?? [],
			snapshot.map((x) => x.columnId),
		);

		if (
			!isEquivalentArray(
				newNotifyColumns,
				this.pgTable.userPreferences.notifiedColumns,
			)
		) {
			this.pgTable.userPrefForm.controls.notifiedColumns.setValue(
				newNotifyColumns,
			);
		}

		// Check for warning width columns
		this.pgTable.userPrefForm.controls.activeConfiguration.controls.resizedColumnWidths.valueChanges
			.pipe(
				startWith(
					this.pgTable.userPrefForm.controls.activeConfiguration.controls
						.resizedColumnWidths.value,
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((widths) => {
				this.undersizedColumns = [];
				Object.entries(widths).forEach(([key, val]) => {
					if (val < warningColumnWidth) {
						this.undersizedColumns.push(key);
					}
				});
			});
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
		this._afterContentChecked$.complete();
	}

	public modal_isColumnChecked = (
		column: PaginationTableColumnDirective<T>,
	) => {
		return (
			this.pgTable.userPreferences.activeConfiguration.visibleColumns[
				column.columnId
			] || column.locked
		);
	};

	public modal_isColumnResized = (
		column: PaginationTableColumnDirective<T>,
	) => {
		return (
			this.pgTable.userPreferences.activeConfiguration.resizedColumnWidths[
				column.columnId
			] != null
		);
	};

	/*
	 *
	 * Configuration Modal Functions
	 *
	 */
	public modal_ResetStyleRules = () => {
		this.pgTable.userPrefForm.controls.activeConfiguration.controls.styleRules.clear();
	};

	public modal_ResetColumnResizing = (
		column?: PaginationTableColumnDirective<T>,
	) => {
		let newValue: PaginationTableSavedResizedColumnWidthsConfiguration;

		if (column) {
			newValue =
				this.pgTable.userPrefForm.controls.activeConfiguration.controls
					.resizedColumnWidths.value;
			delete newValue[column.columnId];
		} else {
			newValue = {};
		}

		this.pgTable.userPrefForm.controls.activeConfiguration.controls.resizedColumnWidths.setValue(
			newValue,
		);
	};

	public modal_ResetColumnOrder = () => {
		this.pgTable.userPrefForm.controls.activeConfiguration.controls.columnOrder.setValue(
			[],
		);
	};

	public modal_IsStyleRuleExpanded = (columnId: string): boolean => {
		if (
			!this.modal_ExpandedStyleRules[
				this.pgTable.userPreferences.activeConfiguration.configId
			]
		) {
			this.modal_ExpandedStyleRules[
				this.pgTable.userPreferences.activeConfiguration.configId
			] = {};
		}

		return Boolean(
			this.modal_ExpandedStyleRules[
				this.pgTable.userPreferences.activeConfiguration.configId
			][columnId],
		);
	};

	public modal_ToggleStyleRuleExpanded = (
		columnId: string,
		open?: boolean,
		element?: HTMLElement,
	): void => {
		if (
			!this.modal_ExpandedStyleRules[
				this.pgTable.userPreferences.activeConfiguration.configId
			]
		) {
			this.modal_ExpandedStyleRules[
				this.pgTable.userPreferences.activeConfiguration.configId
			] = {};
		}

		if (open == null) {
			open =
				!this.modal_ExpandedStyleRules[
					this.pgTable.userPreferences.activeConfiguration.configId
				][columnId];
		}

		this.modal_ExpandedStyleRules[
			this.pgTable.userPreferences.activeConfiguration.configId
		][columnId] = open;

		if (open) {
			setTimeout(() => {
				if (element != null) {
					// Overlayscrollbars doesn't have scroll anymore https://github.com/KingSora/OverlayScrollbars/issues/445
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'nearest',
					});
				}
			}, 100);
		}
	};

	public modal_ToggleAllStyleRuleExpanded = (): void => {
		const allColumns = this.pgTable.getColumns(true);
		const anyOpen =
			allColumns.find((x) => this.modal_IsStyleRuleExpanded(x.columnId)) !=
			null;
		allColumns.forEach((x) => {
			this.modal_ToggleStyleRuleExpanded(x.columnId, !anyOpen);
		});
	};

	public modal_GetColumnStyleRules = (
		columnId: string,
	): FormGroup<FormControlWrapper<PaginationTableStyleRule>>[] => {
		return this.pgTable.userPrefForm.controls.activeConfiguration.controls.styleRules.controls.filter(
			(x) => x.controls.columnId.value === columnId,
		);
	};

	public modal_AddColumnStyleRule = (columnId: string) => {
		const group = this.pgTable.load_AddStyleRuleFormGroup();
		group.controls.columnId.setValue(columnId);
	};

	public modal_DeleteStyleRule = (
		target: FormGroup<FormControlWrapper<PaginationTableStyleRule>>,
	) => {
		const index =
			this.pgTable.userPrefForm.controls.activeConfiguration.controls.styleRules.controls.findIndex(
				(x) => x === target,
			);
		if (index > -1) {
			this.pgTable.userPrefForm.controls.activeConfiguration.controls.styleRules.removeAt(
				index,
			);
		}
	};

	public modal_TryLoadSavedConfiguration = (configId: string) => {
		const foundConfig =
			this.pgTable.userPrefForm.controls.savedConfigurations.value.find(
				(x) => x.configId === configId,
			);

		if (foundConfig) {
			this.pgTable.load_SavedConfiguration(foundConfig);
		}
	};

	public dismiss = () => {
		this.activeModal.dismiss();
	};

	public drop = (e: CdkDragDrop<PaginationTableColumnDirective<T>[]>) => {
		moveItemInArray(e.container.data, e.previousIndex, e.currentIndex);
		this.pgTable.userPrefForm.controls.activeConfiguration.controls.columnOrder.setValue(
			e.container.data.map((c) => c.columnId),
		);
	};

	/*
	 *
	 * Saved Configuration Management
	 *
	 */
	public savedConfiguration_OpenEditModal = (createNew: boolean) => {
		let name = null;
		if (
			!createNew &&
			this.pgTable.userPrefForm.controls.activeConfiguration.controls.name
				.value !== protectedPaginationTableConfigurationDefaultName
		) {
			name =
				this.pgTable.userPrefForm.controls.activeConfiguration.controls.name
					.value;
		}

		const ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
		};

		const modal = this.modalService.open(
			PaginationTableConfigurationEditModalComponent,
			ngbModalOptions,
		);
		(
			modal.componentInstance as PaginationTableConfigurationEditModalComponent<T>
		).bindModalData({
			pgTable: this.pgTable,
			name: name,
			create: createNew,
		});

		modal.result
			.then((val: PaginationTableConfigurationEdit) => {
				if (val.create) {
					const savedConfig = this.savedConfiguration_Create(
						val.name,
						val.copy,
					);
					this.pgTable.load_SavedConfiguration(savedConfig.value);
				} else {
					this.savedConfiguration_Rename(val.name);
				}
			})
			.catch(() => {
				// Do Nothing
			});
	};

	public savedConfiguration_OpenDeleteModal = () => {
		this.confirmPopupService
			.confirm$({
				message: `You are sure you want to delete ${this.pgTable.userPrefForm.controls.activeConfiguration.controls.name.value}`,
			})
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((x) => {
				if (x) {
					this.savedConfiguration_Delete(
						this.pgTable.userPrefForm.controls.activeConfiguration.controls
							.configId.value,
					);
					if (
						this.pgTable.userPrefForm.controls.savedConfigurations.length > 0
					) {
						this.pgTable.load_SavedConfiguration(
							this.pgTable.userPrefForm.controls.savedConfigurations.at(0)
								.value,
						);
					} else {
						this.pgTable.load_SavedConfiguration(
							this.pgTable.formBuild_SavedConfiguration_Group()
								.value as PaginationTableSavedConfiguration,
						);
					}
				}
			});
	};

	public savedConfiguration_Rename = (name: string) => {
		const activeConfig = this.pgTable.userPrefForm.controls.activeConfiguration;
		if (
			activeConfig.controls.name.value ===
			protectedPaginationTableConfigurationDefaultName
		) {
			this.savedConfiguration_Create(name, true);
		}

		activeConfig.controls.name.setValue(name);
	};

	public savedConfiguration_Create = (name: string, copyActive: boolean) => {
		this.pgTable.userPrefFormIgnoreChangesCount++;

		const saveControl = this.pgTable.formBuild_SavedConfiguration_Control();
		if (copyActive) {
			saveControl.patchValue(
				JSON.parse(
					JSON.stringify(
						this.pgTable.userPrefForm.controls.activeConfiguration.value,
					),
				),
			);
		}

		const saveVal = saveControl.value;
		saveVal.name = name;
		saveVal.configId = generateGuid();
		saveControl.setValue(saveVal);

		this.pgTable.userPrefForm.controls.savedConfigurations.push(saveControl);
		this.pgTable.userPrefFormIgnoreChangesCount--;

		return saveControl;
	};

	public savedConfiguration_Delete = (configId: string) => {
		const index =
			this.pgTable.userPrefForm.controls.savedConfigurations.controls.findIndex(
				(x) => x.value.configId === configId,
			);

		if (index > -1) {
			this.pgTable.userPrefForm.controls.savedConfigurations.removeAt(index);
		}
	};
}
