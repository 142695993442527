import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import spacetime, { type Spacetime } from 'spacetime';
import { meetupTitles } from 'src/lib/constants/meetup-data';
import {
	MeetupAttendeeStatus,
	MeetupManagementListItemModel,
} from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { StudentMeetupStoreService } from 'src/lib/services/stores/students/meetup/student-meetup-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasValue } from 'src/lib/utilities/compare';
import { isWeekend } from 'src/lib/utilities/date';
import { normalizeHref } from 'src/lib/utilities/html';
import { firstBy } from 'thenby';
import { SpaceFormatPipe } from '../../../../../pipes/spacetime/space-format.pipe';
import { AddressDisplayComponent } from '../../../../../templates/global/user-address/address-display.component';
import { UserTimeComponent } from '../../../../../templates/global/user-time/user-time.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { MeetupUtilitiesService } from '../service/meetup-utilities.service';

@Component({
	selector: 'ae-student-meetup-available-modal',
	templateUrl: './student-meetup-available-modal.component.html',
	styleUrls: ['./student-meetup-available-modal.component.scss'],
	imports: [
		AddressDisplayComponent,
		NgClass,
		SpaceFormatPipe,
		SpinWhileDirective,
		UserTimeComponent,
	],
})
export class StudentMeetupAvailableModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() studentId: number;

	public data: [Spacetime, MeetupManagementListItemModel[]][] = [];
	public selectedMeetupId: number;

	public meetupTitles = meetupTitles;
	public normalizeHref = normalizeHref;
	public hasValue = hasValue;
	public MeetupAttendeeStatus = MeetupAttendeeStatus;
	public isWeekend = isWeekend;

	public loading = true;
	public saving = false;
	public availableMeetups = false;
	public removingMeetup = false;
	public loadingMeetups = false;
	public hasChanges = false;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private toastr: ToastrService,
		private studentMeetupStoreService: StudentMeetupStoreService,
		public meetupUtilitiesService: MeetupUtilitiesService,
	) {}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { studentId: number }): void => {
		// SET DATA
		this.studentId = data.studentId;
		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC

		const today = spacetime.now();
		for (let i = 0; i < 7; i++) {
			const day = today.add(i, 'date');
			this.data.push([day, []]);
		}

		this.loadMeetups();
	};

	private loadMeetups = () => {
		this.loadingMeetups = true;

		combineLatest(
			this.data.map(([date, meetups]) =>
				this.studentMeetupStoreService
					.meetups$(this.studentId, date.month(), date.year())
					.pipe(
						tap((m) => {
							meetups.length = 0;
							const mm = this.getMeetupForDay(date, m);
							meetups.push(...mm);
						}),
					),
			),
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: () => {
					this.loading = false;
					this.loadingMeetups = false;
					this.availableMeetups =
						this.data.reduce((sum, item) => sum + item[1].length, 0) > 0;
				},
				error: (errors) =>
					this.toastr.error(mergeStrings(errors), 'Available Meetups'),
			});
	};

	public getMeetupForDay = (
		date: Spacetime,
		meetups: MeetupManagementListItemModel[],
	) => {
		return meetups
			.filter(
				(m) =>
					spacetime(m.start_date).isSame(date, 'date') &&
					m.active &&
					spacetime.now().isBefore(m.end_date),
			)
			.sort(firstBy((d) => d.start_date));
	};

	public markSelected = (id: number) => {
		this.selectedMeetupId = id;
	};
}
