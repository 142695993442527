import { Component } from '@angular/core';
import { BillboardLabelDirective } from '../../../../templates/global/billboard-label/billboard-label.directive';

@Component({
	selector: 'ae-profile-sitemap',
	templateUrl: './profile-accessibility-statement.component.html',
	styleUrls: ['./profile-accessibility-statement.component.scss'],
	imports: [BillboardLabelDirective],
})
export class ProfileAccessibilityStatementComponent {}
