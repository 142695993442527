import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { AnchorLinkDirective } from '../../global/anchor-link/anchor-link.directive';
import { SizeMonitorDirective } from '../../global/size-monitor/size-monitor.directive';
import { SidenavTabberComponent } from '../sidenav-tabber/sidenav-tabber.component';
import { SpinWhileDirective } from '../spin-while/spin-while.directive';

@Component({
	selector: 'ae-tabber',
	templateUrl: './tabber.component.html',
	styleUrls: ['./tabber.component.scss'],
	imports: [
		AnchorLinkDirective,
		RouterLink,
		RouterLinkActive,
		RouterOutlet,
		SizeMonitorDirective,
		SpinWhileDirective,
	],
})
export class TabberComponent extends SidenavTabberComponent {
	@Input() preserveParams = true;

	constructor(router: Router, fb: FormBuilder, localStore: LocalStoreService) {
		super(router, fb, localStore);
	}
}
