import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	recordTransformerFactory,
} from 'src/lib/utilities/api/class-transformer-types';

export class LearningPlanIdTitleModel {
	id: number;
	title: string;
}

export function LearningPlanIdTitleModel_ClassTransform_Type() {
	return LearningPlanIdTitleModel;
}

export class LearningPlanCreditsModel {
	completed: number;
	remaining?: number;
	required: number;
	gpa?: number;
}

export function LearningPlanCreditsModel_ClassTransform_Type() {
	return LearningPlanCreditsModel;
}

export class LearningPlanLockModel {
	locked: boolean;
	planned_only_registrations: boolean;
}

export function LearningPlanLockModel_ClassTransform_Type() {
	return LearningPlanLockModel;
}

export class LearningPlanSubjectAreasModel {}

export function LearningPlanSubjectAreasModel_ClassTransform_Type() {
	return LearningPlanSubjectAreasModel;
}

export class LearningPlanTransferTranscriptModel {
	@Transform(dateTransformer)
	date: Date;
	name: string;
}

export function LearningPlanTransferTranscriptModel_ClassTransform_Type() {
	return LearningPlanTransferTranscriptModel;
}

export class LearningPlanPlannerModel {
	name: string;
	uid: number;
}

export function LearningPlanPlannerModel_ClassTransform_Type() {
	return LearningPlanPlannerModel;
}

export class LearningPlanPlannedFieldsModel {
	@Expose({ name: 'pacing_start_timestamp' })
	@Transform(dateTransformer)
	pacing_start_date: Date;
	pacing_weeks: number;
}

export function LearningPlanPlannedFieldsModel_ClassTransform_Type() {
	return LearningPlanPlannedFieldsModel;
}

export class LearningPlanCourseSectionModel {
	class_name: string;
	course_code: string;
	course_name: string;
	section_id: number;
	required: boolean;
	credits: number;
	credits_converted: number;
	term: string;
	is_current: boolean;
	scheduled: boolean;
	transferred: boolean;
	grade: string;
	registration_id: number;
	registration_title: string;
	@Expose({ name: 'start_date_completion_timestamp' })
	@Transform(dateTransformer)
	pacing_start_date: Date;
	pacing_weeks: number;
	target_end_timestamp: number;
	@Expose({ name: 'end_date_completion_timestamp' })
	@Transform(dateTransformer)
	end_date_completion: Date;
	planned: boolean;
	conflicted: boolean;
	weight: number;
	@Type(LearningPlanTransferTranscriptModel_ClassTransform_Type)
	transfer_transcript: LearningPlanTransferTranscriptModel;
	@Type(LearningPlanPlannerModel_ClassTransform_Type)
	planned_by: LearningPlanPlannerModel;
	@Expose({ name: 'planned_on_timestamp' })
	@Transform(dateTransformer)
	planned_date: Date;
	@Type(LearningPlanPlannedFieldsModel_ClassTransform_Type)
	planned_registration_fields: LearningPlanPlannedFieldsModel;
	section_title: string;

	get isSuggested() {
		return this.planned === false && this.registration_id === null;
	}

	get isCompleted() {
		return this.end_date_completion !== null && this.transferred === false;
	}
}

export function LearningPlanCourseSectionModel_ClassTransform_Type() {
	return LearningPlanCourseSectionModel;
}

export class LearningPlanCourseModel {
	course_title: string;
	course_code: string;
	credits_required: number;
	credits_completed: number;
	@Type(LearningPlanCourseSectionModel_ClassTransform_Type)
	sections: LearningPlanCourseSectionModel[];
}

export function LearningPlanCourseModel_ClassTransform_Type() {
	return LearningPlanCourseModel;
}

export class LearningPlanRegistrationModel {
	class_name: string;
	course_name: string;
	course_code: string;
	credits: string;
	credits_converted: number;
	is_current: boolean;
	grade: string;
	registration_id: number;
	registration_title: string;
	required: boolean;
	scheduled: boolean;
	section_id: string;
	term: string;
	transferred: boolean;
	@Type(LearningPlanTransferTranscriptModel_ClassTransform_Type)
	transfer_transcript: LearningPlanTransferTranscriptModel;
}

export function LearningPlanRegistrationModel_ClassTransform_Type() {
	return LearningPlanRegistrationModel;
}

export class LearningPlanSubjectModel {
	subject: string;
	subject_code: string;
	@Type(LearningPlanCreditsModel_ClassTransform_Type)
	credits: LearningPlanCreditsModel;
	@Transform(
		recordTransformerFactory(LearningPlanCourseModel_ClassTransform_Type),
	)
	classes: Record<string, LearningPlanCourseModel>;
	@Type(LearningPlanRegistrationModel_ClassTransform_Type)
	registrations: LearningPlanRegistrationModel[];
}

export function LearningPlanSubjectModel_ClassTransform_Type() {
	return LearningPlanSubjectModel;
}

export class LearningPlanActionModel {
	action: string;
	performed_by: number;
	performed_on: number;
}

export function LearningPlanActionModel_ClassTransform_Type() {
	return LearningPlanActionModel;
}

export class LearningPlanModel {
	@Type(LearningPlanIdTitleModel_ClassTransform_Type)
	course_catalog: LearningPlanIdTitleModel;
	@Type(LearningPlanCreditsModel_ClassTransform_Type)
	credits: LearningPlanCreditsModel;
	id: number;
	@Type(LearningPlanIdTitleModel_ClassTransform_Type)
	graduation_path: LearningPlanIdTitleModel;
	@Type(LearningPlanActionModel_ClassTransform_Type)
	last_rebuild: LearningPlanActionModel;
	@Type(LearningPlanActionModel_ClassTransform_Type)
	last_update: LearningPlanActionModel;
	@Type(LearningPlanLockModel_ClassTransform_Type)
	lock: LearningPlanLockModel;
	@Transform(
		recordTransformerFactory(LearningPlanSubjectModel_ClassTransform_Type),
	)
	subject_areas: Record<string, LearningPlanSubjectModel>;
}
