import { Pipe, PipeTransform } from '@angular/core';
import { getEnvironment } from 'src/lib/environment/environment';

@Pipe({
	name: 'assetUrl',
})
export class AssetUrlPipe implements PipeTransform {
	transform(url: string): string {
		return getEnvironment().assets + url;
	}
}
