import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sanitizeId',
})
export class SanitizeIdPipe implements PipeTransform {
	transform(value: string): string {
		return value?.replace('.', '-').replace(':', '-') ?? value;
	}
}
