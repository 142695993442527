import { AsyncPipe, NgClass } from '@angular/common';
import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import {
	NgbDropdown,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlWrapper } from 'src/lib/types/forms.def';

export interface OrNorSelectorValue<T> {
	or: boolean;
	values: T[];
}

interface OrNorSelectorControlFormGroup<T> {
	or: boolean;
	selectedItems: T[];
}

@Component({
	selector: 'ae-or-nor-select',
	templateUrl: './or-nor-select.component.html',
	styleUrls: ['./or-nor-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrNorSelectComponent),
			multi: true,
		},
	],
	imports: [
		AsyncPipe,
		FormsModule,
		NgbDropdown,
		NgbDropdownMenu,
		NgbDropdownToggle,
		NgClass,
		ReactiveFormsModule,
	],
})
export class OrNorSelectComponent<T>
	implements OnDestroy, ControlValueAccessor, OnInit, OnChanges
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() items: T[];
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() id: string = null;

	$filteredItems: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	controlFG: FormGroup<FormControlWrapper<OrNorSelectorControlFormGroup<T>>>;
	filterControl: FormControl<string>;

	private _touchFunction: () => void;
	private _changeFunction: (value: OrNorSelectorValue<T>) => void = () => null;
	private _changeWatcher: Subscription;

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.filterControl = new FormControl(null);
		this.filterControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val == null || val === '') {
					this.$filteredItems.next(this.items);
				} else {
					this.$filteredItems.next(
						this.items?.filter((i) => {
							const label = this.bindLabel != null ? i[this.bindLabel] : i;
							return label.toLowerCase().includes(val.toLowerCase());
						}),
					);
				}
			});
		this.filterControl.setValue('');

		this.controlFG = this.fb.group<
			FormControlWrapper<OrNorSelectorControlFormGroup<T>>
		>({
			or: new FormControl(true),
			selectedItems: new FormControl([]),
		});
		this.watchForChange();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.items) {
			this.filterControl?.setValue(this.filterControl.value);
		}
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	protected onDropdownOpenChange = () => {
		this.filterControl.setValue('');
	};

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.controlFG.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (this._changeFunction) {
					const selectedValues = val.selectedItems.map((i) => {
						return this.bindValue != null ? i[this.bindValue] : i;
					});

					this._changeFunction({
						or: val.or,
						values: selectedValues,
					} as OrNorSelectorValue<T>);
				}
				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	// Implementing ControlValueAccessor
	public writeValue(val: OrNorSelectorValue<T>): void {
		try {
			this.stopWatchForChange();
			const values = val?.values ?? [];
			const selectedItems = this.items.filter((i) =>
				values.some((v) => {
					return this.bindValue != null ? v === i[this.bindValue] : v === i;
				}),
			);

			this.controlFG?.setValue({
				or: val?.or ?? true,
				selectedItems: selectedItems,
			} as OrNorSelectorControlFormGroup<T>);
		} catch (e) {
			throw new Error(
				`OrNorSelectComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}

	public toggleItem = (item: T) => {
		const si = this.controlFG.controls.selectedItems.value;
		const iIndex = si.indexOf(item);
		if (iIndex === -1) {
			si.push(item);
		} else {
			si.splice(iIndex, 1);
		}

		this.controlFG.controls.selectedItems.setValue(si);
	};

	public isItemSelected = (item: T): boolean => {
		const si = this.controlFG.controls.selectedItems.value;
		const iIndex = si.indexOf(item);
		if (iIndex === -1) {
			return false;
		} else {
			return true;
		}
	};

	public clearSelection = () => {
		this.controlFG.controls.selectedItems.setValue([]);
	};

	public setDisabledState(isDisabled): void {
		this.stopWatchForChange();

		if (isDisabled) this.controlFG.disable();
		else this.controlFG.enable();

		this.watchForChange();
	}
}
