import { Directive, Input } from '@angular/core';

@Directive({
	selector: '[aeCast]',
	exportAs: 'aeCast',
})
export class CastDirective<S, T = S> {
	@Input('aeCast') input: [S, T];

	public get c(): T {
		return this.input[0] as any as T;
	}

	public get r(): S {
		return this.input[0];
	}
}
