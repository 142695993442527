import { Component, Input } from '@angular/core';
import { PhoneNumberPipe } from '../../../pipes/phone-number.pipe';

@Component({
	selector: 'ae-staff-phone',
	templateUrl: './staff-phone.component.html',
	styleUrls: ['./staff-phone.component.scss'],
	imports: [PhoneNumberPipe],
})
export class StaffPhoneComponent {
	@Input() phone: { value: number | string; type: string; sms: boolean };
}
