import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { AsyncSubject, Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import spacetime, { type Format } from 'spacetime';
import { getEnvironment } from 'src/lib/environment/environment';

@Component({
	selector: 'ae-current-time',
	templateUrl: './current-time.component.html',
	styleUrls: ['./current-time.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentTimeComponent implements OnInit, OnDestroy, OnChanges {
	private _unsubscribe$ = new AsyncSubject<null>();

	private pump = new Subject<null>();

	@Input() timezone: string;
	@Input() format: Format = 'time';
	@Input() hoverFormat: Format;

	private lastTime: string;
	public currentTime: string;
	public hoverTime: string;
	public datetime: string;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.cdr.detach();

		this.pump.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
			let now = spacetime.now();

			if (this.timezone != null) {
				now = now.goto(this.timezone);
			}

			this.lastTime = this.currentTime;
			this.currentTime = now.format(this.format).replace(new RegExp(/_/g), ' ');
			this.datetime = now.format('iso');

			if (this.hoverFormat != null) {
				this.hoverTime = now
					.format(this.hoverFormat)
					.replace(new RegExp(/_/g), ' ');
			} else {
				this.hoverTime = null;
			}

			if (this.lastTime !== this.currentTime) {
				this.cdr.detectChanges();
			}
		});

		interval(
			getEnvironment().settings.templates.global.currentTime.intervalTime,
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => this.pump.next(null));

		this.pump.next(null);
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.pump.next(null);
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
