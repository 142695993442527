import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, combineLatest, of, take, takeUntil } from 'rxjs';
import { UserAgreementModel } from 'src/lib/services/api/users/agreements/user-agreement.model';
import { UsersAgreementsService } from 'src/lib/services/api/users/agreements/users-agreements.service';
import { UserModalModel } from 'src/lib/services/api/users/modals/user-modal.model';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { UserNamePipe } from '../../../pipes/user-name.pipe';
import { InputCheckboxComponent } from '../../../templates/controls/input-checkbox/input-checkbox.component';
import { ModalCloseTimeoutComponent } from '../../../templates/global/modal-close-timeout/modal-close-timeout.component';
import { ModalHideUntilDirective } from '../../../templates/global/modal-hide-until/modal-hide-until.directive';
import { WaitSpinnerComponent } from '../../../templates/global/wait-spinner/wait-spinner.component';
import { PrintModalService } from '../print-modal/print-modal.service';

@Component({
	selector: 'ae-prompt-agreements-modal',
	templateUrl: './prompt-agreements-modal.component.html',
	styleUrls: ['./prompt-agreements-modal.component.scss'],
	imports: [
		FormsModule,
		InputCheckboxComponent,
		ModalCloseTimeoutComponent,
		ModalHideUntilDirective,
		ReactiveFormsModule,
		UserNamePipe,
		WaitSpinnerComponent,
	],
})
export class PromptAgreementsModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	public uid: number;
	public agreements: UserAgreementModel[];

	public agreementsForm: FormArray<FormControl<boolean>>;
	public data: UserModalModel;

	public saving: boolean = false;
	public hasAgreementsToComplete: boolean = false;

	constructor(
		private cdref: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
		private fb: FormBuilder,
		private usersAgreementsService: UsersAgreementsService,
		private userStoreService: UserStoreService,
		private printModalService: PrintModalService,
		private toastr: ToastrService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { data: UserModalModel }): void => {
		// SET DATA
		this.data = data.data;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.uid = this.userStoreService.currentUserUid;

		this.usersAgreementsService
			.getAgreements(this.uid)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: (agreements) => {
					if ((agreements?.filter((x) => !x.completed).length ?? 0) > 0) {
						this.agreements = agreements;
						this.buildForms();
					} else {
						this.activeModal.dismiss();
					}
				},
				error: (errors) => console.error(mergeStrings(errors)),
			});
	};

	private buildForms = () => {
		this.agreementsForm = this.fb.array<FormControl<boolean>>(
			this.agreements.map(
				(a) => new FormControl(a.completed, [Validators.requiredTrue]),
			),
		);
	};

	public viewAgreement = (agreement: UserAgreementModel) => {
		this.printModalService
			.openModal$(agreement.body, agreement.title)
			.subscribe();
	};

	public complete = () => {
		if (this.agreementsForm.valid) {
			this.saving = true;

			const agreementSaves = this.agreements.map((a, i) => {
				if (a.completed) {
					return of(null); // Agreement was already agreed to previously
				} else if (this.agreementsForm.at(i).value === true) {
					return this.usersAgreementsService.completeAgreement(
						this.uid,
						a.agreement_id,
					);
				}
				return null;
			});
			agreementSaves.push(of(null)); // prevents locking up when there's no agreements to save

			combineLatest(agreementSaves)
				.pipe(take(1))
				.subscribe({
					next: () => {
						this.activeModal.close();
					},
					error: (err) => {
						this.toastr.error(mergeStrings(err), 'Agreements');
						this.activeModal.dismiss();
					},
				});
		}
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
