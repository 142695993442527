import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	QueryList,
	SimpleChanges,
	ViewChildren,
	WritableSignal,
	signal,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import spacetime, { type Spacetime } from 'spacetime';
import { getEnvironment } from 'src/lib/environment/environment';
import { ChannelParticipantModel } from 'src/lib/services/api/gabby/channels/channel-participant.model';
import { ChannelModel } from 'src/lib/services/api/gabby/channels/channel.model';
import {
	MessageDeliveryMethodModel,
	MessageDeliveryModel,
} from 'src/lib/services/api/gabby/messages/message-delivery.model';
import { MessagePlatform } from 'src/lib/services/api/gabby/messages/message-platform.enum';
import { MessageType } from 'src/lib/services/api/gabby/messages/message-type.enum';
import { MessageModel } from 'src/lib/services/api/gabby/messages/message.model';
import { GabbyUserStoreService } from 'src/lib/services/stores/gabby/user-store/gabby-user-store.service';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { linkifyText } from 'src/lib/utilities/html';
import { PhoneNumberPipe } from '../../../../pipes/phone-number.pipe';
import { SpaceFormatPipe } from '../../../../pipes/spacetime/space-format.pipe';
import { NgDompurifyPipe } from '../../../../thirdparty/ng-dompurify/ng-dompurify.pipe';
import { GabbyParticipantLabelComponent } from '../../gabby-participant-label/gabby-participant-label.component';
import { GabbyChatAttachmentHiderComponent } from '../gabby-chat-attachment-hider/gabby-chat-attachment-hider.component';
import {
	AttachmentPostMessageRender,
	DatePostMessageRender,
	PostMessageRender,
	PostMessageRenderType,
} from '../gabby-chat-component-definitions';
import { GabbyChatMessagePostRenderDirective } from './gabby-chat-message-postrender.directive';

@Component({
	selector: 'ae-gabby-chat-message-row',
	templateUrl: './gabby-chat-message-row.component.html',
	styleUrls: ['./gabby-chat-message-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		GabbyChatAttachmentHiderComponent,
		GabbyChatMessagePostRenderDirective,
		GabbyParticipantLabelComponent,
		NgbTooltip,
		NgClass,
		NgDompurifyPipe,
		NgTemplateOutlet,
		PhoneNumberPipe,
		SpaceFormatPipe,
	],
})
export class GabbyChatMessageRowComponent
	implements OnInit, OnChanges, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();
	private _message: MessageModel;

	@Input() set message(value: MessageModel) {
		this._message = value;
		this.created = spacetime(value.created);
	}
	get message() {
		return this._message;
	}

	@Input() peekUser: (userLinkId?: number) => void;
	@Input() channel: ChannelModel;
	@Input() viewingUserLinkId: number;
	@Input() postRenders: (PostMessageRender &
		DatePostMessageRender &
		AttachmentPostMessageRender)[];
	@Input() fullWidth: boolean = false;

	@ViewChildren(GabbyChatMessagePostRenderDirective)
	public postRendersActive: QueryList<GabbyChatMessagePostRenderDirective>;

	public MessagePlatform = MessagePlatform;
	public MessageType = MessageType;
	public PostMessageRenderType = PostMessageRenderType;

	public wrapsLoaded: boolean;
	public isMyMessage: boolean;
	public participant: ChannelParticipantModel;
	public created: Spacetime;

	public messageDeliveryPlatforms = new Map<MessagePlatform, number>();
	public failedMessageDeliveries: MessageDeliveryMethodModel[] = [];
	public readers: ChannelParticipantModel[] = [];

	public collapsed: WritableSignal<boolean> = signal(false);
	public isMobile = getEnvironment().isApp('mobile');
	public isStudent = getEnvironment().isApp('student');

	public _linkifiedMessage: string;

	constructor(
		public elemRef: ElementRef<HTMLElement>,
		public cdr: ChangeDetectorRef,
		private gabbyUserStoreService: GabbyUserStoreService,
		private localStore: LocalStoreService,
	) {}

	public postRenderTrackBy = (_index: number, item: PostMessageRender) => {
		return item.key;
	};

	public adjustedTime = (val: Spacetime): Spacetime => {
		return val;
	};

	public loadWraps = () => {
		if (!this.wrapsLoaded) {
			this.wrapsLoaded = true;
			this.cdr.markForCheck();
		}
	};

	public htmlMessage = () => {
		return this.message.message;
	};

	public linkifiedMessage = () => {
		if (!this._linkifiedMessage) {
			this._linkifiedMessage = linkifyText(
				this.message.message,
				'colorless-link',
			);
		}

		return this._linkifiedMessage;
	};

	ngOnInit() {
		if (this.message == null) {
			throw Error('GabbyChatMessageRowComponent has no message');
		}

		if (this.channel == null) {
			throw Error('GabbyChatMessageRowComponent has no channel');
		}

		if (this.postRenders == null) {
			throw Error('GabbyChatMessageRowComponent has no postRenders');
		}

		if (this.message.message_type !== MessageType.standard) {
			this.localStore
				.observe$('gabby.system-message.collapse', TypedStoreType.BOOLEAN)
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe((val) => {
					this.collapsed.set(val);
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		let changesProcessing = true;

		if (changes.viewingUserLinkId) {
			this.isMyMessage =
				this.viewingUserLinkId === this.message.message_sender_link_id;
		}

		if (changes.channel) {
			this.participant = this.channel.participants.find(
				(x) => x.link_id === this.message.message_sender_link_id,
			);

			if (
				this.participant == null &&
				this.message.message_send_medium !== MessagePlatform.system
			) {
				this.gabbyUserStoreService
					.user$(this.message.message_sender_link_id)
					.pipe(takeUntil(this._unsubscribe$))
					.subscribe((x) => {
						if (this.participant != null) return;

						this.participant = {
							phones: [],
							emails: [],
						} as ChannelParticipantModel;

						if (x != null) {
							this.participant.link_id = x.id;
							this.participant.name = x.name;
						}

						if (!changesProcessing) {
							this.cdr.detectChanges();
						}
					});
			}
		}

		if (changes.message) {
			this.messageDeliveryPlatforms.clear();

			this.message.delivery_data
				.filter((dd) => {
					if (this.isMyMessage) {
						return (
							dd.participant_link_id !== this.message.message_sender_link_id
						);
					} else {
						return (
							dd.participant_link_id === this.message.message_sender_link_id
						);
					}
				})
				.map((dd: MessageDeliveryModel) => {
					return dd.delivery_methods;
				})
				.flat(Infinity)
				.map((dm: MessageDeliveryMethodModel) => {
					return dm.id;
				})
				.forEach((x) => {
					if (!this.messageDeliveryPlatforms.has(x)) {
						this.messageDeliveryPlatforms.set(x, 0);
					}

					this.messageDeliveryPlatforms.set(
						x,
						this.messageDeliveryPlatforms.get(x) + 1,
					);
				});

			this.failedMessageDeliveries = this.message.delivery_data
				.filter((dd) => {
					if (this.isMyMessage) {
						return (
							dd.participant_link_id !== this.message.message_sender_link_id
						);
					} else {
						return (
							dd.participant_link_id === this.message.message_sender_link_id
						);
					}
				})
				.map((dd: MessageDeliveryModel) => {
					return dd.delivery_methods;
				})
				.flat(20) // 20 is the largest number that typescript can handle before losing it's mind, should be deep enough
				.filter((dm: MessageDeliveryMethodModel) => {
					return !dm.success;
				});

			this.readers = this.message.delivery_data
				.filter(
					(dd) =>
						dd.participant_link_id !== this.message.message_sender_link_id &&
						dd.read === true,
				)
				.map((x) =>
					this.channel.participants.find(
						(p) => p.link_id === x.participant_link_id,
					),
				)
				.filter((x) => x != null);
		}

		changesProcessing = false;
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
