import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, combineLatest } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { StudentEventModel } from 'src/lib/services/api/students/events/student-event.model';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';

import { ToastrService } from 'ngx-toastr';
import { Permits } from 'src/lib/constants/permissions';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';

import { StudentsEventsService } from 'src/lib/services/api/students/events/students-events.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { WaitSpinnerComponent } from '../../../../../templates/global/wait-spinner/wait-spinner.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { EventUpsertComponent } from '../event-upsert/event-upsert.component';
import { EventViewComponent } from '../event-view/event-view.component';

@Component({
	selector: 'ae-student-event-detail-modal',
	templateUrl: './student-event-detail-modal.component.html',
	styleUrls: ['./student-event-detail-modal.component.scss'],
	imports: [
		EventUpsertComponent,
		EventViewComponent,
		SpinWhileDirective,
		WaitSpinnerComponent,
	],
})
export class StudentEventDetailModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() studentId: number;
	@Input() eventId: number;
	@Input() openEdit: Partial<StudentEventModel>;

	public currentEvent: StudentEventModel;

	public canEditEvent = false;
	public isEditing: boolean;
	public saving = false;
	public loading = true;
	public isValid = false;
	public wasEdited = false;

	public permissions = {
		canEditEventAny: false,
		canEditEventOwn: false,
		canEditTaskAny: false,
		canEditTaskOwn: false,
	};

	@ViewChild('eventUpsert')
	eventUpsertComponent: EventUpsertComponent;

	constructor(
		private cdref: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
		private permissionStore: PermissionStoreService,
		private toastr: ToastrService,
		private userService: UserStoreService,
		private studentsEventsService: StudentsEventsService,
	) {}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		studentId: number;
		eventId: number;
		edit?: Partial<StudentEventModel>;
	}): void => {
		// SET DATA
		this.studentId = data.studentId;
		this.eventId = data.eventId;
		this.openEdit = data.edit;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		combineLatest([
			this.studentsEventsService.getStudentEvent(this.studentId, this.eventId),
			this.permissionStore
				.getFieldSet$({
					UserId: this.studentId,
				})
				.pipe(
					tap((x) => {
						this.permissions.canEditEventAny = x.canDo(
							Permits['ga_user_log|edit any user log entities'],
						);
						this.permissions.canEditEventOwn = x.canDo(
							Permits['ga_user_log|edit own user log entities'],
						);
						this.permissions.canEditTaskAny = x.canDo(
							Permits['ga_user_log|edit any user log tasks'],
						);
						this.permissions.canEditTaskOwn = x.canDo(
							Permits['ga_user_log|edit own user log tasks'],
						);
					}),
				),
		])
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: ([event]) => {
					this.currentEvent = event;
					this.isEventEditable();

					if (this.openEdit && this.canEditEvent) {
						this.isEditing = true;
						Object.assign(this.currentEvent, this.openEdit);
					}
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors), 'View Event');
					this.activeModal.dismiss();
				},
			});
	};

	public isFormValid = (isFormValid: boolean) => {
		this.isValid = isFormValid;
	};

	public isEventEditable = () => {
		if (this.currentEvent.is_auto_logged) {
			this.canEditEvent = false;
		} else if (this.currentEvent.category === 'Task') {
			if (
				this.permissions.canEditTaskAny ||
				(this.permissions.canEditTaskOwn &&
					this.userService.currentUserUid === this.currentEvent.creator_uid)
			) {
				this.canEditEvent = true;
			}
		} else if (this.currentEvent.category === 'Note') {
			if (
				this.permissions.canEditEventAny ||
				(this.permissions.canEditEventOwn &&
					this.userService.currentUserUid === this.currentEvent.creator_uid)
			) {
				this.canEditEvent = true;
			}
		}
		this.loading = false;
	};

	public reset = () => {
		this.eventUpsertComponent.resetForm();
	};

	public save = () => {
		this.saving = true;
		this.eventUpsertComponent
			.save$()
			.pipe(finalize(() => (this.saving = false)))
			.subscribe({
				next: (event) => {
					this.wasEdited = true;
					this.currentEvent = event;

					if (this.openEdit) {
						this.close();
					} else {
						this.isEditing = false;
					}
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors), 'Edit Event');
				},
			});
	};

	public close = () => {
		this.activeModal.close(this.wasEdited);
	};
}
