import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
} from '@angular/forms';
import { AsyncSubject, combineLatest, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	NgbDropdown,
	NgbDropdownMenu,
	NgbDropdownToggle,
	NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { OrganizationModel } from 'src/lib/services/api/organizations/organization.model';
import { OrganizationsService } from 'src/lib/services/api/organizations/organizations.service';
import { UserSettingsStoreService } from 'src/lib/services/stores/users/settings/user-settings-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { SpinUntilDirective } from '../../layout/spin-while/spin-until.directive';
import { OrganizationMultiSelectComponent } from '../organization-multi-select/organization-multi-select.component';
import { LegacyDashboardSaveSearchModalComponent } from './saved-search-modal/legacy-dashboard-save-search-modal.component';

export interface ProgramUserPreferences {
	savedSearches: ProgramSavedSearch[];
}

export interface ProgramSavedSearch {
	name: string;
	value: string;
}

export interface ProgramUserPreferencesForm {
	savedSearches: FormArray<FormGroup<FormControlWrapper<ProgramSavedSearch>>>;
}

@Component({
	selector: 'ae-legacy-dashboard-program-select',
	templateUrl: './legacy-dashboard-program-select.component.html',
	styleUrl: './legacy-dashboard-program-select.component.scss',
	imports: [
		NgbDropdown,
		NgbDropdownMenu,
		NgbDropdownToggle,
		OrganizationMultiSelectComponent,
		ReactiveFormsModule,
		SpinUntilDirective,
	],
})
export class LegacyDashboardProgramSelectComponent
	implements OnInit, OnChanges
{
	@Input() public orgControl: FormControl<number[]>;
	@Input() public allowSubmit: boolean = true;
	@Output() public forceReload: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	protected maxSelectedItems: number = 10;
	public searchMap = new Map<number, Fuzzysort.Prepared[]>();
	public subtitleMap = new Map<number, string>();
	public organizations: OrganizationModel[] = [];
	public _organizations: OrganizationModel[] = [];

	public userPreferences: ProgramUserPreferences;
	public userPrefForm: FormGroup<ProgramUserPreferencesForm>;

	private _unsubscribe$ = new AsyncSubject<null>();

	private saveId = `dashboard.org.select.saved-searches`;

	protected loading = true;

	constructor(
		private organizationsService: OrganizationsService,
		private settingsStore: UserSettingsStoreService,
		private fb: FormBuilder,
		private modalService: NgbModal,
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		if (!this.orgControl) {
			this.orgControl = this.fb.control<number[]>(null);
		}

		this.organizationsService
			.getProgramSelection()
			.pipe(
				tap((orgs) => {
					this.orgControl.setValue(orgs.selected);
				}),

				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	}

	ngOnChanges(): void {
		this.setupUserPreferences();
		this.loadUserPreferences();
		this.changeDetectorRef.detectChanges();
	}

	private save = (searchName: string) => {
		const name = searchName;
		let savedSearchGroup =
			this.userPrefForm.controls.savedSearches.controls.find(
				(ss) => ss.controls.name.value === name,
			);

		if (savedSearchGroup == null) {
			savedSearchGroup = this.formBuild_SavedSearch_Group();
			this.userPrefForm.controls.savedSearches.push(savedSearchGroup);
		}

		const saveValue = this.orgControl.value.join(',');
		savedSearchGroup.patchValue({
			name: name,
			value: saveValue,
		});

		this.settingsStore
			.saveSetting$(this.saveId, this.userPrefForm.value)
			.subscribe();
	};

	protected deleteSearch = (search: ProgramSavedSearch, event?: Event) => {
		event?.stopPropagation();
		event?.preventDefault();

		const index = this.userPrefForm.controls.savedSearches.controls.findIndex(
			(ss) => ss.controls.name.value === search.name,
		);

		if (index !== -1) {
			this.userPrefForm.controls.savedSearches.removeAt(index);
		}
	};

	public loadSearch = (search: ProgramSavedSearch) => {
		if (search.value) {
			this.orgControl.setValue(search.value.split(',').map((x) => parseInt(x)));
			this.submit();
		}
	};

	private setupUserPreferences = () => {
		this.userPrefForm = this.formBuild_UserPreferences_Group();
		this.userPreferences = JSON.parse(JSON.stringify(this.userPrefForm.value));

		// Output the form values to the UI
		this.userPrefForm.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				this.userPreferences = JSON.parse(JSON.stringify(v));
			});
	};

	private loadUserPreferences = () => {
		combineLatest([
			this.settingsStore.getSetting$<ProgramUserPreferences>(this.saveId),
		])
			.pipe(
				tap(([loadedConfig]) => {
					if (loadedConfig != null) {
						if (loadedConfig?.savedSearches?.length > 0) {
							loadedConfig.savedSearches.forEach((x) => {
								const savedSearchGroup = this.formBuild_SavedSearch_Group();
								savedSearchGroup.patchValue(x);
								this.userPrefForm.controls.savedSearches.push(savedSearchGroup);
							});
						} else {
							delete loadedConfig.savedSearches;
							this.userPrefForm.patchValue(loadedConfig);
						}
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	};

	private formBuild_UserPreferences_Group = () => {
		return this.fb.group<ProgramUserPreferencesForm>({
			savedSearches: this.fb.array<
				FormGroup<FormControlWrapper<ProgramSavedSearch>>
			>([]),
		});
	};

	private formBuild_SavedSearch_Group = () => {
		return this.fb.group<FormControlWrapper<ProgramSavedSearch>>({
			name: new FormControl(null),
			value: new FormControl(null),
		});
	};

	public submit = () => {
		this.organizationsService
			.setProgramSelection(this.orgControl.value)
			.subscribe(() => {
				this.forceReload.emit(true);
			});
	};

	public updateOrgControl = () => {
		this.submit();
	};

	protected loadingComplete = (complete: boolean) => {
		this.loading = !complete;
	};

	public reset = () => {
		this.orgControl.setValue([]);
		this.submit();
	};

	public openModal = () => {
		const modal = this.modalService.open(
			LegacyDashboardSaveSearchModalComponent,
			{
				size: 'sm',
			},
		);

		(
			modal.componentInstance as LegacyDashboardSaveSearchModalComponent
		).bindModalData({
			searches: this.userPreferences.savedSearches,
		});

		modal.result
			.then((name) => {
				this.save(name);
			})
			.catch(() => {
				// Do nothing
			});
	};
}
