import { Component, Input } from '@angular/core';

@Component({
	selector: 'ae-labeled-display',
	templateUrl: './labeled-display.component.html',
	styleUrls: ['./labeled-display.component.scss'],
	imports: [],
})
export class LabeledDisplayComponent {
	@Input({ required: true }) label: string;
	@Input() isInline: boolean = false;
}
