import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import spacetime, { type Format } from 'spacetime';
import { ActiveStudentService } from 'src/lib/services/startup/active-student/active-student.service';
import { StudentStoreService } from 'src/lib/services/stores/students/student/student-store.service';
import { SpaceFormatPipe } from '../../../pipes/spacetime/space-format.pipe';
import { SpaceMapPipe } from '../../../pipes/spacetime/space-map.pipe';

@Component({
	selector: 'ae-active-student-time',
	templateUrl: './active-student-time.component.html',
	styleUrls: ['./active-student-time.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SpaceFormatPipe, SpaceMapPipe],
})
export class ActiveStudentTimeComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public date: Date;
	@Input() public spaceFormat: Format = 'numeric-us';

	public tz: string;

	constructor(
		private activeStudent: ActiveStudentService,
		private studentStoreService: StudentStoreService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.tz = spacetime().timezone().name;

		this.activeStudent
			.getActiveStudent$()
			.pipe(
				switchMap((studentId) => this.studentStoreService.overview$(studentId)),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((overview) => {
				this.tz = overview.institute_timezone;
				this.cdr.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
