import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import spacetime from 'spacetime';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { isValidDate } from 'src/lib/utilities/compare';
import {
	convertDateToNgbDateStruct,
	convertToDate,
} from 'src/lib/utilities/convert';
import { DateRangePickerValue } from './date-range-picker.types';
import {
	createBothDateValidation,
	createDateValidation,
	createMaxCalendarDateValidation,
	createMinCalendarDateValidation,
	createStartingDateValidation,
} from './validators';

export * from './date-range-picker.types';

@Component({
	selector: 'ae-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateRangePickerComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DateRangePickerComponent),
			multi: true,
		},
	],
	imports: [FormsModule, NgbInputDatepicker, NgClass],
})
export class DateRangePickerComponent
	implements OnInit, ControlValueAccessor, OnChanges
{
	@HostBinding('class.custom-form-control') customFormControl = true;

	@Input() format: string = 'MM/dd/yyyy';
	@Input() minDate: any = null;
	@Input() maxDate: any = null;
	@Input() id: any = null;
	@Input() markDisabled: (
		date: NgbDateStruct,
		current: { year: number; month: number },
	) => boolean = null;
	@Input() noValidate: boolean = false;

	private _minDate: Date;
	private _maxDate: Date;
	private _verifiedToValue: Date;
	private _verifiedFromValue: Date;

	public disabled: boolean = false;
	public hiddenModel: any;
	public formattedModel: string;
	public drpForm: FormGroup<FormControlWrapper<DateRangePickerValue>>;

	public fromDate: Date;
	public toDate: Date;
	public hoveredDate: Date = new Date();

	private _touchFunction: () => void;
	private _validateFn: ((_: any) => any)[];
	private _changeFunction: (value: Partial<DateRangePickerValue>) => void =
		() => null;
	@Input() isDisabledDate: (date: Date) => boolean = (_date) => false;

	constructor(
		private ele: ElementRef<any>,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder,
	) {}

	ngOnInit() {
		this.fromDate = spacetime.now().toNativeDate();
		this.drpForm = this.fb.group({
			fromDate: new FormControl(null, Validators.required),
			toDate: new FormControl(null, Validators.required),
		});
	}

	public setTouched = () => {
		if (this._touchFunction) {
			this._touchFunction();
		}
	};

	public onFormattedChange = (dateModel: string) => {
		this.cdr.detectChanges();
		// Parse dates
		let toDate = null;
		let fromDate = null;

		if (dateModel && dateModel !== '') {
			const splitDates = dateModel.split('-');

			if (splitDates[0]) {
				fromDate = convertToDate(splitDates[0]);
				fromDate = isValidDate(fromDate) ? fromDate : this.fromDate;
			} else {
				fromDate = null;
			}
			if (splitDates[1]) {
				toDate = convertToDate(splitDates[1]);
				toDate = isValidDate(toDate) ? toDate : this.toDate;
			} else {
				toDate = null;
			}
		} else {
			fromDate = null;
			toDate = null;
		}

		this.setVerifiedValue(fromDate, toDate);
		this.updateFormattedModel();
		this.cdr.detectChanges();
	};

	private setVerifiedValue = (newFromValue: Date, newToValue: Date) => {
		if (this._changeFunction == null) return;

		this._verifiedFromValue = convertToDate(newFromValue);
		this._verifiedToValue = convertToDate(newToValue);

		if (!isValidDate(this._verifiedFromValue)) {
			this._verifiedFromValue = null;
		}
		this.drpForm.controls.fromDate.setValue(this._verifiedFromValue);
		this.fromDate = this._verifiedFromValue;

		if (!isValidDate(this._verifiedToValue)) {
			this._verifiedToValue = null;
		}
		this.drpForm.controls.toDate.setValue(this._verifiedToValue);
		this.toDate = this._verifiedToValue;

		this._changeFunction(this.drpForm.value);
	};

	public isPresentingInvalid = () => {
		return this.ele.nativeElement.classList.contains('is-invalid');
	};

	// Implementing NG_VALIDATORS
	public validate(ctrl: FormControl) {
		const errors = {};

		if (!this.noValidate) {
			this._validateFn.forEach((fn) => {
				const error = fn(ctrl);
				if (error) {
					const key = Object.keys(error)[0];
					errors[key] = error[key];
				}
			});
		}

		return errors;
	}

	// Implementing OnChanges
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.minDate) {
			this._minDate = convertToDate(changes.minDate.currentValue);
		}

		if (changes.maxDate) {
			this._maxDate = convertToDate(changes.maxDate.currentValue);
		}

		this._validateFn = [];
		if (this._maxDate != null) {
			this._validateFn.push(createMaxCalendarDateValidation(this._maxDate));
		}
		if (this._minDate != null) {
			this._validateFn.push(createMinCalendarDateValidation(this._minDate));
		}
		this._validateFn.push(createStartingDateValidation());
		this._validateFn.push(createBothDateValidation());
		this._validateFn.push(createDateValidation(this.isDisabledDate));

		this._changeFunction({
			fromDate: this._verifiedFromValue,
			toDate: this._verifiedToValue,
		});
	}

	// Implementing ControlValueAccessor
	public writeValue(val: DateRangePickerValue): void {
		if (val != null && typeof val !== 'string') {
			this.setVerifiedValue(val.fromDate, val.toDate);
		} else {
			this.setVerifiedValue(null, null);
		}

		this.updateFormattedModel();
		this.cdr.detectChanges();
	}
	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	private updateFormattedModel = () => {
		let formattedToDate = null;
		let formattedFromDate = null;

		if (this.fromDate) {
			formattedFromDate = spacetime(this.fromDate)
				.format('numeric-us')
				.toString();
		}
		if (this.toDate) {
			formattedToDate = spacetime(this.toDate).format('numeric-us').toString();
		}

		this.hoveredDate = null;
		this.formattedModel =
			formattedFromDate && formattedToDate
				? `${formattedFromDate} - ${formattedToDate}`
				: null;

		if (this.formattedModel != null) {
			this.setTouched();
		}
	};

	public onHoverSelection = (date) => {
		this.hoveredDate = spacetime(convertToDate(date)).toNativeDate();
	};

	public getMaxDate = () => convertDateToNgbDateStruct(this._maxDate);
	public getMinDate = () => convertDateToNgbDateStruct(this._minDate);
	public getStartDate = (): NgbDateStruct => {
		if (this.fromDate == null) {
			return convertDateToNgbDateStruct(spacetime.now().toNativeDate());
		}
		if (this._verifiedFromValue != null) {
			return convertDateToNgbDateStruct(this._verifiedFromValue);
		} else {
			return convertDateToNgbDateStruct(this.fromDate);
		}
	};

	public onDateSelection = (date, elRef?): void => {
		// Stopping selection or any update on a disabled (blackout) date
		if (this.isDisabled(date)) {
			return;
		}
		const calDate = convertToDate(date);
		if (!this.fromDate && !this.toDate) {
			this.fromDate = spacetime(calDate).toNativeDate();
		} else if (
			this.fromDate &&
			!this.toDate &&
			spacetime(calDate).isAfter(this.fromDate)
		) {
			this.toDate = spacetime(calDate).toNativeDate();
			this.hoveredDate = this.toDate;
			elRef.toggle();
		} else {
			this.toDate = null;
			this.hoveredDate = null;
			this.fromDate = spacetime(calDate).toNativeDate();
		}

		this.updateFormattedModel();
		this.setVerifiedValue(this.fromDate, this.toDate);
	};

	public isHovered = (date) => {
		const calDate = convertToDate(date);
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			spacetime(calDate).isAfter(this.fromDate) &&
			spacetime(calDate).isBefore(this.hoveredDate)
		);
	};

	public isRange = (date) => {
		const calDate = convertToDate(date);
		if (this.hoveredDate == null && this.toDate != null) {
			this.hoveredDate = this.toDate;
		}
		return (
			spacetime(calDate).isSame(spacetime(this.fromDate), 'day') ||
			spacetime(calDate).isSame(spacetime(this.toDate), 'day') ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	};

	public isInside = (date) => {
		if (!this.toDate) {
			return false;
		}
		const calDate = convertToDate(date);
		return spacetime(calDate).isBetween(this.fromDate, this.hoveredDate);
	};

	public isDisabled = (date) => {
		const calDate = convertToDate(date);
		// Checking if this date is in a blackout date or if outside of min date, or outside of max date
		return (
			this.isDisabledDate(calDate) ||
			(this._minDate != null && spacetime(calDate).isBefore(this._minDate)) ||
			(this._maxDate != null && spacetime(calDate).isAfter(this._maxDate))
		);
	};
}
