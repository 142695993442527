import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncSubject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { UserAgreementModel } from 'src/lib/services/api/users/agreements/user-agreement.model';
import { UsersAgreementsService } from 'src/lib/services/api/users/agreements/users-agreements.service';
import { convertToInt } from 'src/lib/utilities/convert';
import { PrintModalService } from '../../../modals/print-modal/print-modal.service';

@Component({
	selector: 'ae-profile-agreements',
	templateUrl: './profile-agreements.component.html',
	styleUrls: ['./profile-agreements.component.scss'],
	imports: [],
})
export class ProfileAgreementsComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	public agreements: UserAgreementModel[] = [];

	constructor(
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private usersAgreementsService: UsersAgreementsService,
		private printModalService: PrintModalService,
	) {}

	ngOnInit() {
		this.route.params
			.pipe(
				map((params) => convertToInt(params.userId)),
				switchMap((userId) =>
					this.usersAgreementsService.getAgreements(userId),
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((agreements) => {
				this.agreements = agreements;
				this.cdr.detectChanges();
			});
	}

	public openAgreementModal = (agreement: UserAgreementModel) => {
		this.printModalService
			.openModal$(agreement.body, agreement.title)
			.subscribe();
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
